import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import MoreInfoTooltip from './more-info-tooltip';
import PdfIcon from '../../../../static/images/icon-pdf.svg';
import DownloadIcon from '../../../../static/images/icon-download.svg';

const styles = theme => ({
    root: {
        padding: '20px',
        backgroundColor: 'white'
    },
    stageTextContainer: {
        paddingBottom: '20px'
    },
    stageIndicator: {
        paddingRight: '10px',
        marginBottom: '-5px',
        display: 'inline-block'
    },
    stageText: {
        color: '#90b669',
        display: 'inline-block'
    },
    titleContainer: {
        paddingBottom: '20px'
    },
    submissionContainer: {
        backgroundColor: theme.palette.gray.gray400,
        borderRadius: '4px',
        marginBottom: '10px',
        [theme.breakpoints.up('md')]: {
            height: '70px'
        }
    },
    submissionDetailsText: {
        display: 'inline-block'
    },
    firstHeadCell: {
        paddingLeft: '20px'
    },
    firstCell: {
        paddingLeft: '20px',
        paddingTop: '10px',
        paddingRight: '10px',
        paddingBottom: '10px'
    },
    gridCell: {
        padding: '10px'
    },
    dividerCell: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    lastEditedDate: {
        color: '#605F60'
    },
    downloadButton: {
        textTransform: 'none',
        width: '190px',
        height: '30px',
        backgroundColor: theme.palette.white,
    },
    downloadIcon: {
        width: '16px',
        height: '21px',
        marginRight: '10px'
    },
    infoIcon: {
        paddingLeft: '5px'
    },
    moreInfoText: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '27px',
        letterSpacing: '0.2px',
        color:theme.palette.secondary.charcoal
    },
    tableHeadingText: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    headerContainer: {
        alignItems: 'center'
    },
    subText:{
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    }
});

@inject('submissionStore')
@observer
class CompletedSubmissions extends React.Component {
    render() {
        const { classes, stage, reviewType, submissionStore } = this.props;
        const { currentSubmissions } = submissionStore;

        const completedSubmissions = submissionStore.getCompletedSubmissions(currentSubmissions, stage, reviewType);

        return (
            <div className={classes.root}>
                <Grid item xs={12} className={classes.titleContainer}>
                    <Typography variant="h3" className={classes.moreInfoText}>
                        My completed submissions
                    </Typography>
                </Grid>
                <Hidden smDown>
                    <Grid container>
                        <Grid container item xs={4} className={[classes.firstHeadCell, classes.headerContainer].join(' ')}>
                            <Typography variant="h4" className={classes.tableHeadingText}>
                                Date submitted
                            </Typography>
                        </Grid>
                        <Grid container justify="center" item xs={4} className={classes.headerContainer}>
                            <Typography variant="h4" className={classes.tableHeadingText}>
                                Written submission
                            </Typography>
                        </Grid>
                        <Grid container justify="center" item xs={4} className={classes.headerContainer}>
                            <Typography variant="h4" className={classes.tableHeadingText}>
                                Mapping submission
                            </Typography>
                            <span className={classes.infoIcon}>
                                <MoreInfoTooltip
                                    title="Viewing your KML file"
                                    body="You can open your KML file using the free Google Earth application available at google.com/earth/ or other GIS software. If you have a Gmail account, you can save your KML file and open it at any time on Google MyMaps."
                                />
                            </span>
                        </Grid>
                    </Grid>
                </Hidden>
                {
                    completedSubmissions.map(s =>
                        <Grid key={s.submissionId} container className={classes.submissionContainer}>
                            <Hidden smDown>
                                <Grid container alignItems="center" item xs={4} className={classes.firstCell}>
                                    <span className={classes.lastEditedDate}>
                                        <Typography variant="h5" className={classes.subText} color="inherit">
                                            {submissionStore.getFormattedSubmissionDate(s.submittedDateTime || s.lastModified || s.createdDateTime)}
                                        </Typography>
                                    </span>
                                </Grid>
                                <Grid container justify="center" alignItems="center" item xs={4} className={classes.gridCell}>
                                    <span>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.downloadButton}
                                            component="a"
                                            href={`/api/submissions/redivision/${s.profileId}/${s.submissionId}/written`}
                                            target='_blank'
                                        >
                                            <img className={classes.downloadIcon} src={PdfIcon} alt="Download PDF" />
                                            Download PDF
                                        </Button>
                                    </span>
                                </Grid>
                                <Grid container justify="center" alignItems="center" item xs={4} className={classes.gridCell}>
                                    <span>
                                        {
                                            s.hasMappingSegment ?
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.downloadButton}
                                                    component="a"
                                                    href={`/api/submissions/${s.profileId}/${s.submissionId}/map/kml`}
                                                    target='_blank'
                                                >
                                                    <img className={classes.downloadIcon} src={DownloadIcon} alt="Download KML" />
                                                    Download KML
                                                </Button> :
                                                <Typography variant="h5" className={classes.subText}>N/A</Typography>
                                        }
                                    </span>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid container item xs={4} alignItems="center" className={classes.gridCell}>
                                    <span>
                                        <Typography variant="h4" className={classes.tableHeadingText}>
                                            Date submitted
                                        </Typography>
                                    </span>
                                </Grid>
                                <Grid container item xs={8} justify="flex-end" alignItems="center" className={classes.gridCell}>
                                    <span className={classes.lastEditedDate}>
                                        <Typography variant="h5" className={[classes.subText, classes.submissionDetailsText].join(' ')} color="inherit">
                                            {submissionStore.getFormattedSubmissionDate(s.submittedDateTime || s.lastModified || s.createdDateTime)}
                                        </Typography>
                                    </span>
                                </Grid>
                                <Grid item xs={12} className={classes.dividerCell}>
                                    <Divider />
                                </Grid>
                                <Grid container item xs={3} alignItems="center" className={classes.gridCell}>
                                    <span>
                                        <Typography variant="h4" className={classes.tableHeadingText}>
                                            Written submission
                                        </Typography>
                                    </span>
                                </Grid>
                                <Grid container item xs={9} justify="flex-end" alignItems="center" className={classes.gridCell}>
                                    <span>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.downloadButton}
                                            component="a"
                                            href={`/api/submissions/redivision/${s.profileId}/${s.submissionId}/written`}
                                            target='_blank'
                                        >
                                            <img className={classes.downloadIcon} src={PdfIcon} alt="Download PDF" />
                                            Download PDF
                                        </Button>
                                    </span>
                                </Grid>
                                <Grid item xs={12} className={classes.dividerCell}>
                                    <Divider />
                                </Grid>
                                <Grid container item xs={3} alignItems="center" className={classes.gridCell}>
                                    <span>
                                        <Typography variant="h4" className={classes.tableHeadingText}>
                                            Mapping submission
                                        </Typography>
                                    </span>
                                </Grid>
                                <Grid container item xs={9} justify="flex-end" alignItems="center" className={classes.gridCell}>
                                    <span>
                                        {
                                            s.hasMappingSegment ?
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.downloadButton}
                                                    component="a"
                                                    href={`/api/submissions/${s.profileId}/${s.submissionId}/map/kml`}
                                                    target='_blank'
                                                >
                                                    <img className={classes.downloadIcon} src={DownloadIcon} alt="Download KML" />
                                                        Download KML
                                                    </Button> :
                                                <Typography variant="h5" className={classes.subText}>N/A</Typography>
                                        }
                                    </span>
                                </Grid>
                            </Hidden>
                        </Grid>
                    )
                }
            </div>
        );
    }
}

CompletedSubmissions.propTypes = {
    classes: PropTypes.object.isRequired,
    submissionStore: PropTypes.object,
    stage: PropTypes.number.isRequired,
    reviewType: PropTypes.number.isRequired
}

export default withStyles(styles)(CompletedSubmissions);