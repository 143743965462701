import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Grid } from '@material-ui/core';
import MapGuidelineDetails from './map-guideline-details';
import Step1DetailsImage from './../../../../static/images/map-guide-step1.svg';
import Step2DetailsImage from './../../../../static/images/map-guide-step2.svg';
import Step3DetailsImage from './../../../../static/images/map-guide-step3.svg';
import Step4DetailsImage from './../../../../static/images/map-guide-step4.svg';
import Step5DetailsImage from './../../../../static/images/map-guide-step5.svg';
import Step6DetailsImage from './../../../../static/images/map-guide-step6.svg';
import Step7DetailsImage from './../../../../static/images/map-guide-step7.svg';

class MapGuidelineContent extends React.Component {
    render() {
        const { activeStep, handleChangeActiveStep } = this.props;
        return (
            <Grid container >
                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleChangeActiveStep}
                >
                    <Grid item xs={12} >
                        <MapGuidelineDetails
                            title='1. Select a district'
                            image={Step1DetailsImage}
                            content={<>Click on a district name in the right hand panel or use the zoom tool
                                in the lower right hand corner of the map to <b>zoom to district.</b>
                            </>} />
                    </Grid>
                    <Grid item xs={12} >
                        <MapGuidelineDetails
                            title='2. Select blocks'
                            image={Step2DetailsImage}
                            content={<>Use the <b>point</b>, <b>rectangle</b> or <b>polygon tool</b> to select blocks on the map.
                                       The panel shows the number of voters in your selected blocks.
                                       </>} />
                    </Grid>
                    <Grid item xs={12} >
                        <MapGuidelineDetails
                            title='3. Assign to district'
                            image={Step3DetailsImage}
                            content={<>Once you have selected blocks on the map, you’ll be able to <b>assign them to a district. </b>
                                       The panel will show you the effect of re-assigning blocks from one district to another.
                                       Hit Confirm to lock in your choice.
                                       </>} />
                    </Grid>
                    <Grid item xs={12} >
                        <MapGuidelineDetails
                            title='4. Check the effect of your change'
                            image={Step4DetailsImage}
                            content={<>In this example, moving 1,905 voters from Brunswick District to Essendon District has put
                                        Brunswick District within the +/-10% deviation. </>} />
                    </Grid>
                    <Grid item xs={12} >
                        <MapGuidelineDetails
                            title='5. View existing districts '
                            image={Step5DetailsImage}
                            content={<>You can view existing districts at any time by going to the layers panel and clicking on <b>Existing districts. </b>
                                       Existing boundaries will appear with black outlines, and any boundary
                                       changes you&apos;ve made will appear with white outlines. </>} />
                    </Grid>
                    <Grid item xs={12} >
                        <MapGuidelineDetails
                            title='6.  Find unassigned blocks '
                            image={Step6DetailsImage}
                            content={<>If you remove blocks from a district they will become <b>Unassigned. </b> 
                                       You can zoom to these blocks by clicking on <b>Unassigned</b> in the right hand panel.
                                       Click again to remove the pins. </>} />
                    </Grid>
                    <Grid item xs={12} >
                        <MapGuidelineDetails
                            title='7. Check your map '
                            image={Step7DetailsImage}
                            content={<>Click on Save to save a draft or validate your map.
                                       The validation highlights issues that may be present in your model. 
                                       You can submit your map without resolving these issues.
                                       This information is provided to ensure you haven’t accidentally overlooked an aspect of your submission.</>} />
                    </Grid>
                    <Grid item xs={12} >
                        <MapGuidelineDetails
                            title='8. Before you submit'
                            content={'Things to note when finalising your submission:' +
                                '  \n- Your submission can cover the whole State, or just the area(s) you&apos;re interested in.' +
                                '  \n- You&apos;ll have an opportunity to include a written statement after you&apos;ve completed your model. This is where you can explain the choices you&apos;ve made, suggest electorate names, describe how you would group your districts into regions, and share anything else that’s relevant to your submission.' +
                                '  \n- This tool is for the mapping of districts. If you also want to comment on regions, please include this in your written statement explaining which districts you think should be included in which regions.' +
                                '  \n- A limitation of the mapping tool is that you can&apos;t split blocks. If your preferred boundary does split one or more blocks, you can explain your preferred boundary in your written statement.' +
                                '  \n- It is okay to submit your map with validation errors. This information is provided to ensure you haven&apos;t accidentally overlooked an aspect of your submission.'
                            } />
                    </Grid>
                </SwipeableViews>
            </Grid>
        );
    }
}

MapGuidelineContent.propTypes = {
    activeStep: PropTypes.number,
    handleChangeActiveStep: PropTypes.func
};

export default MapGuidelineContent;