import React from 'react'
import PropTypes from 'prop-types';
import { compose } from "recompose";
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import { Typography, FormControl, FormHelperText, InputLabel, Select, Button, MenuItem, Grid, Link, OutlinedInput, ListSubheader, Divider } from '@material-ui/core';
import AddIcon from '../../../static/images/add-icon.svg';
import RemoveIcon from '../../../static/images/remove-icon.svg';
import CrossIcon from '../../../static/images/cross-icon.svg';

const styles = theme => ({
    blockPanel: {
        padding: '0 25px',
        '@media print': {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            pageBreakBefore: 'always'
        }
    },
    title: {
        paddingTop: '16px',
    },
    addDistrictLabel: {
        display: 'inline',
        marginLeft: '5px',
        fontWeight: '600'
    },
    removeDistrictLabel: {
        display: 'inline',
        fontSize: '16px',
        lineHeight: '24px',
        marginLeft: '5px !important',
    },
    voterCountLabelDetails: {
        paddingTop: '28px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
    },
    voterCountStyle: {
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '27px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    addGrid: {
        paddingTop: '31px',
        display: 'flex',
        alignItems: 'center'
    },
    removeGrid: {
        paddingTop: '25px',
        display: 'flex',
        alignItems: 'center'
    },
    cancelIcon: {
        position: 'fixed',
        right: '25px'
    },
    titleGrid: {
        paddingTop: '25px',
    },
    selectFormField: {
        width: '100%',
        marginBottom: 'unset',
        marginTop: '7px',
        '& fieldset': {
            paddingLeft: '8px !important',
            border: '2px solid',
            borderColor: theme.palette.cloudyBlue,
            boxSizing: 'border-box',
            borderRadius: '3px'
        },
        '& fieldset legend': {
            width: '0px !important'
        }
    },
    menuPaper: {
        maxHeight: '511px'
    },
    listSubHeader: {
        position: 'unset',
        paddingTop: '12px',
        paddingBottom: '6px',
        fontSize: '12px',
        fontWeight: 600,
        fontStyle: 'normal',
        lineHeight: '18px',
        textTransform: 'uppercase',
        color: theme.palette.secondary.main
    },
    selectComponentStyle: {
        border: 'unset',
        textTransform: 'capitalize'
    },
    inputLabel: {
        color: theme.palette.secondary.charcoal
    },
    affectedDistrictLabelDetails: {
        paddingTop: '29px',
        paddingBottom: '7px',
        fontWeight: '600'
    },
    affectedDisPanel: {
        backgroundColor: theme.palette.secondary.background,
        padding: '25px',
        maxHeight: 'calc(100vh - 560px)',
        overflow: 'auto',
        whiteSpace: 'nowrap'
    },
    affectedSegmementName: {
        textTransform: 'capitalize'
    },
    votersCount: {
        fontWeight: 600,
        paddingLeft: '6px'
    },
    deviationLabel: {
        paddingLeft: '17px',
    },
    confirmButton: {
        width: '100%',
        position: 'absolute',
        bottom: '30px'
    },
    addDistrictPanel: {
        minHeight: 'calc(100vh - 345px)',
        position: 'relative'
    },
    dividerClass:{
        background: '#B6C3D2',
        margin: '20px 0'
    },
    withinBoundsDeviation: {
        color: '#438600',
        fontWeight: 600,
        paddingLeft: '6px'
    },
    outOfBoundsDeviation: {
        color: '#ca0000',
        fontWeight: 600,
        paddingLeft: '6px',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap !important'
    },
    segmentColourSwatch: {
        height: '18px',
        width: '18px',
        border: 'none',
        padding: '0px',
        borderRadius: '2px',
        cursor: 'pointer'
    },
    segmentName: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize'
    },
    segmentNameContainer: {
        marginLeft: '10px'
    },
    districtLabel:{
        display: 'none'
    }
});

@inject('modelingMapStore')
@observer
class SelectedBlockPanel extends React.Component {

    constructor(props) {
        super(props);
    }


    @observable addedToDistrict = '';

    @computed get segmentsSortedByName() {
        const { segments } = this.props.modelingMapStore;
        return [...segments].sortByProperty('name', 'asc', 'properties');
    }

    @computed get segmentsInViewSortedbyName() {
        const { segmentsInView } = this.props.modelingMapStore;
        return [...segmentsInView].sortByProperty('name', 'asc', 'properties');
    }

    @computed get affectedSegments() {
        const { selectedNuggets, segments } = this.props.modelingMapStore;
        let sourceSegments = [];
        let targetSegmentId = this.addedToDistrict && this.addedToDistrict.properties.id;
        selectedNuggets.forEach(nugget => {
            if (nugget.assignedSegmentId !== 0 && nugget.assignedSegmentId !== targetSegmentId) {
                const sourceSegment = sourceSegments.find(sourceSegment => {
                    return sourceSegment.segmentId === nugget.assignedSegmentId
                })
                if (sourceSegment) {
                    sourceSegment.impactedElectors += nugget.properties.electors
                } else {
                    sourceSegments.push({ segmentId: nugget.assignedSegmentId, impactedElectors: nugget.properties.electors });
                }
            }
        });
        return sourceSegments.map(sourceSegment => {
            const segmentDetails = segments.find(segment => { return segment.properties.id === sourceSegment.segmentId });
            const resultingDeviation = this.props.modelingMapStore.calcDeviation(
                (segmentDetails.properties.electors - sourceSegment.impactedElectors), segmentDetails.properties.members);
            return { ...sourceSegment, name: segmentDetails.properties.name, resultingDeviation: resultingDeviation };
        }).sortByProperty('name');
    }

    @computed get addedToSegment() {
        const { selectedNuggets } = this.props.modelingMapStore;
        let targetSegment = {};
        if (this.addedToDistrict) {
            const { electors, members, id, name } = this.addedToDistrict.properties;
            let impactedElectors = 0;
            selectedNuggets.forEach(nugget => {
                if (nugget.assignedSegmentId !== id) {
                    impactedElectors = impactedElectors + nugget.properties.electors;
                }
            });
            const resultingDeviation = this.props.modelingMapStore.calcDeviation((electors + impactedElectors), members);
            targetSegment = { segmentId: id, impactedElectors: impactedElectors, name: name, resultingDeviation: resultingDeviation }
        }
        return targetSegment;
    }

    clearSelection = () => {
        this.props.modelingMapStore.clearSelectedNuggets();
    }

    handleChange = (event) => {
        this.addedToDistrict = this.props.modelingMapStore.segments.find(segment => segment.properties.id === event.target.value);
    }

    removeFromDistrict = () => {
        this.props.modelingMapStore.unassignNuggets();
    }

    handleConfirmclick = () => {
        const segmentId = this.addedToDistrict.properties.id;
        this.props.modelingMapStore.selectSegment(segmentId);
        this.props.modelingMapStore.assignNuggets();
    }

    deviationClass = (deviation) => {
        return (deviation > -10 && deviation < 10) ? this.props.classes.withinBoundsDeviation : this.props.classes.outOfBoundsDeviation;
    }

    render() {
        const { classes, modelingMapStore } = this.props;
        const { selectedNuggets, selectedElectors } = modelingMapStore;

        return (
            <Grid className={classes.blockPanel}>
                <Grid className={classes.titleGrid}>
                    <img src={CrossIcon} alt="Cross Icon" className={classes.cancelIcon} onClick={this.clearSelection} />
                    <Typography variant="h3" color="secondary" className={classes.title}> {selectedNuggets.length} selected</Typography>
                </Grid>
                <Grid>
                    <Typography variant="h4" color="secondary" className={classes.voterCountLabelDetails}>Voters</Typography>
                    <Typography variant="h5" className={classes.voterCountStyle}>{selectedElectors.toLocaleString()}</Typography>
                </Grid>
                <Grid>
                    <Grid className={classes.addGrid}>
                        <img src={AddIcon} alt="Add Icon" />
                        <Typography variant="subtitle2" color="secondary" className={classes.addDistrictLabel}>Add to District</Typography>
                    </Grid>
                    <InputLabel id="selected-district-label" aria-labelledby="district-value" className={classes.districtLabel}>
                        {this.addedToDistrict ? ('Selected District' + this.addedToDistrict.properties.name) :
                            "Please Select District from Dropdown"}
                    </InputLabel>
                    <FormControl
                        id="districtSelect"
                        className={classes.selectFormField}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="district" shrink={false} className={classes.inputLabel}>
                            {this.addedToDistrict ? "" : "Select District"}
                        </InputLabel>
                        <Select
                            labelId="selected-district-label"
                            value={this.addedToDistrict && this.addedToDistrict.properties.id}
                            onChange={this.handleChange}
                            input={
                                <OutlinedInput
                                    labelWidth={103}
                                    name="Select District"
                                    className={classes.selectComponentStyle}
                                />
                            }
                            inputProps={{
                                name: 'district',
                                id: 'district',
                            }}
                            variant="outlined"
                            MenuProps={{
                                classes: { paper: classes.menuPaper },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}>
                            {this.segmentsInViewSortedbyName.length &&
                                <ListSubheader className={classes.listSubHeader}>CURRENTLY IN VIEW</ListSubheader>}
                            {this.segmentsInViewSortedbyName.length &&
                                this.segmentsInViewSortedbyName.map((segment, index) => {
                                    return <MenuItem key={'view_' + index + '_' + segment.properties.id} value={segment.properties.id}>
                                        <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={0} className={classes.noWrap}>
                                            <Grid item id={'viewColor_' + index + '_' + segment.properties.id} className={classes.segmentColourSwatch} style={{ background: segment.properties.colour }} />
                                            <Grid item id={'viewName_' + index + '_' + segment.properties.id} className={classes.segmentNameContainer}>
                                                <span className={classes.segmentName}>{segment.properties.name.toLowerCase()}</span>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                })
                            }
                            <ListSubheader className={classes.listSubHeader}>ALL</ListSubheader>
                            {this.segmentsSortedByName.length && this.segmentsSortedByName.map((segment, index) => {
                                return <MenuItem key={'all_' + index + '_' + segment.properties.id} value={segment.properties.id}>
                                    <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={0} className={classes.noWrap}>
                                        <Grid item id={'allColor_' + index + '_' + segment.properties.id} className={classes.segmentColourSwatch} style={{ background: segment.properties.colour }} />
                                        <Grid item id={'allName_' + index + '_' + segment.properties.id} className={classes.segmentNameContainer}>
                                            <span className={classes.segmentName}>{segment.properties.name.toLowerCase()}</span>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            })
                            }
                        </Select>
                        <FormHelperText id='district-value' className={classes.districtLabel}>
                            {this.addedToDistrict ? ('Selected District' + this.addedToDistrict.properties.name) :
                                "Please Select District from Dropdown"}
                        </FormHelperText>
                    </FormControl>
                    {this.addedToDistrict && <Grid className={classes.addDistrictPanel}>
                        <Typography variant="subtitle2" color="secondary" className={classes.affectedDistrictLabelDetails}>Affected Districts</Typography>
                        <Grid className={classes.affectedDisPanel}>
                            <Grid>
                                <Typography variant="h5" color="secondary" className={classes.affectedSegmementName}>{this.addedToSegment.name.toLowerCase()}</Typography>
                                <Typography variant="subtitle2" color="secondary" display="inline">Voters</Typography>
                                <Typography variant="subtitle2" color="secondary" display="inline" className={classes.votersCount}>+{this.addedToSegment.impactedElectors.toLocaleString()}</Typography>
                                <Typography variant="subtitle2" color="secondary" display="inline" className={classes.deviationLabel}>Deviation</Typography>
                                <Typography variant="subtitle2" color="secondary" display="inline" className={this.deviationClass(this.addedToSegment.resultingDeviation)}>
                                    {this.addedToSegment.resultingDeviation > 0 && '+'}{this.addedToSegment.resultingDeviation.toFixed(2)}%
                                    </Typography>
                            </Grid>
                            {this.affectedSegments.length > 0 && this.affectedSegments.map((affectedSegment) => {
                                return <Grid key={'affectedSegment_' + affectedSegment.segmentId}>
                                    <Divider className={classes.dividerClass} />
                                    <Typography variant="h5" color="secondary" className={classes.affectedSegmementName}>{affectedSegment.name.toLowerCase()}</Typography>
                                    <Typography variant="subtitle2" color="secondary" display="inline">Voters</Typography>
                                    <Typography variant="subtitle2" color="secondary" display="inline" className={classes.votersCount}>-{affectedSegment.impactedElectors.toLocaleString()}</Typography>
                                    <Typography variant="subtitle2" color="secondary" display="inline" className={classes.deviationLabel}>Deviation</Typography>
                                    <Typography variant="subtitle2" color="secondary" display="inline" className={this.deviationClass(affectedSegment.resultingDeviation)}>
                                        {affectedSegment.resultingDeviation > 0 && '+'}{affectedSegment.resultingDeviation.toFixed(2)}%
                                    </Typography>
                                </Grid>
                            })
                            }
                        </Grid>
                        <Button onClick={() => this.handleConfirmclick()} color='primary' variant='contained' className={classes.confirmButton}>
                            Confirm
                        </Button>
                    </Grid>}
                    {(!this.addedToDistrict && this.affectedSegments.length > 0) &&
                        <Grid className={classes.removeGrid}>
                            <img src={RemoveIcon} alt="Remove Icon" />
                            <Link href="#" component='button' variant="subtitle2" onClick={this.removeFromDistrict} className={classes.removeDistrictLabel}>
                                Remove from District(s)
                            </Link>
                        </Grid>}
                </Grid>
            </Grid>
        )
    }
}

SelectedBlockPanel.propTypes = {
    modelingMapStore: PropTypes.object,
    classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles)
)(SelectedBlockPanel)
