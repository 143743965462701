import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    roundedRect: {
        background: theme.palette.white,
        borderRadius: '8px',
        alignItems: 'center',
        display: 'flex'       
    },
    container: {
        padding: '15px 0'
    },
    info1: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            margin: '10px 0'
        }
    },
    contentText: {
        [theme.breakpoints.up('sm')]: {
            padding: '0 30px'
        },        
        [theme.breakpoints.down('xs')]: {
            margin: '20px 20px'
        },
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    titleStyle: {
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '27px',
        letterSpacing: '0.2px',
        color: theme.palette.primary.contrastText
    }
});

class RedivisionStageDetails extends React.Component {
    render() {
        const { classes, imageLogo, title, content, subTitle } = this.props;
        return (
            <React.Fragment>
                <Grid container className={classes.container}>
                    <Grid item xs={12} sm={3} md={3} className={classes.info1}>
                        <img src={imageLogo} alt="ActiveStage1Logo"></img>
                        <Typography variant="h2" color="secondary" className={classes.titleStyle}>
                            {title}
                        </Typography>
                        <Typography variant="body1">
                            {subTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} className={classes.roundedRect}>
                        <Typography variant="h3" className={classes.contentText}>
                            {content}
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

RedivisionStageDetails.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    subTitle: PropTypes.object,
    imageLogo: PropTypes.string,
    content: PropTypes.object
};

export default compose(
    withStyles(styles)
)(RedivisionStageDetails);
