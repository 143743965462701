import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '../../../../static/images/icon-close-primary.svg'
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    actionButton: {
        textTransform: 'none'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
      },
    viewNowButton: {
        color: theme.palette.primary.main,
        textTransform: 'none',
    },
});

@inject('submissionStore')
@observer
class ConfirmMappingSubmissionDialog extends React.Component {
    render() {
        const { classes, submissionStore } = this.props;
        const hasMappingSegment = submissionStore.draftDetails.hasMappingSegment;

        return (
            <Dialog
                open={submissionStore.confirmMappingSubmissionDialogOpen}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle disableTypography>
                    <Typography variant="h2">
                        {hasMappingSegment ? "Would you like to review your draft map?" : "Would you like to create a map?"}
                    </Typography>
                    <IconButton className={classes.closeButton} onClick={this.handleDialogClose}>
                        <img src={CloseIcon} alt="Remove" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            hasMappingSegment ?
                            "You have completed a written submission. You have saved a draft map. Would you like to view and edit your draft map?"
                            :
                            "You have completed a written submission. Would you also like to use the VEC’s Boundary Builder to complete a map of your preferred electoral structure?"
                        }                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className={classes.viewNowButton} onClick={this.handleDialogNo}>
                        {hasMappingSegment ? "No, submit my written submission and my draft map" : "No, submit my written submission only"}
                    </Button>
                    <Button variant="contained" className={classes.actionButton} onClick={this.handleDialogYes} color="primary" autoFocus>
                        {hasMappingSegment ? "Yes, I would like to review my draft map" : "Yes, I want to add a map to my submission"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleDialogClose = () => {
        this.props.submissionStore.setConfirmMappingSubmissionDialogOpen(false);
    }

    handleDialogYes = () => {
        const { submissionStore, history } = this.props;

        if (submissionStore.isValidWrittenSubmission()) {
            history.push('/mapping');
            submissionStore.setshowSaveDraftMessage(false);
            submissionStore.saveSubmission(true); //save written draft before navigating to map           
            submissionStore.setConfirmMappingSubmissionDialogOpen(false);
        }
        else {
            submissionStore.setConfirmMappingSubmissionDialogOpen(false);
            submissionStore.showWrittenSubmissionValidationError();
        }
    }

    handleDialogNo = () => {
        const { submissionStore } = this.props;
      
        submissionStore.setConfirmMappingSubmissionDialogOpen(false);
        submissionStore.validateWrittenAndSave();
    }
}

ConfirmMappingSubmissionDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    submissionStore: PropTypes.object,
    history: PropTypes.object
}

export default compose(
    withStyles(styles),
    withWidth(),
)(ConfirmMappingSubmissionDialog);