import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';

const styles = () => ({
    stepImage: {
        marginTop: '39px',
        marginLeft: '158px',
        width: '645px',
        height: '387px',
    },
    stepText: {
        margin: '30px 0 30px 158px',
        width: '645px'
    },
    stepTitle: {
        margin: '38px 0 0 0'
    },
    stepSubContent: {
        marginLeft: '158px',
        width: '645px'
    }
});

class MapGuidelineDetails extends React.Component {
    render() {
        const { classes, image, title, content } = this.props;
        let contentClasses = [classes.content];
        return (
            <React.Fragment>
                <Typography align="center" color="secondary" variant="h2" className={classes.stepTitle}>
                    {title}
                </Typography>
                {image ?
                    <>
                        <img src={image} alt="step image" className={classes.stepImage} />
                        <Typography variant="subtitle2" className={classes.stepText}>
                            {content}
                        </Typography>
                    </> : 
                    <Typography variant="subtitle2" className={classes.stepSubContent}>
                        <ReactMarkdown source={content} className={contentClasses.join(' ')} />
                    </Typography>}
            </React.Fragment>
        );
    }
}

MapGuidelineDetails.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object,PropTypes.string])
};

export default withStyles(styles)(MapGuidelineDetails);