import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Hidden, Grid, Button, Typography} from "@material-ui/core";
import { observer } from "mobx-react";
import { observable, computed } from 'mobx';
import SegmentList from "./segment-list"
import IconArrowUp from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-arrow-up.svg';
import IconArrowDown from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-arrow-down.svg';

const styles = theme => ({
    withinBoundsDeviation: {
        color: "#438600",
        textAlign: "right"
    },
    outOfBoundsDeviation: {
        color: "#ca0000",
        textAlign: "right"
    },
    districtList: {
        overflowX: "hidden",
        overflowY: "auto",
        maxHeight: "400px",
        marginBottom: '30px'
    },
    inViewHeaderText: {
        fontWeight: 'bold', 
        marginLeft: '25px', 
        marginTop: '24px'
    },
    allHeaderText: {
        fontWeight: 'bold', 
        marginLeft: '25px', 
        marginTop: '24px'
    },
    headerContainer: {
        background: theme.palette.primary.dark,
        height: '39px'
    },
        segmentTableCell: {
        padding: '0px',
        verticalAlign: 'middle'
    },
    districtsHeader: {
        width: '50%'
    },
    votersHeader: {
        width: '20%'
    },
    deviationHeader: {
        width: '30%'
    },
    districtsHeaderText: {
        marginLeft: '25px'
    },
    segmentTableHeadingText: {
        fontWeight: '600',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        color: theme.palette.white
    },
    segmentTableHeadingContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    sortingButton: {
        minWidth: '10px',
        heigth: '10px'
    },  
    numberTableCell: {
        textAlign: 'left',
        paddingTop: '2px'
    }
});

@observer
class ExistingBoundariesInfo extends React.Component {
    constructor(props) {
        super(props);
        this.modelingStore = props.modelingStore;
    }

    @observable sortOrder = 'asc';
    @observable nameColumnSort = 'name';
    @observable sortingState = {};

    @computed get boundariesSorted() {
        const { existingBoundaries } = this.modelingStore;
        return [...existingBoundaries].sortByProperty(this.nameColumnSort, this.sortOrder, "properties");
    }

    @computed get boundariesInViewSorted() {
        const { existingBoundariesInView } = this.modelingStore;
        return [...existingBoundariesInView].sortByProperty(this.nameColumnSort, this.sortOrder, "properties");
    }

    deviationClass = deviation => {
        return deviation > -10 && deviation < 10 ? this.props.classes.withinBoundsDeviation : this.props.classes.outOfBoundsDeviation;
    }

    toggleExistingWardNames = () => {
        this.modelingStore.toggleShowExistingBoundaryLabels();
    }

    handleColumnSort = (nameColumnSort, sortOrder) => {
        this.nameColumnSort = nameColumnSort;
        this.sortOrder = sortOrder;
    }

    handleTableSorting = (columnSort, nameColumnSort) => {
        let sortingValue = this.sortingState[`columnSort${columnSort}`];
        sortingValue = typeof sortingValue === 'undefined' ? false : !sortingValue;
        Object.assign(this.sortingState, { [`columnSort${columnSort}`]: sortingValue });
        this.nameColumnSort = nameColumnSort;
        this.sortOrder = sortingValue ? 'asc' : 'desc';

        Object.keys(this.sortingState).forEach(function (key) {
            if (key.startsWith('columnSort') && key !== `columnSort${columnSort}`) {
                Object.assign(this.sortingState, { [key]: true });
            }
        }.bind(this));
    }

    render() {
        const { classes } = this.props;
        const { existingBoundaries } = this.modelingStore;

        return (
            <Hidden smDown>
                <Grid container className={classes.headerContainer}>
                    <Grid className={[classes.segmentTableCell, classes.districtsHeader].join(' ')}>
                        <Grid className={classes.segmentTableHeadingContainer}>
                            <Typography variant={"body1"} className={[classes.segmentTableHeadingText, classes.districtsHeaderText].join(' ')}>
                                Districts
                            </Typography>
                            <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(1, 'name')}>
                                {this.sortingState['columnSort1'] || typeof this.sortingState['columnSort1'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className={[classes.segmentTableCell, classes.numberTableCell, classes.votersHeader].join(' ')}>
                        <Grid className={classes.segmentTableHeadingContainer}>
                            <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                Voters
                            </Typography>
                            <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(2, 'electors')}>
                                {this.sortingState['columnSort2'] || typeof this.sortingState['columnSort2'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className={[classes.segmentTableCell, classes.numberTableCell, classes.deviationHeader].join(' ')}>
                        <Grid className={classes.segmentTableHeadingContainer}>
                            <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                Deviation
                            </Typography>
                            <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(3, 'deviation')}>
                                {this.sortingState['columnSort3'] || typeof this.sortingState['columnSort3'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                            </Button>
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid className={classes.districtList}>
                    {this.boundariesInViewSorted.length > 0 &&
                        <Typography color={"secondary"} variant={"body1"} className={classes.inViewHeaderText} >
                            CURRENTLY IN VIEW
                        </Typography>
                    }
                    <SegmentList segments={this.boundariesInViewSorted} keyPrefix={'inviewBoundary'} idProperty={"existingElectorateId"} allowEditing={false} allowSelection={false}/>
                    {this.boundariesInViewSorted.length > 0 && 
                        <Typography color={"secondary"} variant={"body1"} className={classes.allHeaderText} >
                            ALL
                        </Typography>
                    }
                    <SegmentList segments={this.boundariesSorted} keyPrefix={'existingBoundary'} idProperty={"existingElectorateId"} allowEditing={false} allowSelection={false}/>
                </Grid>
            </Hidden>
        )
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        parentElement: PropTypes.object,
        modelingStore: PropTypes.object
    }
}

export default withStyles(styles)(ExistingBoundariesInfo)
