import { observable, computed, action, runInAction } from 'mobx';
import moment from 'moment';
import redivisionService from '../services/redivision.service';
import { Enums } from '../enums';

export default class RedivisionStore {

    constructor(sessionStore) {
        this.sessionStore = sessionStore;
        this.routerStore = sessionStore.routerStore;
        this.authStore = sessionStore.authStore;
        this.msgBoxStore = sessionStore.msgBoxStore;
        redivisionService.setAuthStore(this.authStore);
        this.getActiveRedivision();
    }

    @observable activeRedivision = null;
    @observable requestError = null;

    @computed get displayMakeSubmissions() {
        const submissionStore = this.sessionStore.submissionStore;
        const authStore = this.sessionStore.authStore;
        const submissions = submissionStore.currentSubmissions;
        const hasRetrievedSubmissions = submissionStore.hasRetrievedSubmissions;
        let hasDraftStageOne = false;
        let hasDraftStageTwo = false;

        if (authStore.isUserAuthenticated) {

            if (hasRetrievedSubmissions) {
                hasDraftStageOne = submissionStore.hasDraftSubmission(submissions, Enums.RedivisionSubmissionStage.InitialSubmission, Enums.ReviewType.StateRedivision);
                hasDraftStageTwo = submissionStore.hasDraftSubmission(submissions, Enums.RedivisionSubmissionStage.ProposedBoundaries, Enums.ReviewType.StateRedivision);
                return (this.isStageOneActive || this.isStageTwoActive) && !((hasDraftStageOne && this.isStageOneActive) || (hasDraftStageTwo && this.isStageTwoActive));
            }
            else {
                return false;
            }
        }
        else {
            return this.isStageOneActive || this.isStageTwoActive;
        }
    }

    @computed get displaySignInFooterNote() {
        return !this.sessionStore.authStore.isUserAuthenticated;
    }

    @computed get isInitialSubmissionOpen() {
        return this.activeRedivision && this.activeRedivision.isInitialSubmissionOpen;
    }

    @computed get isProposedBoundariesPublished() {
        return this.activeRedivision && this.activeRedivision.isProposedBoundariesPublished;
    }

    @computed get isStageOneActive() {
        return this.activeRedivision && this.activeRedivision.isInitialSubmissionOpen && !this.activeRedivision.isInitialSubmissionClose;
    }

    @computed get isStageOneClosed() {
        return this.activeRedivision && this.activeRedivision.isInitialSubmissionOpen && this.activeRedivision.isInitialSubmissionClose;
    }

    @computed get isStageTwoActive() {
        return this.activeRedivision && this.activeRedivision.isProposedBoundariesPublished && !this.activeRedivision.isSuggestionsAndObjectsionsClosed;
    }

    @computed get isStageTwoClosed() {
        return this.activeRedivision && this.activeRedivision.isProposedBoundariesPublished && this.activeRedivision.isSuggestionsAndObjectsionsClosed;
    }

    @action getActiveRedivision = () => {
        redivisionService.getActiveRedivision().then((response) => {
            runInAction(() => {
                this.activeRedivision = response;
            });
        }).catch((error) => {
            this.requestError = error;
        });
    }

    @action getMilestoneDate = (milestoneType) => {
        let result = null;

        if (this.activeRedivision && this.activeRedivision.milestones && this.activeRedivision.milestones.length > 0) {
            const milestone = this.activeRedivision.milestones.find(m => m.dateType === milestoneType);

            if (milestone) {
                result = moment(milestone.date);
            }
        }

        return result;
    }

    @action startTextBasedSubmission = () => {
        const { authStore, routerStore } = this.sessionStore;
        if (authStore.isUserAuthenticated) {
            routerStore.history.push('/writtensubmission');
        } else {
            this.displaySignInPopup();
        }
    }

    @action startMapBasedSubmission = (submissionId) => {
        this.authStore.authenticateUser().then(() => {
            let mapUrl = '/mapping/' + (submissionId? submissionId : '');
            this.msgBoxStore.show({
                header: 'Map processing',
                content: 'Please note that loading, saving or submitting a map may take up to 60 seconds depending on your computer\'s capacity.',
                size: 'sm',
                inputOptions: { type: 'NONE' },
                hideCancel: true,
                confirmButton: 'Continue'
            }).then(() => {
                this.routerStore.history.push(mapUrl);
            }).catch((err) => {
                if (err) {
                    console.log(err);
                }
            });            
        });
    }

    @action displaySignInPopup = () => {
        if (!this.authStore.isUserAuthenticated) {
            this.authStore.authenticateUser();
        }
    }

    @computed get submissionStage() {
        if (this.isStageOneActive) {
            return Enums.RedivisionSubmissionStage.InitialSubmission;
        }
        else if (this.isStageTwoActive) {
            return Enums.RedivisionSubmissionStage.ProposedBoundaries;
        } else if (this.activeRedivision.isFinalReportPublished) {
            return Enums.RedivisionSubmissionStage.FinalisedBoundaries;
        } else {
            return Enums.RedivisionSubmissionStage.None;
        }
    }
}