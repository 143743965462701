import React from 'react'
import PropTypes from 'prop-types';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, TableRow, TableCell, TableBody, Grid, TextField, ClickAwayListener } from '@material-ui/core';
import { GithubPicker } from 'react-color';
import IconDelete from '-!svg-react-loader?name=IconDelete!../../../static/images/delete-icon.svg';
import IconPencil from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-pencil-2.svg';
import { inject, observer } from 'mobx-react';
import FloatingBox from '../floating-box';
import { observable, action } from 'mobx'

const styles = theme => ({
    segmentTableCell: {
        padding: '0px',
        verticalAlign: 'middle'
    },
    segmentName: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '116px',
        paddingTop: '8px',
        textTransform: 'capitalize'
    },
    numberTableCell: {
        textAlign: 'right',
        padding: '0px'
    },
    segmentRow: {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        height: '45px'
    },
    selectRow: {
        cursor: 'pointer',
        '&:hover': {
            background: '#eaeaea'
        }
    },
    selectedRow: {
        background: '#e0e0e0',
        '&:hover': {
            background: '#d0d0d0'
        }
    },
    evenRow: {
        background: theme.palette.white
    },
    nameTableCol: {
        display: 'inline',
    },
    segmentColourSwatch: {
        height: '18px',
        width: '18px',
        border: 'none',
        padding: '0px',
        borderRadius: '2px',
        cursor: 'pointer'
    },
    segmentNameEdit: {
        maxWidth: '110px',
        textTransform: 'capitalize'
    },
    clickableIcon: {
        cursor: 'pointer',
        paddingLeft: '4px',
        '@media print': {
            display: 'none !important'
        }
    },
    editIcon: {
        marginBottom: '4px',
        width: '14px',
        height: '14px',
        '@media print': {
            display: 'none !important'
        }
    },
    deleteIcon: {
        width: '16px',
        height: '16px',
        paddingTop: '5px',
        marginRight: '12px'
    },
    withinBoundsDeviation: {
        color: '#438600'
    },
    outOfBoundsDeviation: {
        color: '#ca0000'
    },
    tooltipBottom: {
        marginBottom: '10px'
    },
    noWrap: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap !important'
    },
    arrowPopper: {
        '&[x-placement*="bottom"] $arrowArrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: 'transparent transparent #4a90e2 transparent',
            },
        },
        '&[x-placement*="top"] $arrowArrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: '#4a90e2 transparent transparent transparent',
            },
        },
    },
    arrowArrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            marginLeft: '20px',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    tableHeader: {
        padding: '0px',
        borderBottom: 'none'
    },
    districtsHeader: {
        width: '40%'
    },
    votersHeader: {
        width: '22%'
    },
    deleteHeader: {
        width: '8%'
    },
    districtsHeaderText: {
        marginLeft: '25px'
    },
    saveContainer: {
        textAlign: 'left'
    },
    segmentNameContainer: {
        marginLeft: '10px'
    },
    segmentShowEditContainer: {
        marginLeft: '25px'
    },
    districtNameCell: {
        minWidth: '180px'
    }
});

@inject('msgBoxStore', 'modelingMapStore')
@observer
class SegmentList extends React.Component {

    constructor(props) {
        super();
        this.modelingStore = props.modelingMapStore;
    }    

    @observable editSegmentName = false;
    @observable colourPickerActive = false;
    @observable colourPickerAnchor = null;
    @observable showDeviationInfo = false;
    @observable selectedSegmentName = '';
    @observable hasEditedSegmentName = false;
    @observable isScrollToListReq = false;

    static propTypes = {
        classes: PropTypes.object.isRequired,
        modelingStore: PropTypes.object,
        finishBtnFn: PropTypes.func,
    }

    componentDidUpdate(){
        if(this.isScrollToListReq){
            this.scrollToEditedSegment();
            this.isScrollToListReq = false;
        }
    }

    render() {
        const { classes, segments, keyPrefix, idProperty, allowEditing, allowSelection } = this.props;
        const { selectedSegmentId, colourSwatches } = this.modelingStore;

        return <>
            { segments && segments.length > 0 &&
                (<Table>
                    <colgroup>
                        <col className={[classes.tableHeader, classes.districtsHeader].join(' ')} />
                        <col className={[classes.tableHeader, classes.votersHeader].join(' ')} />
                        <col className={classes.tableHeader} />
                        <col className={[classes.tableHeader, classes.deleteHeader].join(' ')} />
                    </colgroup>
                    <TableBody>
                        {segments && segments.length > 0 && segments.map((segment, index) => {
                            return (
                                <TableRow className={[classes.segmentRow, classes.noWrap, allowSelection ? classes.selectRow: null, segment.properties[idProperty] == selectedSegmentId ? classes.selectedRow : classes.evenRow].join(' ')} 
                                 key={`${keyPrefix + segment.properties[idProperty]}`}
                                 id={`${keyPrefix + segment.properties[idProperty]}`}
                                 onClick={(event) => { if(allowSelection) this.handleSelectedSegment(event, segment.properties[idProperty]) }}>
                                    <TableCell className={[classes.segmentTableCell, classes.districtNameCell].join(' ')} >
                                        <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={0} className={[classes.noWrap, classes.segmentShowEditContainer].join(' ')}>
                                            {this.colourPickerAnchor && this.colourPickerActive && selectedSegmentId === segment.properties[idProperty] &&
                                                <ClickAwayListener onClickAway={event=>this.toggleColourPicker(event, segment.properties[idProperty])}>
                                                    <FloatingBox zIndex={1} anchorEl={this.colourPickerAnchor} position='under-left' offset={{ x: -5, y: 10 }} onClick={this.stopPropogation}>
                                                        <GithubPicker colors={colourSwatches} width='125px' onChange={(event) => { this.setSegmentColour(event.hex) }} />
                                                    </FloatingBox>
                                                </ClickAwayListener>
                                            }
                                            { allowEditing && 
                                                <Grid item id={keyPrefix+'_ColorSwatch_'+segment.properties[idProperty]} className={classes.segmentColourSwatch} style={{ background: segment.properties.colour }} onClick={event=>this.toggleColourPicker(event, segment.properties[idProperty])}>
                                                    {/*Colour swatch*/}
                                                </Grid>
                                            }
                                            <Grid item xs={10} className={allowEditing ? classes.segmentNameContainer: null}>
                                                {this.editSegmentName && segment.properties[idProperty] == selectedSegmentId ?
                                                    <ClickAwayListener onClickAway={(event) => this.handleEditSegmentName(event, segment.properties[idProperty])}>
                                                        <TextField value={this.selectedSegmentName.toLowerCase()} autoFocus={true} inputProps={{ className: classes.segmentNameEdit, maxLength: 26 }} 
                                                        onClick={this.stopPropogation} onChange={(event) => { this.updateSegmentName(event.target.value) }} onKeyDown={(event) => this.handleEditSegmentKeyDown(event) }/>
                                                    </ClickAwayListener>
                                                    : <Grid className={classes.nameTableCol}>
                                                        <span className={classes.segmentName}>{segment.properties.name.toLowerCase()}</span>
                                                        {allowEditing && <IconPencil className={[classes.clickableIcon, classes.editIcon].join(' ')} onClick={(event) => this.handleEditSegmentName(event, segment.properties[idProperty])} />}
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell className={[classes.segmentTableCell, classes.numberTableCell].join(' ')}>
                                        <Typography color={'textPrimary'}>
                                            {segment.properties.electors.toLocaleString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={[classes.segmentTableCell, classes.numberTableCell].join(' ')} >
                                        <Typography className={this.deviationClass(segment.properties.deviation)}>
                                            {segment.properties.deviation > 0 && '+'}{segment.properties.deviation.toFixed(2)}%
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.segmentTableCell} onClick={(event) => this.confirmDelete(event, segment.properties[idProperty])}>
                                         { allowEditing && <IconDelete className={[classes.clickableIcon, classes.deleteIcon].join(' ')} /> }
                                    </TableCell> 
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>)
            }
        </>
    }

    @action stopPropogation = (event)=>{
        event.stopPropagation();
    }

    @action handleEditSegmentName = (event, id)=>{
        event.stopPropagation();
        this.selectSegment(id);
        this.toggleEditSegmentName()
    }

    toggleColourPicker = (event, id) => {
        event.stopPropagation();        
        this.selectSegment(id);
        this.colourPickerAnchor = event.target;
        this.colourPickerActive = !this.colourPickerActive;
    }

    setSegmentColour = (newColour) => {
        this.modelingStore.selectedSegment.properties.colour = newColour;
        this.colourPickerActive = false;
        this.modelingStore.setIsMapDirty(true);
    }

    handleEditSegmentKeyDown = (event) => { 
        if (event.key === "Enter") {
            this.toggleEditSegmentName();
        }
    }

    @action toggleEditSegmentName = () => {
        this.editSegmentName = !this.editSegmentName;
        if(this.hasEditedSegmentName){
            this.isScrollToListReq = true;
        }
        if(!this.editSegmentName && this.selectedSegmentName) {
            this.modelingStore.selectedSegment.properties.name = this.selectedSegmentName;
            this.hasEditedSegmentName = false;
        }
    }

    scrollToEditedSegment = () => {
        const { selectedSegment } = this.modelingStore;
        const { keyPrefix, idProperty } = this.props;
        const id = keyPrefix + selectedSegment.properties[idProperty];
        const elemToScrollTo = document.getElementById(id);
        if (elemToScrollTo) {
            elemToScrollTo.scrollIntoView();
        }
    }

    @action updateSegmentName = (newName) => {
        this.selectedSegmentName = newName.toUpperCase();
        this.hasEditedSegmentName = true;
        this.modelingStore.setIsMapDirty(true);
    }

    selectSegment = (id) => {
        const prevSelectedSegment = this.modelingStore.selectedSegment;
        const { idProperty } = this.props;

        if(id !== prevSelectedSegment.properties[idProperty]) {

            if(this.hasEditedSegmentName) {
                this.toggleEditSegmentName();
            }
            else {
                this.editSegmentName = false;
            }
        }

        this.modelingStore.selectSegment(id);
        this.selectedSegmentName = this.hasEditedSegmentName &&  id === prevSelectedSegment.properties[idProperty] ? this.selectedSegmentName : this.modelingStore.selectedSegment.properties.name;
    }

    
    handleSelectedSegment = (event, id) => {
        console.log('inside handleSelectedSegment',event.bubbles);
        this.selectSegment(id);
        this.displaySelectedSegment();
    }

    displaySelectedSegment = () => {
        this.modelingStore.displaySelectedSegment();
    }
    
    deviationClass = (deviation) => {
        return (deviation > -10 && deviation < 10) ? this.props.classes.withinBoundsDeviation : this.props.classes.outOfBoundsDeviation;

    }

    confirmDelete = (event, id) => {
        event.stopPropagation();
        this.selectSegment(id);
        const { selectedSegment } = this.modelingStore;

        this.props.msgBoxStore.show({
            content: `Do you wish to delete ${selectedSegment.properties.name} district?`,
            header: 'Delete District',
            size: 'xs',
            inputOptions: { type: 'NONE' },
            hideCancel: false,
            confirmButton: 'Yes',
            cancelButton: 'No'
        }).then(() => {
            this.modelingStore.deleteSegment(this.modelingStore.selectedSegmentId);
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }
}

SegmentList.propTypes = {
    msgBoxStore: PropTypes.object,
    modelingMapStore: PropTypes.object,
    segments: PropTypes.any,
    keyPrefix: PropTypes.string.isRequired,
    idProperty: PropTypes.string.isRequired,
    allowEditing: PropTypes.bool.isRequired,
    allowSelection: PropTypes.bool.isRequired
}

export default compose(
    withStyles(styles)
)(SegmentList)
