import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Enums } from '../../../enums'
import DraftSubmissions from '../submission/draft-submissions';
import CompletedSubmissions from '../submission/completed-submissions';

const styles = (theme) => ({
    titleContainer: {
        paddingTop: "30px",
        fontSize: '24px',
        lineHeight: '36px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "20px"
        }
    },
    lastPositionStage: {
        order: 1
    },
});

class SubmissionsSummaryStage1 extends React.Component {
    render() {
        const { classes, showDraftsStage1, showCompletedStage1, isProposedBoundariesPublished } = this.props;
        return (
            <Grid container className={isProposedBoundariesPublished ? classes.lastPositionStage : null}>
                {(showDraftsStage1 || showCompletedStage1) &&
                    <Grid item xs={12}>
                        <Typography variant="h2" color="secondary" className={classes.titleContainer}>Stage One submissions</Typography>
                    </Grid>
                }
                {showDraftsStage1 &&
                    <Grid item xs={12}>
                        <DraftSubmissions stage={Enums.RedivisionSubmissionStage.InitialSubmission} reviewType={Enums.ReviewType.StateRedivision} />
                    </Grid>
                }
                {showCompletedStage1 &&
                    <Grid item xs={12}>
                        <CompletedSubmissions stage={Enums.RedivisionSubmissionStage.InitialSubmission} reviewType={Enums.ReviewType.StateRedivision} />
                    </Grid>
                }
            </Grid>
        );
    }
}

SubmissionsSummaryStage1.propTypes = {
    classes: PropTypes.object,
    showDraftsStage1: PropTypes.bool,
    showCompletedStage1: PropTypes.bool,
    isProposedBoundariesPublished: PropTypes.bool
};

export default withStyles(styles)(SubmissionsSummaryStage1);