import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import Header from '../header';
import ContactDetails from '../profile/contact-details';
import WrittenSubmission from '../reviews/submission/written-submission';
import RouteTransitionGuard from '../route-transition-guard';
import { inject, observer } from 'mobx-react';
import BrowserWarning from '../browser-warning';

const styles = theme => ({
    footerMargin: {
        height: '40px'
    },
    mainContainer: {
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            background: theme.palette.gray.gray400
        }
    },
    titleContainer: {

        background: theme.palette.primary.light,
        [theme.breakpoints.up('xs')]: {
            height: '140px'
        },
        [theme.breakpoints.up('sm')]: {
            height: '250px'
        },
        [theme.breakpoints.up('md')]: {
            height: '310px'
        }
    },
    titleText: {
        [theme.breakpoints.up('xs')]: {
            marginTop: '40px'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '61px'
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '81px'
        }
    },
    contentSection: {
        [theme.breakpoints.up('sm')]: {
            background: theme.palette.gray.gray400,
            paddingBottom: '100px'
        }
    },
    content: {
        minHeight: '250px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '0px',
            boxShadow: 'none',
            padding: '25px'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '-60px',
            borderRadius: '8px',
            padding: '50px'
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '-120px',
            padding: '70px 95px 90px 95px'
        }
    }
});

@inject('rootStore')
@observer
class FormsLayout extends React.Component {
    render() {
        const { classes, rootStore: { formTitle } } = this.props;

        return (
            <React.Fragment>
                <Grid className={classes.mainContainer}>
                    <Grid container>
                        <Header />
                    </Grid>
                    <Grid container className={classes.titleContainer}>
                        <Grid item xs={2} />
                        <Grid item xs={8} className={classes.titleText}>
                            <Typography id="title" variant={"h1"} color="secondary">{formTitle}</Typography>
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                    <Grid container className={classes.contentSection}>
                        <Grid item sm={2} />
                        <Grid item xs={12} sm={8}>
                            <Paper elevation={3} className={classes.content}>
                                <Switch>
                                    <Route path="/profile" component={() => <ContactDetails />} />
                                    <Route path="/writtensubmission/:submissionId?" component={() => <WrittenSubmission />} />
                                </Switch>
                            </Paper>
                        </Grid>
                        <Grid item sm={2} />
                    </Grid>
                    <BrowserWarning />
                </Grid >
                <RouteTransitionGuard />
            </React.Fragment>
        );
    }
}

FormsLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    rootStore: PropTypes.object
};

export default compose(
    withStyles(styles)
)(FormsLayout);
