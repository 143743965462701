import { observable, action, runInAction, computed } from "mobx";
import moment from 'moment';
import { Enums } from '../enums';

import councilService from '../services/council.service';

export default class CouncilStore {
    constructor(sessionStore) {
        this.sessionStore = sessionStore;
    }

    @observable councilResult = [];
    @observable getCouncilsError = null;
    @observable councilsWithAcitveSubmissions = [];
    @observable getCouncilsWithAcitveSubmissionsError = null;
    @observable submissionslResult = [];
    @observable getSubmissionsError = null;
    @observable councilSearchQuery = '';
    @observable councilSearchResult = [];
    @observable councilDetails = null;
    @observable getCouncilDetailsError = null;
    @observable myCouncilsOnly = false;
    @observable reviewClosedDialogOpen = false;
    @observable checkSubmissionAllowedFailedDialogOpen = false;
    @observable checkSubmissionAllowedTryAgainFn = null;

    @computed get submissionStage() {
        if (this.councilDetails && this.councilDetails.review.isAddendumSubmissionsOpen) {
            return Enums.SubmissionStage.Addendum;
        }
        else if (this.councilDetails && this.councilDetails.review.isPreliminaryReportPublished) {
            return Enums.SubmissionStage.Response;
        } else if (this.councilDetails && this.councilDetails.review.isPreliminarySubmissionsOpen) {
            return Enums.SubmissionStage.Preliminary;
        } else {
            return Enums.SubmissionStage.None;
        }
    }

    @computed get canShowWrittenSubmissions() {
        if (this.councilDetails && this.councilDetails.review && this.councilDetails.review.reviewType === 1)
        {
            return this.councilDetails.review.isPreliminarySubmissionsOpen && !this.councilDetails.review.isPreliminarySubmissionsClose;
        }
       
        return false;
    }

    @computed get learnMoreLink() {
        let result = '#';

        if (this.councilDetails) {
            let councilName = this.councilDetails.lgaName.toLowerCase();

            if (councilName.startsWith('greater ')) {
                councilName = councilName.replace('greater ', '');
            }

            councilName = councilName.replace(/ /g, '');

            result = `https://www.vec.vic.gov.au/reviews/${councilName}rr.html`;
        }

        return result;
    }

    @action getCouncils() {
        councilService.getCouncils().then((response) => {
            runInAction(() => {
                this.councilResult = response;
                this.setCouncilSearchQuery('');
            });
        }).catch((error) => {
            this.getCouncilsError = error;
        });
    }

    @action getCouncilsWithAcitveSubmissions() {
        councilService.getCouncilsWhthActiveSubmissions().then((response) => {
            runInAction(() => {
                this.councilsWithAcitveSubmissions = response;
            });
        }).catch((error) => {
            this.getcouncilsWithAcitveSubmissionsError = error;
        });
    }

    @computed get filteredCouncils() {
        const regex = new RegExp(`\\b${RegExp.escape(this.councilSearchQuery)}`, 'gi');
        return this.councilResult
            .map(c => {
                const councilWithActiveSubmission = this.councilsWithAcitveSubmissions.filter(ac => ac.councilId === c.councilId)[0];
                let council = {
                    councilId: c.councilId,
                    displayName: c.displayName
                }

                if (councilWithActiveSubmission) {
                    council.submissionCount = councilWithActiveSubmission.submissionCount;
                }

                return council;
            })
            .filter(council => {
                const matches = council.displayName.match(regex);   // Use regex matches instead of test because regex.test does not return correct results
                const match = matches && matches.length > 0;

                return this.myCouncilsOnly ?
                    match && council.submissionCount && (council.submissionCount.preliminary + council.submissionCount.representationResponse +
                        council.submissionCount.subdivisionResponse + council.submissionCount.addendum) > 0 :
                    match;
            });
    }

    @action
    setCouncilSearchQuery(query) {
        this.councilSearchQuery = query;
    }

    @action
    getCouncilDetails(councilId) {        
        this.councilDetails = null;//Clear the values first so old values are not rendered whilst loading
        this.sessionStore.submissionStore.resetSubmissionForm();
        this.sessionStore.modelingMapStore.clearMapData();
        councilService.getCouncilDetails(councilId).then((response) => {
            runInAction(() => {
                this.councilDetails = response;
            });
        }).catch((error) => {
            this.getCouncilDetailsError = error;
        });
    }

    @action
    setMyCouncilsOnly(value) {
        this.myCouncilsOnly = value;
    }

    getMilestoneDate(review, milestoneType) {
        let result = null;

        if (review && review.milestones && review.milestones.length > 0) {
            const milestone = review.milestones.filter(m => m.dateType === milestoneType)[0];

            if (milestone) {
                result = moment(milestone.date);
            }
        }

        return result;
    }

    @action
    setReviewClosedDialogOpen(open) {
        this.reviewClosedDialogOpen = open;
    }

    @action
    setCheckSubmissionAllowedFailedDialogOpen(open, tryAgainFn) {
        this.checkSubmissionAllowedFailedDialogOpen = open;
        this.checkSubmissionAllowedTryAgainFn = tryAgainFn;
    }

    getReviewDocumentUrl(reportType, fileExtensionType) {
        const councilDetails = this.councilDetails;
        let result = '#';

        if (councilDetails && councilDetails.review && councilDetails.review.reviewDocuments) {
            const reviewDocuments = councilDetails.review.reviewDocuments;
            const reviewDocument = reviewDocuments.filter(d => d.reportType === reportType && d.fileExtensionType === fileExtensionType)[0];

            if (reviewDocument) {
                result = `/api/councils/${councilDetails.councilId}/reviewdocuments/${reviewDocument.reviewDocumentId}?fileName=${reviewDocument.fileName}`;
            }
        }

        return result;
    }

    checkSubmissionAllowed = (callback) => () => {
        if (this.councilDetails) {
            councilService.isSubmissionAllowed(this.councilDetails.councilId, this.submissionStage)
                .then(response => {
                    if (response === false) {
                        this.setReviewClosedDialogOpen(true);
                    }
                    else {
                        callback();
                    }
                })
                .catch(() => {
                    this.setCheckSubmissionAllowedFailedDialogOpen(true, this.checkSubmissionAllowed(callback));
                });
        }
    }
}
