import React from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

@inject('routerStore')
class RouterStoreInitializer extends React.Component {

    // Please do not alter this as this is the top most level where we can initialize the router store.
    constructor(props){
        super(props);
        const {routerStore} = this.props;
        routerStore.setRoute(this.props.location, this.props.match, this.props.history);
    }

    render() {
        return this.props.children;
    }
}

RouterStoreInitializer.propTypes = {
    location: PropTypes.object,
    children: PropTypes.object,
    routerStore: PropTypes.object,
    match: PropTypes.object,
    history:PropTypes.object
};

export default withRouter(RouterStoreInitializer);