import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Header from '../../header';
import StartSubmission from './start-submission';
import RedivisionStages from './redivision-stages';
import BrowserWarning from '../../browser-warning';

class LandingPage extends React.Component {
    render() {
        return (
            <Grid container>
                <Header />
                <RedivisionStages />
                <StartSubmission />
                <BrowserWarning />
            </Grid>
        );
    }
}

LandingPage.propTypes = {
    classes: PropTypes.object
};

export default LandingPage;