import fetchIntercept  from 'fetch-intercept';


export const unregister = fetchIntercept.register({
    request: function (url, config) {
        config = {...config, headers: config?{...config.headers, Authorization: `Bearer ${window.accessToken}`} : {Authorization: `Bearer ${window.accessToken}`}};
        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});