import React from 'react'
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { withRouter, Prompt } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Messages } from '../messages';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import StyledPrimaryOutlinedButton from './styled-primary-outlined-button';
import StyledPrimaryContainedButton  from './styled-primary-contained-button';


const styles = theme => ({
    dialogContainer: {
        width: '500px',
        height: '300px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: 'unset',
            height: 'unset',
        }
    },
    actionContainer: {
        justifyContent: 'center'
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '42px',
        color: '#0E3A6C',
        textAlign: 'center',
        alignItems: 'center',
    },
    content: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: '#39444F',
        textAlign: 'center',
        alignItems: 'center',
    },
    cancelButtonStyle:{
        float: 'right',
        marginRight: '15px',
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        }
    }
})

@withRouter
@inject('rootStore')
@observer
class RouteTransitionGuard extends React.Component {
    @observable modalVisible = false;
    @observable lastLocation = null;

    @action setModalVisible(isVisible) {
        this.modalVisible = isVisible;
    }

    @action setLastLocation(location) {
        this.lastLocation = location;
    }

    showModal = (location) => {
        this.setLastLocation(location);
        this.setModalVisible(true);
    }

    handleCloseWithoutNavigation = () => {
        this.setModalVisible(false);
    }

    handleBlockedNavigation = (nextLocation) => {
        const { rootStore } = this.props;
        const { isDirty } = rootStore;

        if (isDirty) {
            this.showModal(nextLocation)
            return false
        }

        return true
    }

    handleConfirmNavigationClick = () => {
        const { rootStore, history } = this.props;
        this.setModalVisible(false);
        if (this.lastLocation) {
            rootStore.setDirty(false);
            history.push(this.lastLocation.pathname, this.lastLocation.state);
        }
    }

    render() {
        const { rootStore, classes } = this.props
        const warningMessage = Messages.NavAwayConfirmMsg;

        return (
            <React.Fragment>
                <Prompt
                    when={rootStore.isDirty}
                    message={this.handleBlockedNavigation} />
                <Dialog
                    open={this.modalVisible}
                    disableBackdropClick>
                    <Grid container className={classes.dialogContainer}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <DialogTitle><Typography className={classes.title}>Leave page?</Typography></DialogTitle>
                            <DialogContent>
                                <DialogContentText className={classes.content}>
                                {warningMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className={classes.actionContainer}>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <StyledPrimaryOutlinedButton onClick={this.handleCloseWithoutNavigation} color="primary" variant="outlined" className={classes.cancelButtonStyle}>
                                            Cancel
                                        </StyledPrimaryOutlinedButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <StyledPrimaryContainedButton onClick={this.handleConfirmNavigationClick} color="primary" variant="contained">
                                            OK
                                        </StyledPrimaryContainedButton>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Dialog>
            </React.Fragment>
        )
    }
}

RouteTransitionGuard.propTypes = {
    classes: PropTypes.object.isRequired,
    rootStore: PropTypes.object,
    history: PropTypes.object
}

export default withStyles(styles)(RouteTransitionGuard);