import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, TableRow, TableCell, TableBody, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx'

// eslint-disable-next-line
const styles = theme => ({
    segmentTableCell: {
        padding: '0px',
        verticalAlign: 'middle'
    },
    segmentName: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '110px',
        paddingTop: '8px',
        textTransform: 'capitalize'
    },
    numberTableCell: {
        textAlign: 'right',
        padding: '0px'
    },
    tableRow: {
        cursor: 'pointer',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        height: '45px',
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap !important',
        '&:hover': {
            background: '#eaeaea'
        }
    },
    colourSwatch: {
        height: '18px',
        width: '18px',
        border: 'none',
        padding: '0px',
        margin: '10px',
        cursor: 'pointer',
        marginLeft: '25px',
        background: '#000000'
    },
    labelCell: {
        padding: '0px',
        borderBottom: 'none',
        width: '40%'
    },
    votersCell: {
        padding: '0px',
        borderBottom: 'none',
        width: '22%'
    },
    deleteCell: {
        width: '8%'
    },
    districtNameCell: {
        minWidth: '180px'
    }
});

@inject('modelingMapStore')
@observer
class UnassignedInfoRow extends React.Component {

    @observable editSegmentName = false;
    @observable colourPickerActive = false;
    @observable colourPickerAnchor = null;
    @observable showDeviationInfo = false;

    render() {
        const { classes, modelingMapStore: {unassignedReviewElectors} } = this.props;

        return (unassignedReviewElectors > 0 ?
                <Table>
                    <colgroup>
                        <col className={classes.labelCell} />
                        <col className={classes.votersCell} />
                        <col />
                        <col className={classes.deleteCell} />
                    </colgroup>
                    <TableBody>
                            <TableRow className={classes.tableRow} onClick={() => { this.markUnassignedNuggets()}}>
                                <TableCell className={[classes.segmentTableCell, classes.districtNameCell].join(' ')}>
                                    <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={0} className={classes.noWrap}>                                            
                                        <Grid item id='unassignedSwatch' className={classes.colourSwatch} >
                                            {/*Colour swatch*/}
                                        </Grid>
                                        <Grid item >
                                            <span className={classes.segmentName}>Unassigned</span>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell className={classes.numberTableCell}>
                                    <Typography color={'textPrimary'}>
                                        {unassignedReviewElectors.toLocaleString()}
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
        : null)
    }

    markUnassignedNuggets() {
        this.props.modelingMapStore.markUnassignedNuggets();
    }   
    
    static propTypes = {
        classes: PropTypes.object.isRequired,
        modelingMapStore: PropTypes.object,
    }
}

export default withStyles(styles)(UnassignedInfoRow)
