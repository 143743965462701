import { observable, action } from 'mobx'

export default class MsgBoxStore {
    @observable cancelButton = 'Cancel'; 
    @observable confirmButton = 'Ok'; 
    @observable hideConfirm = false;
    @observable content = ''; 
    @observable header = '';
    @observable inputValid= true;
    @observable inputOptions = {type: 'TEXT', value: null, label: '', items: [], failedValidationMsg: ' ', validateFn: null, inputControl: null,};
    @observable open = false; 
    @observable size = false; 
    
    @observable close = () => {
        this.open = false;
        this.rejector();        
    }

    @observable confirm = () => {
        if (this.validateData(this.inputOptions.value)){
            this.open = false;
            this.resolver(this.inputOptions.value);
        }
    }

    @action show = ({cancelButton, confirmButton, content, header, inputOptions, size, hideConfirm, hideCancel, leftAlignContent}) => {
        let defaultInputOptions = {type: 'TEXT', value: null, label: '', items: [], editable: true, failedValidationMsg: ' ', validateFn: null, inputControl: null,};
        this.deferred = new Promise((resolver, reject) => {
            this.resolver = resolver;
            this.rejector = reject;            
        });        
        this.cancelButton = cancelButton || 'Cancel';
        this.confirmButton = confirmButton || 'OK';
        this.hideConfirm = hideConfirm || false;
        this.hideCancel = hideCancel || false;
        this.leftAlignContent = leftAlignContent || false;
        this.content = content;
        this.header = header;
        this.inputOptions = {...defaultInputOptions, ...inputOptions}; 
        this.size = size || false;
        this.open = true;        
        this.inputValid = true
        if (this.hideCancel && this.hideConfirm) {
            this.rejector();
        }
        return this.deferred;
    }

    //Loops through an array showing collection of messages boxes. 
    //This will update the array with each messagebox result and return the promise 
    //and resolve the promise after all have been shown regardless of the user selection
    @action showMultiple = (arrayOfMessages) => {
        let deferred = new Promise((resolve) => {
            let remainingMessages = arrayOfMessages.filter(message => message.dialogResult == undefined);
            if (remainingMessages.length != 0) {
                this.show(remainingMessages[0]).then((result) => {                
                    remainingMessages[0].dialogResult = result;
                    this.showMultiple(arrayOfMessages).then(() => {
                        resolve();
                    });
                }, ()=>{
                    remainingMessages[0].dialogResult = false;
                    this.showMultiple(arrayOfMessages).then(() => {
                        //Were not tracking the individual promises so just resolve regardless of the user response
                        resolve();
                    });
                });
            } else {
                resolve(null);            
            }
        });
        return deferred;
    }

    @action validateData(value) {        
        if (!this.inputOptions.validateFn || this.inputOptions.validateFn(value)){
            this.inputValid = true;                                
        } else {
            this.inputValid = false;                                
        }
        this.inputOptions.value = value
        return this.inputValid;
    }
}
