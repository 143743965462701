import 'whatwg-fetch'

export default class BaseService {

    authStore = null;

    setAuthStore (authStore) {
        this.authStore = authStore;
    }

    fetch (url, options) {
        return this.authStore ?  this.authStore.renewAccessToken().then( () =>  {

            return options ? fetch(url, options) : fetch(url);
        }
        ).catch( (err) => { return Promise.reject(err); } ) : options ? fetch(url, options) : fetch(url);
    }
}