import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

const styles = {
    actionButton: {
        textTransform: 'none'
    }
};

@inject('rootStore')
@observer
class ConfirmNavigationDialog extends React.Component {
    render() {
        const { classes, rootStore, confirmNavigationText } = this.props;

        return (
            <Dialog
                open={rootStore.confirmNavigationDialogOpen}
                onClose={this.handleDialogClose}
            >
                <DialogTitle disableTypography><Typography variant="h2">Leave page?</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmNavigationText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.actionButton} onClick={this.handleDialogOk} color="primary" autoFocus>
                        OK
                    </Button>
                    <Button className={classes.actionButton}  onClick={this.handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleDialogClose = () => {
        this.props.rootStore.setConfirmNavigationDialogOpen(false);
    }

    handleDialogOk = () => {
        const { rootStore, history, onDialogOk } = this.props;

        if (onDialogOk) onDialogOk();
        if (rootStore.confirmNavigationLink) {
            history.push(rootStore.confirmNavigationLink);
        } else {
            history.goBack();
        }
        rootStore.setConfirmNavigationDialogOpen(false);
    }
}

ConfirmNavigationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    rootStore: PropTypes.object,
    confirmNavigationText: PropTypes.string.isRequired,
    history: PropTypes.object,
    onDialogOk: PropTypes.func
}

export default compose(
    withStyles(styles),
    withWidth(),
)(ConfirmNavigationDialog);