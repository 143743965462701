import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Grid, Dialog, DialogContent } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import MapGuidelineContent from './map-guideline-content';
import MapGuidelinePagination from './map-guideline-pagination';
import CrossIcon from '../../../../static/images/cancel-icon-large.svg';


const styles = () => ({
    dialogContainer: {
        maxWidth: '1000px',
        height: '773px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px'
    },
    cancelIcon: {
        float: 'right',
        cursor: 'pointer',
        marginRight: '30px',
        marginTop: '38px'
    },
    paddingUnset: {
        padding: 'unset'
    }
})

@inject('modelingMapStore')
@observer
class MapGuidelinesDialog extends Component {
    @observable activeStep = 0;

    @action setActiveStep = (activeStep) => {
        this.activeStep = activeStep;
    }

    handleChangeActiveStep = activeStep => {
        this.setActiveStep(activeStep);
    };

    handleNext = () => {
        let currentActiveStep = this.activeStep;
        this.setActiveStep(currentActiveStep + 1);
    };

    handleBack = () => {
        let currentActiveStep = this.activeStep;
        this.setActiveStep(currentActiveStep - 1);
    };

    closeMapGuidelineDialog = () => {
        this.setActiveStep(0);
        this.props.modelingMapStore.setShowMapGuidelinesDialog(false);
    }

    render() {
        const { classes, modelingMapStore: { showMapGuidelinesDialog } } = this.props;

        return (
            <Dialog open={showMapGuidelinesDialog} fullWidth={true} maxWidth={'lg'} classes={{ paper: classes.dialogContainer }}>
                <Grid container>
                    <Grid item md={12}>
                        <Grid>
                            <img src={CrossIcon} alt="Cross Icon" className={classes.cancelIcon} onClick={this.closeMapGuidelineDialog} />
                        </Grid>
                        <DialogContent className={classes.paddingUnset}>
                            <MapGuidelineContent
                                activeStep={this.activeStep}
                                handleChangeActiveStep={this.handleChangeActiveStep} />
                            <MapGuidelinePagination
                                activeStep={this.activeStep}
                                handleChangeActiveStep={this.handleChangeActiveStep}
                                handleBack={this.handleBack}
                                handleNext={this.handleNext}
                            />
                        </DialogContent>
                    </Grid>
                </Grid>
            </Dialog>

        );
    }
}

MapGuidelinesDialog.propTypes = {
    classes: PropTypes.object,
    modelingMapStore: PropTypes.object,
};

export default compose(
    withStyles(styles)
)(MapGuidelinesDialog);
