import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SwipeableViews from 'react-swipeable-views';
import { Grid, IconButton } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import RedivisionStageDetails from './redivision-stage-details';
import { Enums } from '../../../enums';
import ActiveStage1Logo from '../../../../static/images/stage1_active.svg';
import ActiveStage2Logo from '../../../../static/images/stage2_active.svg';
import ActiveStage3Logo from '../../../../static/images/stage3_active.svg';
import InActiveStage1Logo from '../../../../static/images/stage1_inactive.svg';
import InActiveStage2Logo from '../../../../static/images/stage2_inactive.svg';
import InActiveStage3Logo from '../../../../static/images/stage3_inactive.svg';

const styles = theme => ({
    container: {
        margin: '0 15px',
        display: 'block'
    },
    pagination: {
        justifyContent: 'center'
    },
    stageBtn: {
        height: 18,
        width: 18,
        cursor: 'pointer',
        border: 0,
        background: 'none',
        padding: 0
    },
    paginationDot: {
        backgroundColor: theme.palette.secondary.light,
        height: 12,
        width: 12,
        borderRadius: 6,
        margin: 3
    },
    currentPaginationDot: {
        backgroundColor: theme.palette.secondary.main,
        height: 12,
        width: 12,
        borderRadius: 6,
        margin: 3
    }
})

@inject('redivisionStore')
@observer
class RedivisionStagesSwipe extends Component {
    state = {
        index: 0
    };

    handleChangeIndex = index => {
        this.setState({ index });
    };

    render() {
        const { index } = this.state;
        const { classes, redivisionStore } = this.props;
        const { activeRedivision, isStageOneActive, isStageTwoActive, isStageOneClosed, getMilestoneDate, isStageTwoClosed } = redivisionStore;

        const initialSubOpenTime = getMilestoneDate(Enums.MilestoneType.InitialSubmissionOpen);
        const initialSubCloseTime = getMilestoneDate(Enums.MilestoneType.InitialSubmissionClose);
        const respSubOpenTime = getMilestoneDate(Enums.MilestoneType.ProposedBoundariesPublished);
        const respSubCloseTime = getMilestoneDate(Enums.MilestoneType.SuggestionsAndObjectsionsClosed);
        const finalisedBoundariesTime = getMilestoneDate(Enums.MilestoneType.FinalReportPublished);

        const stagePaginationButtons = [];

        for (let i = 0; i < 3; i += 1) {
            stagePaginationButtons.push(
                <IconButton key={i} index={i} className={classes.stageBtn} onClick={() => this.handleChangeIndex(i)}>
                    <Grid className={i === index ? classes.currentPaginationDot : classes.paginationDot} />
                </IconButton>

            );
        }

        return (
            <>
                {activeRedivision &&
                    <>
                        <SwipeableViews
                            index={index}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            <Grid item xs={12} className={classes.container}>
                                <RedivisionStageDetails
                                    title='Stage One submissions'
                                    subTitle={isStageOneActive ?
                                        <>Open now <br /> Close {initialSubCloseTime.format('h a')} on {initialSubCloseTime.format('D MMMM YYYY')}</> :
                                        (isStageOneClosed ? <>Closed</> :
                                            <>Open {initialSubOpenTime.format('D MMMM YYYY')} <br /> Close {initialSubCloseTime.format('h a')} on {initialSubCloseTime.format('D MMMM YYYY')}</>)}
                                    imageLogo={isStageOneActive ? ActiveStage1Logo : InActiveStage1Logo}
                                    content={<>At the start of a redivision, the EBC invites submissions about the current State electoral boundaries.</>} />
                            </Grid>
                            <Grid item xs={12} className={classes.container}>
                                <RedivisionStageDetails
                                    title='Stage Two submissions'
                                    subTitle={isStageTwoActive ?
                                        <>Open now <br /> Close {respSubCloseTime.format('h a')} on {respSubCloseTime.format('D MMMM YYYY')}</> :
                                        (isStageTwoClosed ? <>Closed</> :
                                            <>Open {respSubOpenTime.format('D MMMM YYYY')} <br /> Close {respSubCloseTime.format('h a')} on {respSubCloseTime.format('D MMMM YYYY')}</>)}
                                    imageLogo={isStageTwoActive ? ActiveStage2Logo : InActiveStage2Logo}
                                    content={<>The EBC publishes a report with its proposed changes to the State’s electoral boundaries. You can submit suggestions and objections about the proposed boundaries.</>} />
                            </Grid>
                            <Grid item xs={12} className={classes.container}>
                                <RedivisionStageDetails
                                    title='Final boundaries'
                                    subTitle={<>Available {finalisedBoundariesTime.format('D MMMM YYYY')}</>}
                                    imageLogo={isStageTwoClosed ? ActiveStage3Logo : InActiveStage3Logo}
                                    content={<>The EBC publishes a report with final changes to State electoral boundaries. </>} />
                            </Grid>
                        </SwipeableViews>
                        <Grid container className={classes.pagination}>{stagePaginationButtons}</Grid>
                    </>}
            </>
        );
    }
}

RedivisionStagesSwipe.propTypes = {
    classes: PropTypes.object,
    redivisionStore: PropTypes.object,
};

export default compose(
    withStyles(styles)
)(RedivisionStagesSwipe);
