import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

var Tools = {

    fixIEScrollIssue: function (elementId) {
        if (document.documentMode) {
            const el = document.getElementById(elementId);
            if (el) {
                const rect = el.getClientRects()[0];
                if (rect) {
                    el.style.height = Math.floor(document.body.clientHeight - rect.top - 20) + 'px';
                }
            }
        }
    },

    MAXINT: Math.pow(2, 31) - 1,

    isDateValidator: function (value) {
        let regIE = /^\d{2}[./]\d{2}[./]\d{4}$/
        if (regIE.test(value) && moment(value, 'DD/MM/YYYY').isValid()) {   // Check for value with format DD/MM/YYYY possibly from IE
            value = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');       // Convert for valid date check
        }
        let reg = /^\d{4}[./-]\d{2}[./-]\d{2}$/
        if (reg.test(value)) {
            return moment(value, 'YYYY-MM-DD').isValid() && moment(value).year() != "0000"
        }
        else {
            return false;
        }
    },

    isEmailValidator: function (value) {
        // eslint-disable-next-line
        let reg = /^([a-zA-Z0-9'_\-\.!#$%&*+=?^`{}|\"~\\,:;<>()\[\]/]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-\[\]]+\.)+))([a-zA-Z\[\]]{2,18}|[0-9\[\]]{1,3})(\]?)$/;
        return reg.test(value);
    },

    getResponseJson: function (response) {
        if (response.status !== 200) {
            return Promise.reject(new Error(response.statusText));
        }

        return response.json();
    },
    getResponseString: function (response) {
        if (response.status !== 200) {
            return Promise.reject(new Error(response.statusText));
        }

        return response.text();
    },
    isMobile: function () {
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
        return isMobile;
    },

    htmlDecode: function(innerHTML){
         return Object.assign(document.createElement('textarea'), {innerHTML}).value;
    },

    isIE: /*@cc_on!@*/false || !!document.documentMode
};


if (!Array.prototype.sortBy) {

    Array.prototype.sortBy = function (names) {
        var result = [];
        this.forEach(function (element) {
            result.push(element);
        });
        if (names && (typeof (names) == 'string' || Array.isArray(names))) {
            if (typeof (names) == 'string') names = [names];
            return result.sort(function (a, b) {
                for (var i = 0; i < names.length; i++) {
                    var name = names[i];
                    if (a[name] == b[name])
                        continue;
                    else if (a[name] > b[name])
                        return 1;
                    else
                        return -1;
                }
                return 0;
            });
        }
        else {
            return result.sort();
        }
    }

    Array.prototype.sortByProperty = function (key, order = 'asc', property) {
        return this.sort(function innerSort(x, y) {
            const a = property ? x[property] : x;
            const b = property ? y[property] : y;
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        });
    }
}

if (!Array.prototype.uniqBy) {

    Array.prototype.uniqBy = function (names) {
        var hashed = [];
        var result = [];
        if (names) {
            this.forEach(function (element) {
                if (hashed.indexOf(element[names]) < 0) {
                    hashed.push(element[names]);
                    result.push(element);
                }
            });
        }
        else {
            this.forEach(function (element) {
                if (hashed.indexOf(element) < 0) {
                    hashed.push(element);
                    result.push(element);
                }
            });
        }
        return result;
    }
}

if (!Array.prototype.last) {

    Array.prototype.last = function () {
        return this.length ? this[this.length - 1] : null
    }
}

if (!Array.prototype.max) {
    Array.prototype.max = function (name) {
        return this.reduce((max, value) => {
            var val = name ? value[name] : value;
            return max > val ? max : val;
        }, 0);
    }
}

if (!Array.prototype.sumInt) {
    Array.prototype.sumInt = function (name) {
        if (name) {
            return this.reduce((tot, value) => {
                return tot + (parseInt(value[name]) || 0);
            }, 0);
        }
        else {
            return this.reduce((tot, value) => {
                return tot + (parseInt(value) || 0);
            }, 0);
        }
    }
}

if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        }
    });
}

if (!RegExp.escape) {
    RegExp.escape = function (s) {
        // eslint-disable-next-line
        return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    };
}

// IE11 SVGElement.contains fix
// Ref: https://github.com/swimlane/ngx-charts/issues/386
if (typeof (SVGElement) !== "undefined" && typeof SVGElement.prototype.contains === 'undefined') {
    SVGElement.prototype.contains = HTMLDivElement.prototype.contains;
}
if (typeof (SVGSVGElement) !== "undefined" && typeof SVGSVGElement.prototype.contains === 'undefined') {
    SVGSVGElement.prototype.contains = HTMLDivElement.prototype.contains;
}
export { Tools }