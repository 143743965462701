import React from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose } from "recompose";
import { Button, IconButton, ClickAwayListener, MenuItem, MenuList, Popper, Paper, Tooltip, Divider } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import IconCursor from '-!svg-react-loader?name=IconCursor!../../../static/images/icon-cursor.svg';
import IconSquare from '-!svg-react-loader?name=IconSquare!../../../static/images/icon-square.svg';
import IconPolygon from '-!svg-react-loader?name=IconPolygon!../../../static/images/icon-polygon.svg';
import { Enums } from '../../enums';
import { action, observable } from 'mobx';

const styles = theme => ({
    bottomToolBar: {        
        height: '60px',
        position: 'relative',
        width: 'fit-content',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '95px',
        order: 3,
        padding: '0px',        
        '& > button': {
            position: 'relative',
            top: '50%',
            transform: 'translateY(-150%)'
        },
        '@media print': {
            display: 'none !important'
        }
    },
    toolButton: {
        height: '37px',
        minHeight: '37px',
        minWidth: '60px',
        marginRight: '5px',
        '&:disabled': {
            background: 'rgba(0,0,0,0.15)',
            border: 'none'
        },
        '@media print': {
            display: 'none !important'
        }
    },
    activeToolIcon: {
        '& > path': {
            fill: theme.palette.secondary.main + ' !important',            
        },
        '& > rect': {
            fill: theme.palette.secondary.main + ' !important',            
        }
    },
    inactiveToolIcon: {
        '& > path': {
            fill: theme.palette.secondary.light + ' !important',
        },
        '& > rect': {
            fill: theme.palette.secondary.light + ' !important',
        }
    },
    disabledIcon: {
        '& > path': {
            stroke: 'rgba(0, 0, 0, 0.25) !important',
        }
    },  
    tooltip: {
        fontSize: '0.875rem',
    },
    popupMenu: {
        '@media print': {
            display: 'none !important'
        }
    }       
});

@inject('modelingMapStore', 'redivisionStore')
@observer
class MapTools extends React.Component {

    constructor(props) {
        super(props);
        this.redivisionStore = props.redivisionStore;
    }

    @observable toolsMenuVisible = false;

    setSelectionMethod = (selectionMethod) => {
        this.props.modelingMapStore.selectionMethod = selectionMethod;
    }

    @action.bound clearSelection() {
        this.hideToolsMenu();
        this.props.modelingMapStore.clearSelectedNuggets();
    }

    toggleToolsMenu = (event) => {
        this.popperElement = event.target;
        this.toolsMenuVisible = !this.toolsMenuVisible;
    }

    hideToolsMenu = () => {
        if (this.toolsMenuVisible == true) {
            this.toolsMenuTimeout = setTimeout(() => {
                this.toolsMenuVisible = false;
            }, 10);
        }
    }

    print = () => {
        this.hideToolsMenu();
        window.print();
    }

    @action.bound undo() {
        this.hideToolsMenu();
        this.props.modelingMapStore.undoLastAction();
    }
    
    @action.bound redo() {
        this.hideToolsMenu();
        this.props.modelingMapStore.redoLastAction();
    }

    @action.bound markUnassignedNuggets() {
        this.hideToolsMenu();
        this.props.modelingMapStore.markUnassignedNuggets();
    }   

    componentWillUnmount() {
        if (this.toolsMenuTimeout) {
            clearTimeout(this.toolsMenuTimeout);
        }
    }

    render() {

        const { classes } = this.props;
        this.mapContainer = document.querySelector('#mapContainer')
        const { selectedNuggets, selectionMethod, unassignedReviewElectors, unassignedNuggetMarkers, lastAction } = this.props.modelingMapStore;

        return <Paper className={classes.bottomToolBar}>

            <Tooltip
                title='Point select'
                placement='bottom'
                classes={{ tooltip: classes.tooltip}}
                PopperProps={{
                    popperOptions: {
                        modifiers: { arrow: { enabled: true, element: 'span' } }
                    }
                }}>
                <Button className={classes.toolButton} onClick={() => { this.setSelectionMethod(Enums.SelectionMethod.Point) }}>
                    <IconCursor className={selectionMethod === Enums.SelectionMethod.Point ? classes.activeToolIcon : classes.inactiveToolIcon} />
                </Button>
            </Tooltip>
            <Tooltip
                title='Rectangle select'
                placement='bottom'
                classes={{ tooltip: classes.tooltip}}
                PopperProps={{
                    popperOptions: {
                        modifiers: { arrow: { enabled: true, element: 'span' } }
                    }
                }}>
                <Button className={classes.toolButton} onClick={() => { this.setSelectionMethod(Enums.SelectionMethod.Rectangle) }}>
                    <IconSquare className={selectionMethod === Enums.SelectionMethod.Rectangle ? classes.activeToolIcon : classes.inactiveToolIcon} />
                </Button>
            </Tooltip>
            <Tooltip
                title='Custom polygon select'                                    
                placement='bottom'
                classes={{ tooltip: classes.tooltip}}
                PopperProps={{
                    popperOptions: {
                        modifiers: { arrow: { enabled: true, element: 'span' } }
                    }
                }}>
                <Button className={classes.toolButton} onClick={() => { this.setSelectionMethod(Enums.SelectionMethod.Polygon) }}>
                    <IconPolygon className={selectionMethod === Enums.SelectionMethod.Polygon ? classes.activeToolIcon : classes.inactiveToolIcon} />
                </Button>
            </Tooltip>
            <Divider component='div' orientation='vertical' style={{display:'inline-block'}}/>
            <IconButton className={classNames(classes.toolButton)} height={24} width={24} onClick={this.toggleToolsMenu}>
                <MoreVert className={classes.inactiveToolIcon}/>
            </IconButton>
            <Popper anchorEl={this.popperElement} placement={'bottom'} open={this.toolsMenuVisible}>
                <ClickAwayListener onClickAway={this.hideToolsMenu}>
                    <Paper className={classes.popupMenu}>
                        <MenuList>
                            <MenuItem disabled={!selectedNuggets.length} onClick={this.clearSelection}>Deselect all</MenuItem>
                            <MenuItem onClick={this.redo} disabled={!lastAction || !lastAction.undone}>Redo</MenuItem>
                            <MenuItem onClick={this.undo} disabled={!lastAction || lastAction.undone}>Undo</MenuItem>
                            <MenuItem onClick={this.markUnassignedNuggets} disabled={unassignedReviewElectors === 0}>{unassignedNuggetMarkers.length === 0? 'Show unassigned': 'Hide pins'}</MenuItem>
                            <MenuItem onClick={this.print}>Print map</MenuItem>
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </Paper>
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        modelingMapStore: PropTypes.object,
        redivisionStore: PropTypes.object,
    }
}

export default compose(
    withStyles(styles),
)(MapTools);