import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '../../../../static/images/delete-icon.svg';

const styles = theme => ({
    attachmentsHeader: {
        marginTop: '40px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    attachmentsDetailText: {
        paddingBottom: '12px'
    },
    attachmentButton: {
        textTransform: 'none',
        width: '137px',
        height: '40px',
        marginBottom: '20px',
        borderRadius: '3px',
        minWidth: '180px',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        flex: 'none',
        order: 0,
        alignSelf: 'center',

        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset !important',
            width: 'unset !important',
            marginBottom: '15px'
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 'unset',
            width: '100% !important',
            marginBottom: '15px'
        },
    },
    attachmentList: {
        backgroundColor: theme.palette.gray.gray400,
        margin: '20px 0',
        borderRadius: '4px'
    },
    deleteIcon: {
        marginLeft: '10px',
        cursor: 'pointer'
    },
    attachmentsHelperText: {
        color: theme.palette.error.main,
        marginTop: '5px'
    },
    fileName: {
        wordBreak: 'break-all',
        paddingRight: '68px',
        fontWeight: 600,
        paddingLeft: '0 !important'
    },
    gridMargin: {
        marginTop: '40px'
    }
});

const acceptedExtensions = ['doc', 'docx', 'pdf', 'txt', 'jpg', 'jpeg', 'png'];

@inject('submissionStore')
@observer
class SubmissionAttachments extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = null;
    }

    render() {
        const { classes, submissionStore } = this.props;

        return (
            <>
                <Typography variant="h5" className={classes.attachmentsHeader}>
                    Attachments (optional)
                </Typography>
                {
                    submissionStore.submissionFiles.length == 0 &&
                    <Typography variant="subtitle2">
                        Please attach any documents to support your submission.<br />
                    Accepted file types: {acceptedExtensions.map((e) => '.' + e).join(', ')}<br />
                    Accepted file sizes: 10MB per file (total upload limit: 20MB)
                </Typography>
                }
                {
                    submissionStore.submissionFiles.length > 0 &&
                    <List className={classes.attachmentList}>
                        {
                            submissionStore.submissionFiles.map((file, index) => {
                                return (
                                    <ListItem key={index}>
                                        <Grid item xs={11}>
                                            <ListItemText>
                                                <Grid container>
                                                    <Typography variant="subtitle2" className={classes.fileName}>{file.name}</Typography>                                                   
                                                </Grid>
                                            </ListItemText>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <ListItemSecondaryAction>
                                                <img src={DeleteIcon} alt="Delete" className={classes.deleteIcon} onClick={this.handleRemoveFile(file)} />
                                            </ListItemSecondaryAction>
                                        </Grid>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                }
                <Grid className={classes.gridMargin}>
                    <input
                        hidden
                        id="flat-button-file"
                        type="file"
                        accept={acceptedExtensions.map((e) => '.' + e).join(', ')}
                        name="addFile"
                        ref={this.setFileInputRef}
                        onChange={this.handleAddFileChange}
                    />
                    <label htmlFor="flat-button-file">
                        <Button
                            className={classes.attachmentButton}
                            component="span"
                            color="secondary"
                            variant="contained">
                            Attach file
                            </Button>
                    </label>
                </Grid>
                {
                    submissionStore.attachmentsHelperText.length > 0 &&
                    <Typography variant="body1" className={classes.attachmentsHelperText}>
                        {submissionStore.attachmentsHelperText}
                    </Typography>
                }
            </>
        );
    }

    setFileInputRef = element => {
        this.fileInputRef = element;
    }

    handleAddFileChange = () => {
        const { submissionStore } = this.props;
        const files = this.fileInputRef.files;

        if (files && files.length > 0) {
            const file = files[0];
            const fileExt = file.name.split('.').pop().toLowerCase();
            const totalAttachmentsSize = submissionStore.totalAttachmentsSize + file.size;

            if (acceptedExtensions.indexOf(fileExt) < 0) {
                submissionStore.setAttachmentsHelperText('Unsupported file type');
            }
            else if (file.size > 10485760) {  // 10MB limit
                submissionStore.setAttachmentsHelperText('Files must not exceed 10 MB');
            }
            else if (totalAttachmentsSize > 20971520) {  // 20MB limit
                submissionStore.setAttachmentsHelperText('Total upload must not exceed 20 MB');
            }
            else {
                submissionStore.setAttachmentsHelperText('');
                submissionStore.addSubmissionFile(file);
            }

            // clear the file input
            this.fileInputRef.value = '';
        }
    }

    handleRemoveFile = (file) => () => {
        this.props.submissionStore.removeSubmissionFile(file);
    }
}

SubmissionAttachments.propTypes = {
    classes: PropTypes.object.isRequired,
    submissionStore: PropTypes.object
}

export default withStyles(styles)(SubmissionAttachments);
