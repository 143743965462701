import React from 'react'
import PropTypes from 'prop-types';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import IconPlus from '-!svg-react-loader?name=IconPlus!../../../static/images/icon-plus-2.svg';
import IconArrowUp from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-arrow-up.svg';
import IconArrowDown from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-arrow-down.svg';
import { inject, observer } from 'mobx-react';
import StyledPrimaryContainedButton from '../styled-primary-contained-button';
import StyledPrimaryOutlinedButton from '../styled-primary-outlined-button';
import { observable, computed } from 'mobx';
import SegmentList from './segment-list';
import UnassignedInfoRow from './unassigned-info-row';

const styles = theme => ({
    segmentMenu: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '@media print': {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            pageBreakBefore: 'always'
        }
    },
    titlePane: {
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    },
    segmentList: {
        color: theme.palette.primary.contrastText,
        fontSize: '16pt',
        fontWeight: 'bold',
        lineHeight: 1.21,
        overflowX: 'hidden',
        overflowY: 'auto',
        '@media print': {
            overflowX: 'visible',
            overflowY: 'visible'
        }
    },
    finishMapContainer: {
        background: theme.palette.primary.main,
        minHeight: '110px',
        textAlign: 'right',
        alignItems: 'center',
        padding: '25px',
        '@media print': {
            display: 'none'
        }
    },
    segmentTableCell: {
        padding: '0px',
        verticalAlign: 'middle'
    },
    segmentName: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100px',
        paddingTop: '8px',
        textTransform: 'capitalize'
    },
    numberTableCell: {
        textAlign: 'left',
        paddingTop: '2px'
    },
    submitButton: {
        width: '165px',
        minWidth: '165px',
        height: '50px',
        background: theme.palette.white,
        color: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.gray.gray600
        }
    },
    noWrap: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap !important'
    },
    titleText: {
        color: theme.palette.white,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.4px'
    },
    titleContainer: {
        height: '40px',
        marginTop: '10px',
        marginLeft: '25px'
    },
    searchContainer: {
        height: '31px',
        marginLeft: '25px',
        display: 'inline-flex'
    },
    searchDistrictsText: {
        color: 'rgba(255, 255, 255, 0.5)'
    },
    headerContainer: {
        background: theme.palette.primary.dark,
        height: '39px',
        [theme.breakpoints.up('sm')]: {
            width: '99%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '95%'
        }
    },
    segmentTableHeadingText: {
        fontWeight: '600',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        color: theme.palette.white
    },
    districtsHeader: {
        width: '55%',
        maxWidth: '200px'
    },
    votersHeader: {
        width: '21%'
    },
    deviationHeader: {
        width: '24%'
    },
    districtsHeaderText: {
        marginLeft: '25px'
    },
    newDistrictContainer: {
        background: '#E7EBF0',
        minHeight: '80px',
        alignItems: 'center',
        padding: '25px'
    },
    numberDistrictsText: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px'
    },
    addNewSegmentText: {
        fontWeight: 'bold'
    },
    saveButton: {
        width: '165px',
        minWidth: '165px',
        heigth: '50px',
        borderColor: theme.palette.white,
        color: theme.palette.white,
        background: theme.palette.primary.main,
        marginBottom: '0px'
    },
    saveContainer: {
        textAlign: 'left'
    },
    submitContainer: {
        textAlign: 'right'
    },
    numberDistrictsContainer: {
        display: 'inline-flex'
    },
    addNewSegmentContainer: {
        textAlign: 'right'
    },
    districtsText: {
        marginLeft: '5px',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
    },
    iconPlus: {
        marginLeft: '15px'
    },
    segmentTableHeadingContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    sortingButton: {
        minWidth: '10px',
        height: '10px',
        '@media print': {
            display: 'none !important'
        }
    },
    addNewDistrictButton: {
        '&:disabled': {
            opacity: 0.3
        }
    },
    inViewHeaderText: {
        fontWeight: 'bold', 
        marginLeft: '25px', 
        marginTop: '24px'
    },
    allHeaderText: {
        fontWeight: 'bold', 
        marginLeft: '25px', 
        marginTop: '24px'
    }
});

@inject('msgBoxStore')
@observer
class SegmentInfoPanel extends React.Component {

    constructor(props) {
        super();
        this.modelingStore = props.modelingStore;
        this.scrollElementRef = React.createRef();
        this.scrollElementToAllRef = React.createRef();
    }

    @observable editSegmentName = false;
    @observable colourPickerActive = false;
    @observable colourPickerAnchor = null;
    @observable showDeviationInfo = false;
    @observable sortingState = {};
    @observable sortOrder = 'asc';
    @observable nameColumnSort = 'name';

    @computed get segmentsSorted() {
        const { segments } = this.modelingStore;
        let segmentsSort = [...segments].sortByProperty(this.nameColumnSort, this.sortOrder, "properties");
        const newDistricts = segmentsSort.filter(seg => seg.properties.name === "New District");

        // New districts should be positioned on top
        if(newDistricts.length > 0) {

            for(const seg of newDistricts){
                const indexNewDistrict = segmentsSort.findIndex(s => s.properties.id === seg.properties.id);

                if(indexNewDistrict > 0) {
                    const newDistrict = {...segmentsSort[indexNewDistrict]};
                    segmentsSort.splice(indexNewDistrict, 1);
                    segmentsSort.splice(0, 0, newDistrict);
                }
            }
        }

        return segmentsSort;
    }

    @computed get segmentsInViewSorted() {
        const { segmentsInView } = this.modelingStore;
        return [...segmentsInView].sortByProperty(this.nameColumnSort, this.sortOrder, "properties");
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        modelingStore: PropTypes.object,
        finishBtnFn: PropTypes.func,
        saveBtnFn: PropTypes.func
    }

    render() {
        const { classes } = this.props;
        const { unassignedSegmentMembers, totalAssignedMembers } = this.modelingStore;

        return (
            <div className={classes.segmentMenu}>
                <Grid className={classes.titlePane}>
                    <Grid className={classes.titleContainer}>
                        <Typography variant={'h3'} className={classes.titleText}>MY DISTRICTS</Typography>
                    </Grid>
                    <Grid container className={classes.headerContainer}>
                        <Grid className={[classes.segmentTableCell, classes.districtsHeader].join(' ')}>
                            <Grid className={classes.segmentTableHeadingContainer}>
                                <Typography variant={"body1"} className={[classes.segmentTableHeadingText, classes.districtsHeaderText].join(' ')}>
                                    Districts
                                </Typography>
                                <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(1, 'name')} id='btnSortDistrict' aria-label='Sort by District'>
                                    {this.sortingState['columnSort1'] || typeof this.sortingState['columnSort1'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid className={[classes.segmentTableCell, classes.numberTableCell, classes.votersHeader].join(' ')}>
                            <Grid className={classes.segmentTableHeadingContainer}>
                                <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                    Voters
                                </Typography>
                                <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(2, 'electors')} id='btnSortElectors' aria-label='Sort by Electors'>
                                    {this.sortingState['columnSort2'] || typeof this.sortingState['columnSort2'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid className={[classes.segmentTableCell, classes.numberTableCell, classes.deviationHeader].join(' ')}>
                            <Grid className={classes.segmentTableHeadingContainer}>
                                <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                    Deviation
                                </Typography>
                                <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(3, 'deviation')} id='btnSortDeviation' aria-label='Sort by Deviation'>
                                    {this.sortingState['columnSort3'] || typeof this.sortingState['columnSort3'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.segmentList}>
                    <Grid item xs={12} ref={this.scrollElementRef}>
                        <UnassignedInfoRow />
                        {this.segmentsInViewSorted.length > 0 &&
                            <Typography color={"secondary"} variant={"body1"} className={classes.inViewHeaderText} >
                                CURRENTLY IN VIEW
                            </Typography>
                        }
                        <SegmentList segments={this.segmentsInViewSorted} keyPrefix={'inviewSegment'} idProperty={"id"} allowEditing={true} allowSelection={true} />
                        <Grid ref={this.scrollElementToAllRef}>
                            {this.segmentsInViewSorted.length > 0 &&
                                <Typography color={"secondary"} variant={"body1"} className={classes.allHeaderText} >
                                    ALL
                            </Typography>
                            }
                            <SegmentList segments={this.segmentsSorted} keyPrefix={'segment'} idProperty={"id"} allowEditing={true} allowSelection={true} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.newDistrictContainer}>
                    <Grid item xs={6} className={classes.numberDistrictsContainer}>
                        <Typography color='secondary' variant='h4' className={classes.numberDistrictsText}>
                            {`${totalAssignedMembers}/88`}
                        </Typography>
                        <Typography color='secondary' variant='h5' className={classes.districtsText}>
                            Districts
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.addNewSegmentContainer}>
                        <Button className={classes.addNewDistrictButton} disabled={unassignedSegmentMembers === 0} onClick={() => { this.addSegment() }}>
                            <Typography color='secondary' variant="subtitle2" className={classes.addNewSegmentText}>
                                Add New
                            </Typography>
                            <IconPlus className={classes.iconPlus} />
                        </Button>
                    </Grid>
                </Grid>
                <Grid container className={classes.finishMapContainer}>
                    <Grid item xs={6} className={classes.saveContainer}>
                        <StyledPrimaryOutlinedButton className={classes.saveButton} onClick={() => { this.saveBtnClicked() }}>
                            Save
                        </StyledPrimaryOutlinedButton>
                    </Grid>
                    <Grid item xs={6} className={classes.submitContainer}>
                        <StyledPrimaryContainedButton className={classes.submitButton} onClick={() => { this.finishBtnClicked() }}>
                            Submit
                        </StyledPrimaryContainedButton>
                    </Grid>
                </Grid>
            </div>
        )
    }

    addSegment = () => {
        (this.segmentsInViewSorted.length > 0) ?
            this.scrollElementToAllRef.current.scrollIntoView(true) :
            this.scrollElementRef.current.scrollIntoView(true);
        this.modelingStore.addSegment('New District');
        this.modelingStore.setIsMapDirty(true);
    }

    finishBtnClicked() {
        if (this.props.finishBtnFn) {
            this.props.finishBtnFn();
        }
    }

    saveBtnClicked() {
        if (this.props.saveBtnFn) {
            this.props.saveBtnFn();
        }
    }

    handleTableSorting = (columnSort, nameColumnSort) => {
        let sortingValue = this.sortingState[`columnSort${columnSort}`];
        sortingValue = typeof sortingValue === 'undefined' ? false : !sortingValue;
        Object.assign(this.sortingState, { [`columnSort${columnSort}`]: sortingValue });
        this.nameColumnSort = nameColumnSort;
        this.sortOrder = sortingValue ? 'asc' : 'desc';

        Object.keys(this.sortingState).forEach(function (key) {
            if (key.startsWith('columnSort') && key !== `columnSort${columnSort}`) {
                Object.assign(this.sortingState, { [key]: true });
            }
        }.bind(this));
    }
}

SegmentInfoPanel.propTypes = {
    msgBoxStore: PropTypes.object
}

export default compose(
    withStyles(styles)
)(SegmentInfoPanel)
