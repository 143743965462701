import { observable, action, runInAction } from 'mobx';
import { Tools } from '../tools';

import profileService from '../services/profile.service';

export default class ProfileStore {
    constructor(sessionStore) {
        this.sessionStore = sessionStore;
        this.routerStore = sessionStore.routerStore;
        profileService.setAuthStore(this.sessionStore.authStore);
    }

    @observable userProfile = {
        isOrganisation: false,
        organisationName: '',
        firstName: '',
        lastName: '',
        isAuthorisedBehalfOfOrg: false,
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        postcode: '',
        state: '',
        emailAddress: '',
        reEnterEmailAddress: '',
        preferredContactMethod: '1',
        phoneNumber: '',
        password: '',
        agreeToVecPrivacyPolicy: false
    };

    @observable isExistingUser = false;
    @observable getProfileError = null;
    @observable profileId = null;
    @observable isOrganisation = false;
    @observable organisationName = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable isAuthorisedBehalfOfOrg = false;
    @observable addressLine1 = '';
    @observable addressLine2 = '';
    @observable suburb = '';
    @observable postcode = '';
    @observable state = '';
    @observable emailAddress = '';
    @observable reEnterEmailAddress = '';
    @observable preferredContactMethod = '1';
    @observable phoneNumber = '';
    @observable password = '';
    @observable agreeToVecPrivacyPolicy = false;

    @observable organisationNameError = false;
    @observable organisationNameHelperText = '';
    @observable firstNameError = false;
    @observable firstNameHelperText = '';
    @observable lastNameError = false;
    @observable lastNameHelperText = '';
    @observable isAuthorisedBehalfOfOrgError = false;
    @observable isAuthorisedBehalfOfOrgHelperText = '';
    @observable addressLine1Error = false;
    @observable addressLine1HelperText = '';
    @observable suburbError = false;
    @observable suburbHelperText = '';
    @observable postcodeError = false;
    @observable postcodeHelperText = '';
    @observable stateError = false;
    @observable stateHelperText = '';
    @observable emailAddressError = false;
    @observable emailAddressHelperText = '';
    @observable reEnterEmailAddressError = false;
    @observable reEnterEmailAddressHelperText = '';
    @observable phoneNumberError = false;
    @observable phoneNumberHelperText = '';
    @observable passwordError = false;
    @observable passwordHelperText = '';
    @observable agreeToVecPrivacyPolicyError = false;
    @observable agreeToVecPrivacyPolicyHelperText = '';

    @observable isContactDetailsFormDirty = false;
    @observable isContactDetailsFormValid = false;

    @observable createProfileError = null;

    @action
    setIsOrganisation(isOrg) {
        this.isOrganisation = isOrg;
    }

    @action
    setOrganisationName(orgName) {
        this.organisationName = orgName;
    }

    @action
    setFirstName(firstName) {
        this.firstName = firstName;
    }

    @action
    setLastName(lastName) {
        this.lastName = lastName;
    }

    @action
    setIsAuthorisedBehalfOfOrg(isAuthorized) {
        this.isAuthorisedBehalfOfOrg = isAuthorized;
    }

    @action
    setAddressLine1(address) {
        this.addressLine1 = address;
    }

    @action
    setAddressLine2(address) {
        this.addressLine2 = address;
    }

    @action
    setSuburb(suburb) {
        this.suburb = suburb;
    }

    @action
    setPostcode(postcode) {
        this.postcode = postcode;
    }

    @action
    setState(state) {
        this.state = state;
    }

    @action
    setEmailAddress(email) {
        this.emailAddress = email;
    }

    @action
    setReEnterEmailAddress(email) {
        this.reEnterEmailAddress = email;
    }

    @action
    setPreferredContactMethod(contactMethod) {
        this.preferredContactMethod = contactMethod;
    }

    @action
    setPhoneNumber(phoneNumber) {
        this.phoneNumber = phoneNumber;
    }

    @action
    setPassword(password) {
        this.password = password;
    }

    @action
    setAgreeToVecPrivacyPolicy(agree) {
        this.agreeToVecPrivacyPolicy = agree;
    }

    @action
    setProfileId(profileId) {
        this.profileId = profileId;
    }

    @action
    validateOrganisationName() {
        if (!this.organisationName) {
            this.organisationNameError = true;
            this.organisationNameHelperText = 'Please provide organisation name.';
        }
        else {
            this.organisationNameError = false;
            this.organisationNameHelperText = '';
        }
    }

    @action
    validateFirstName() {
        if (!this.firstName) {
            this.firstNameError = true;
            this.firstNameHelperText = 'Please provide surname and/or given names.';
        }
        else {
            this.firstNameError = false;
            this.firstNameHelperText = '';
        }
    }

    @action
    validateLastName() {
        if (!this.lastName) {
            this.lastNameError = true;
            this.lastNameHelperText = 'Please provide surname and/or given names.';
        }
        else {
            this.lastNameError = false;
            this.lastNameHelperText = '';
        }
    }

    @action
    validateLastIsAuthorisedBehalfOfOrg() {
        if (!this.isAuthorisedBehalfOfOrg) {
            this.isAuthorisedBehalfOfOrgError = true;
            this.isAuthorisedBehalfOfOrgHelperText = 'Please confirm you are authorised.';
        }
        else {
            this.isAuthorisedBehalfOfOrgError = false;
            this.isAuthorisedBehalfOfOrgHelperText = '';
        }
    }

    @action
    validateAddressLine1() {
        if (!this.addressLine1) {
            this.addressLine1Error = true;
            this.addressLine1HelperText = 'Please provide your address.';
        }
        else {
            this.addressLine1Error = false;
            this.addressLine1HelperText = '';
        }
    }

    @action
    validateSuburb() {
        if (!this.suburb) {
            this.suburbError = true;
            this.suburbHelperText = 'Please provide your suburb.';
        }
        else {
            this.suburbError = false;
            this.suburbHelperText = '';
        }
    }

    @action
    validatePostcode() {
        if (!this.postcode) {
            this.postcodeError = true;
            this.postcodeHelperText = 'Please provide your postcode.';
        }
        else if (this.postcode.length !== 4) {
            this.postcodeError = true;
            this.postcodeHelperText = 'Postcode must be 4 digits.';
        }
        else {
            this.postcodeError = false;
            this.postcodeHelperText = '';
        }
    }

    @action
    validateState() {
        if (!this.state) {
            this.stateError = true;
            this.stateHelperText = 'Please select your state or territory.';
        }
        else {
            this.stateError = false;
            this.stateHelperText = '';
        }
    }

    @action
    validateEmailAddress() {
        if (!this.emailAddress) {
            this.emailAddressError = true;
            this.emailAddressHelperText = 'Please provide your email.';
        }
        else if (!Tools.isEmailValidator(this.emailAddress)) {
            this.emailAddressError = true;
            this.emailAddressHelperText = 'Please provide a valid email address.';
        }
        else {
            this.emailAddressError = false;
            this.emailAddressHelperText = '';
        }
    }

    @action
    validateReEnterEmailAddress() {
        if (this.reEnterEmailAddress !== this.emailAddress) {
            this.reEnterEmailAddressError = true;
            this.reEnterEmailAddressHelperText = 'Email address does not match.';
        }
        else {
            this.reEnterEmailAddressError = false;
            this.reEnterEmailAddressHelperText = '';
        }
    }

    @action
    validatePhoneNumber() {
        if (!this.phoneNumber) {
            this.phoneNumberError = true;
            this.phoneNumberHelperText = 'Please provide a contact telephone number.';
        }
        else if (this.phoneNumber.length < 10) {
            this.phoneNumberError = true;
            this.phoneNumberHelperText = 'Phone number must be 10 digits.';
        }
        else {
            this.phoneNumberError = false;
            this.phoneNumberHelperText = '';
        }
    }

    @action
    validatePassword() {
        if (!this.password) {
            this.passwordError = true;
            this.passwordHelperText = 'Please provide a password.';
        }
        else {
            this.passwordError = false;
            this.passwordHelperText = '';
        }
    }

    @action
    validateAgreeToVecPrivacyPolicy() {
        if (!this.agreeToVecPrivacyPolicy) {
            this.agreeToVecPrivacyPolicyError = true;
            this.agreeToVecPrivacyPolicyHelperText = 'You must agree to EBC\'s privacy policy.';
        }
        else {
            this.agreeToVecPrivacyPolicyError = false;
            this.agreeToVecPrivacyPolicyHelperText = '';
        }
    }

    @action
    checkFormValidity() {
        if (this.isOrganisation) {
            this.validateOrganisationName();
            this.validateLastIsAuthorisedBehalfOfOrg();
        }
        else {
            this.clearOrganisationValidationErrors();
        }

        this.validateFirstName();
        this.validateLastName();
        this.validateAddressLine1();
        this.validateSuburb();
        this.validatePostcode();
        this.validateState();
        this.validateEmailAddress();
        this.validatePhoneNumber();
        this.validateAgreeToVecPrivacyPolicy();

        this.isContactDetailsFormValid = !this.organisationNameError && !this.firstNameError &&
            !this.lastNameError && !this.isAuthorisedBehalfOfOrgError && !this.addressLine1Error &&
            !this.suburbError && !this.postcodeError && !this.stateError &&
            !this.emailAddressError && !this.phoneNumberError &&
            !this.agreeToVecPrivacyPolicyError;
    }

    clearValidation() {
        this.organisationNameError = false;
        this.organisationNameHelperText = '';
        this.firstNameError = false;
        this.firstNameHelperText = '';
        this.lastNameError = false;
        this.lastNameHelperText = '';
        this.isAuthorisedBehalfOfOrgError = false;
        this.isAuthorisedBehalfOfOrgHelperText = '';
        this.addressLine1Error = false;
        this.addressLine1HelperText = '';
        this.suburbError = false;
        this.suburbHelperText = '';
        this.postcodeError = false;
        this.postcodeHelperText = '';
        this.stateError = false;
        this.stateHelperText = '';
        this.emailAddressError = false;
        this.emailAddressHelperText = '';
        this.reEnterEmailAddressError = false;
        this.reEnterEmailAddressHelperText = '';
        this.phoneNumberError = false;
        this.phoneNumberHelperText = '';
        this.agreeToVecPrivacyPolicyError = false;
        this.agreeToVecPrivacyPolicyHelperText = '';
    }


    clearOrganisationValidationErrors() {
        this.organisationNameError = false;
        this.organisationNameHelperText = '';
        this.isAuthorisedBehalfOfOrgError = false;
        this.isAuthorisedBehalfOfOrgHelperText = '';
    }

    @action
    setIsContactDetailsFormDirty(dirty) {
        this.isContactDetailsFormDirty = dirty;
        this.sessionStore.rootStore.setDirty(dirty);
    }

    @action
    saveProfile() {
        const { rootStore } = this.sessionStore;

        const profile = {
            profileId: this.profileId,
            isOrganisation: this.isOrganisation,
            organisationName: this.organisationName,
            firstName: this.firstName,
            lastName: this.lastName,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            suburb: this.suburb,
            postcode: this.postcode,
            state: this.state,
            emailAddress: this.emailAddress,
            phoneNumber: this.phoneNumber,
            preferredContactMethod: this.preferredContactMethod
        };

        rootStore.setBusy(true);

        profileService.saveProfile(profile).then((response) => {
            rootStore.setBusy(false);
            runInAction(() => {
                this.userProfile = response;
                this.isExistingUser = true;
                this.setIsContactDetailsFormDirty(false);
                this.routerStore.history.push('/');
            });
        }).catch((error) => {
            rootStore.setBusy(false);
            this.createProfileError = error;
        });
    }

    @action
    resetContactDetailsForm() {
        this.isOrganisation = false;
        this.organisationName = '';
        this.firstName = '';
        this.lastName = '';
        this.isAuthorisedBehalfOfOrg = false;
        this.addressLine1 = '';
        this.addressLine2 = '';
        this.suburb = '';
        this.postcode = '';
        this.state = '';
        this.emailAddress = '';
        this.reEnterEmailAddress = '';
        this.preferredContactMethod = '1';
        this.phoneNumber = '';
        this.password = '';
        this.agreeToVecPrivacyPolicy = false;
        this.setIsContactDetailsFormDirty(false);
        this.clearValidation();
    }

    @action
    loadContactDetailsFormFromUserProfile() {
        if (this.userProfile) {
            this.isOrganisation = this.userProfile.isOrganisation;
            this.organisationName = this.userProfile.organisationName;
            this.firstName = this.userProfile.firstName;
            this.lastName = this.userProfile.lastName;
            this.isAuthorisedBehalfOfOrg = this.userProfile.isOrganisation ? true : false;
            this.addressLine1 = this.userProfile.addressLine1;
            this.addressLine2 = this.userProfile.addressLine2;
            this.suburb = this.userProfile.suburb;
            this.postcode = this.userProfile.postcode.toString();
            this.state = this.userProfile.state;
            this.emailAddress = this.userProfile.emailAddress;
            this.reEnterEmailAddress = this.userProfile.reEnterEmailAddress;
            this.preferredContactMethod = this.userProfile.preferredContactMethod.toString();
            this.phoneNumber = this.userProfile.phoneNumber;
            this.agreeToVecPrivacyPolicy = true; //setting default to true for Edit profile becauase a profile can be created only when this property is set to true.           
        }
    }

    @action
    getProfile(id) {
        this.sessionStore.rootStore.setBusy(true);
        profileService.getProfile(id)
            .then((response) => {
                runInAction(() => {
                    this.userProfile = response;
                    this.isExistingUser = true;
                    this.sessionStore.rootStore.setBusy(false);
                });
            }).catch((error) => {
                this.getProfileError = error;
                this.sessionStore.rootStore.setBusy(false);
            });
    }
}