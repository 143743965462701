import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import ContactDetailsContent from './contact-details-content';
import ConfirmNavigationDialog from '../confirm-navigation-dialog'
import { Messages } from '../../messages';


@inject('rootStore', 'profileStore')
@observer
class ContactDetails extends React.Component {

    constructor(props) {
        super();
        const { rootStore, profileStore } = props;
        const formTitle = profileStore.isExistingUser ? "Edit your profile" : "Complete your profile";
        rootStore.setFormTitle(formTitle);
    }

    redirectToHomePage = () => {
        const { history } = this.props;
        history.push('/');
    }

    render() {
        const { rootStore, ...other } = this.props;
        const { isContactDetailsFormDirty } = this.props.profileStore;

        rootStore.setConfirmNavigation(this.confirmNavigation);

        setTimeout(() => {
            rootStore.setBreadcrumbs([]);
        }, 10);

        return (
            <>
                <ContactDetailsContent />
                <Prompt when={isContactDetailsFormDirty} message={() => { return this.navAwayMessage() }} />
                <ConfirmNavigationDialog
                    {...other}
                    confirmNavigationText={Messages.NavAwayConfirmMsg}
                    onDialogOk={this.dialogOkHandler} />
            </>
        );
    }

    navAwayMessage() {
        let message = null;
        if (this.props.profileStore.isContactDetailsFormDirty) {
            message = Messages.NavAwayConfirmMsg;
        } else {
            return true;
        }
        return message;
    }

    dialogOkHandler = () => {
        const { rootStore, profileStore } = this.props;

        profileStore.resetContactDetailsForm();
        rootStore.setConfirmNavigation(null);
    }
}

ContactDetails.propTypes = {
    rootStore: PropTypes.object,
    profileStore: PropTypes.object,
    history: PropTypes.object
}

export default ContactDetails;
