import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToMarkdown from 'draftjs-to-markdown';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styles = theme => ({
    submissionTextHeader: {
        fontWeight: 600,
        display: 'inline',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal,
    },
    editorHelpText: {
        display: 'flex',
        justifyContent: 'flex-end',
        float: 'right',
    },
    markdownEditorContainer: {
        border: '2px solid',
        borderColor: theme.palette.secondary.light,
        boxSizing: 'border-box',
        borderRadius: '3px'
    },
    markdownEditor: {
        height: '300px',
        padding: '10px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: '#39444F',
        ['& .DraftEditor-root']: {
            marginTop: '-20px'
        }
    },
    gridMargin:{
        marginBottom: '7px'
    }
})

const toolbarConfig = {
    options: ['inline', 'list'],
    inline: {
        options: ['bold', 'italic']
    },
    list: {
        options: ['unordered', 'ordered', 'indent', 'outdent']
    }
}

const submissionTextMaxLength = 8000;

@inject('submissionStore')
@observer
class SubmissionText extends React.Component {
    constructor(props) {
        super();
        //Only do this is there is no current state else it'll clear the content between back and forward navigations
        if (!props.submissionStore.submissionTextEditorState) {
            props.submissionStore.submissionTextEditorState = EditorState.createEmpty();
        }
    }

    render() {
        const { classes, submissionStore } = this.props;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid className={classes.gridMargin}>
                        <Typography variant="h4" className={classes.submissionTextHeader}>
                            Message
                    </Typography>
                        {
                            (submissionTextMaxLength - submissionStore.submissionPlainTextLength <= 0) &&
                            <Typography variant="body1" className={classes.editorHelpText}>
                                Maximum character limit reached.
                        </Typography>
                        }
                    </Grid>
                    <Editor
                        editorState={submissionStore.submissionTextEditorState}
                        wrapperClassName={classes.markdownEditorContainer}
                        editorClassName={classes.markdownEditor}
                        toolbar={toolbarConfig}
                        onEditorStateChange={this.handleEditorStateChange}
                        handleBeforeInput={this.handleEditorBeforeInput}
                        handlePastedText={this.handleEditorPastedText}
                        placeholder="Enter your message here"
                    />
                </Grid>
            </Grid>
        );
    }

    handleEditorStateChange = editorState => {
        const { submissionStore } = this.props;

        submissionStore.setIsWrittenSubmissionFormDirty(true);
        submissionStore.submissionTextEditorState = editorState;
        submissionStore.submissionText = editorState && draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
    }

    handleEditorBeforeInput = () => {
        const { submissionStore } = this.props;

        if (submissionStore.submissionPlainTextLength >= submissionTextMaxLength) {
            return 'handled';
        }
    };

    handleEditorPastedText = () => {
        const { submissionStore } = this.props;

        if (submissionStore.submissionPlainTextLength >= submissionTextMaxLength) {
            return 'handled';
        }
    }
}

SubmissionText.propTypes = {
    classes: PropTypes.object.isRequired,
    submissionStore: PropTypes.object
}

export default withStyles(styles)(SubmissionText);
