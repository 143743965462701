import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import { Enums } from '../../../enums';

import ActiveStage1Logo from '../../../../static/images/stage1_active.svg';
import ActiveStage2Logo from '../../../../static/images/stage2_active.svg';
import ActiveStage3Logo from '../../../../static/images/stage3_active.svg';
import InActiveStage1Logo from '../../../../static/images/stage1_inactive.svg';
import InActiveStage2Logo from '../../../../static/images/stage2_inactive.svg';
import InActiveStage3Logo from '../../../../static/images/stage3_inactive.svg';
import RedivisionStageDetails from './redivision-stage-details';
import RedivisionStagesSwipe from './redivision-stages-swipe';

const styles = theme => ({
    backgroundContainer: {
        background: theme.palette.secondary.background,
        padding: '20px 0',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    headerStyle: {
        textAlign: 'center'
    }
})

@inject('redivisionStore')
@observer
class RedivisionStages extends React.Component {

    render() {
        const { classes, redivisionStore } = this.props;
        const { activeRedivision, isStageOneActive, isStageTwoActive, isStageOneClosed, getMilestoneDate, isStageTwoClosed } = redivisionStore;

        const initialSubOpenTime = getMilestoneDate(Enums.MilestoneType.InitialSubmissionOpen);
        const initialSubCloseTime = getMilestoneDate(Enums.MilestoneType.InitialSubmissionClose);
        const respSubOpenTime = getMilestoneDate(Enums.MilestoneType.ProposedBoundariesPublished);
        const respSubCloseTime = getMilestoneDate(Enums.MilestoneType.SuggestionsAndObjectsionsClosed);
        const finalisedBoundariesTime = getMilestoneDate(Enums.MilestoneType.FinalReportPublished);
        return (
            <React.Fragment>
                <Grid container className={classes.backgroundContainer}>
                    <Hidden xsDown><Grid item sm={1} md={2} /></Hidden>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={10} md={8}>
                            <Grid className={classes.headerStyle}>
                                <Typography variant="h1" color="secondary">Make a submission</Typography>
                            </Grid>
                            {activeRedivision &&
                                <>
                                    <RedivisionStageDetails
                                        title='Stage One submissions'
                                        subTitle={isStageOneActive ?
                                            <>Open now <br /> Close {initialSubCloseTime.format('ha')} on {initialSubCloseTime.format('D MMMM YYYY')}</> :
                                            (isStageOneClosed ? <>Closed</> :
                                                <>Open {initialSubOpenTime.format('D MMMM YYYY')} <br /> Close {initialSubCloseTime.format('ha')} on {initialSubCloseTime.format('D MMMM YYYY')}</>)}
                                        imageLogo={isStageOneActive ? ActiveStage1Logo : InActiveStage1Logo}
                                        content={<>At the start of a redivision, the EBC invites submissions about the current State electoral boundaries.</>} />
                                    <RedivisionStageDetails
                                        title='Stage Two submissions'
                                        subTitle={isStageTwoActive ?
                                            <>Open now <br /> Close {respSubCloseTime.format('ha')} on {respSubCloseTime.format('D MMMM YYYY')}</> :
                                            (isStageTwoClosed ? <>Closed</> :
                                                <>Open {respSubOpenTime.format('D MMMM YYYY')} <br /> Close {respSubCloseTime.format('ha')} on {respSubCloseTime.format('D MMMM YYYY')}</>)}
                                        imageLogo={isStageTwoActive ? ActiveStage2Logo : InActiveStage2Logo}
                                        content={<>The EBC publishes a report with its proposed changes to the State’s electoral boundaries. You can submit suggestions and objections about the proposed boundaries. </>} />
                                    <RedivisionStageDetails
                                        title='Final boundaries'
                                        subTitle={<>Available {finalisedBoundariesTime.format('D MMMM YYYY')}</>}
                                        imageLogo={isStageTwoClosed ? ActiveStage3Logo : InActiveStage3Logo}
                                        content={<>The EBC publishes a report with final changes to State electoral boundaries. </>} />
                                </>}
                        </Grid>
                    </Hidden>
                    <Hidden smUp>
                        <RedivisionStagesSwipe />
                    </Hidden>
                    <Hidden xsDown><Grid item sm={1} md={2} /></Hidden>
                </Grid>
            </React.Fragment>
        );
    }
}

RedivisionStages.propTypes = {
    classes: PropTypes.object,
    redivisionStore: PropTypes.object,
};

export default compose(
    withStyles(styles)
)(RedivisionStages);
