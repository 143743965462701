import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { inject, observer } from 'mobx-react';
import { observable, action, computed } from "mobx";
import SignedInMenu from './profile/signed-in-menu';
import HamburgerMenu from './profile/hamburger-menu';
import HomeLogo from '../../static/images/home.svg';
import EBCLogo from '../../static/images/ebc.svg';
import HelpLogo from '../../static/images/help.svg';

const styles = theme => ({
    header: {
        display: 'flex',
        width: '100%',
        zIndex: '1000',
        background: theme.palette.secondary.background,
        borderBottom: '1px solid #B6C3D2',
        position: 'relative'
    },
    gridContainer: {
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '150px'
        }
    },
    headerLinkText: {
        textTransform: 'none',
        fontWeight: '600 !important',
        marginLeft: '10px',
        color: theme.palette.primary.contrastText,
        display: 'inline',
        fontSize: '18px',
        fontStyle: 'normal',
        lineHeight: '27px',
        letterSpacing: '0.2px'
    },
    headerTitleText: {
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '33px',
        marginLeft: '10px',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            lineHeight: '30px',
        }
    },
    buttonText: {
        textTransform: 'none',
        fontWeight: 'bold !important',
        marginLeft: '10px',
        color: theme.palette.primary.contrastText
    },
    button: {
        width: '120px',
        height: '35px',
        minWidth: 'unset',
        minHeight: 'unset',
        marginLeft: '10px',
        border: '2px solid #0E3A6C',
        boxSizing: 'border-box',
        borderRadius: '3px',
        top: 'calc(40% - 35px/2)'
    },
    helpButton: {
        minWidth: 'unset'
    },
    secondaryButtonLabel: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginLeft: '10px',
        color: theme.palette.white
    },
    inlineGrid: {
        display: 'inline-block'
    },
    linkButton: {
        display: 'inline-block',
        textDecorationLine: 'none !important'
    },
    setVerticleAlign: {
        verticalAlign: 'middle'
    },
    rightAlignedGrid: {
        textAlign: 'right',
        display: 'inline-block'
    },
    homeIcon: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '4px'
        }
    }
});

@inject('authStore', 'rootStore', 'profileStore', 'modelingMapStore', 'submissionStore')
@observer
class Header extends React.Component {

    @observable isClickedHamburgerMenu = false;

    @computed get disableHomeLink() {
        return !this.props.profileStore.isExistingUser;
    }

    @computed get showHelp() {
        return this.props.location.pathname.indexOf("mapping") !== -1;
    }

    @action
    setIsClickedHamburgerMenu = (isClicked) => {
        this.isClickedHamburgerMenu = isClicked;
    }

    componentDidMount() {
        this.props.submissionStore.resetSubmissionForm();
        window.addEventListener("beforeunload", this.unsavedChangesHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.unsavedChangesHandler);
    }

    unsavedChangesHandler = (event) => {
        const { rootStore } = this.props;
        if (rootStore.isDirty) {
            event.preventDefault();
            event.returnValue = '';
        }
    }


    handleHomeClick() {
        try {
            window.location.replace('https://ebc.vic.gov.au');
        } catch (e) {
            // IE bug causes location.replace to throw an unspecified error when 'Stay on this page' is clicked subsequently.
            // Ignore the error and continue.
        }
    }

    redirectToLandingPage = () => {
        const { history } = this.props;
        history.push('/');
    }

    showMapGuideline = () => {
        this.props.modelingMapStore.setShowMapGuidelinesDialog(true);
    }

    render() {
        const { classes } = this.props;
        const { authStore } = this.props;
        return (
            <AppBar elevation={0} className={classes.header}>
                <Toolbar name='Header'>
                    <Grid item xs={1} md={2} lg={3}>
                        <Link component="button" onClick={() => { this.handleHomeClick() }} className={classes.linkButton}>
                            <img src={HomeLogo} alt="Home logo" className={classes.homeIcon} />
                            <Hidden smDown>
                                <Typography variant="h1" className={classes.headerLinkText} id="ebc-title">
                                    EBC Home
                        </Typography>
                            </Hidden>
                        </Link>
                    </Grid>
                    <Grid item xs={10} md={8} lg={5} className={classes.gridContainer}>
                        <Grid className={classes.inlineGrid}>
                            <Hidden xsDown>
                                <img src={EBCLogo} alt="EBC logo" className={classes.setVerticleAlign} />
                            </Hidden>
                        </Grid>
                        <Grid className={classes.inlineGrid}>
                            <Link component="button" onClick={() => { this.redirectToLandingPage() }} className={classes.linkButton} disabled={this.disableHomeLink}>
                                <Typography className={classes.headerTitleText} id="tool-title">
                                    Public Submission Tool
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} md={2} lg={4} className={classes.rightAlignedGrid}>
                        <Hidden smDown>
                            {this.showHelp && <Button className={classes.helpButton} onClick={this.showMapGuideline}>
                                <img src={HelpLogo} alt="Help logo" />
                                <Typography variant="body1" className={classes.headerLinkText} id="help-title">
                                    Help
                                    </Typography>
                            </Button>}
                        </Hidden>
                        <Hidden mdDown>
                            {
                                authStore.isUserAuthenticated ?
                                    <SignedInMenu /> :
                                    <>
                                        <Button
                                            onClick={() => authStore.authenticateUser()}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            className={classes.button}
                                        >
                                            <Typography variant="body2" className={classes.buttonText}>
                                                Sign in
                                                </Typography>
                                        </Button>
                                        <Button
                                            onClick={() => authStore.register()}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            className={classes.button}
                                        >
                                            <Typography variant="body2" className={classes.secondaryButtonLabel}>
                                                Sign up
                                                </Typography>
                                        </Button>
                                    </>
                            }
                        </Hidden>
                        <Hidden lgUp>
                            {authStore.isUserAuthenticated ?
                                <SignedInMenu /> :
                                <HamburgerMenu />}
                        </Hidden>
                    </Grid>
                </Toolbar>
            </AppBar >
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    authStore: PropTypes.object,
    rootStore: PropTypes.object,
    profileStore: PropTypes.object,
    modelingMapStore: PropTypes.object,
    submissionStore: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};

export default compose(
    withRouter,
    withStyles(styles),
    withWidth()
)(Header);
