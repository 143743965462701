import BaseService from './base.service.js'
import { Tools } from '../tools';

class ConfigurationService extends BaseService {
    getEditableContent() {
        let url = '/api/configuration/editablecontent';
        return this.fetch(url).then(Tools.getResponseJson);
    }

    updateContentText(editableContent) {
        let url = '/api/configuration/editablecontent';
        return this.fetch(url, {
            method: 'put',
            body: JSON.stringify(editableContent),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(Tools.getResponseJson);
    }
    getConfigParameter(paramName) {
        let url = '/api/configuration/' + paramName;
        return this.fetch(url).then(Tools.getResponseString);

    }
}

const configurationService = new ConfigurationService();

export default configurationService;
