import { observable, action } from 'mobx';

export default class RouterStore {
  
  @observable location = {};
  @observable match = {};
  @observable history = {};

  constructor(sessionStore){
    this.sessionStore = sessionStore;
  }

  @action setRoute(location, match, history) {
    this.location = location;
    this.match = match;
    this.history = history;
  }

  @action navigateTo(route){
    this.history.push(route);
  }

  @action navigateBack(){
    this.history.goBack();
  }

  @action navigateForward(){
    this.history.navigateForward();
  }

}


