import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Enums } from '../../../enums'
import DraftSubmissions from '../submission/draft-submissions';
import CompletedSubmissions from '../submission/completed-submissions';

const styles = (theme) => ({
    titleContainer: {
        paddingTop: "30px",
        fontSize: '24px',
        lineHeight: '36px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "20px"
        }
    },
    lastPositionStage: {
        order: 1
    },
});

class SubmissionsSummaryStage2 extends React.Component {
    render() {
        const { classes, showDraftsStage2, showCompletedStage2 } = this.props;

        return (
            <Grid container>
                    {(showDraftsStage2 || showCompletedStage2) &&
                        <Grid item xs={12}>
                            <Typography variant="h2" color="secondary" className={classes.titleContainer}>Stage Two submissions</Typography>
                        </Grid>
                    }
                    {showDraftsStage2 &&
                        <Grid item xs={12}>
                            <DraftSubmissions stage={Enums.RedivisionSubmissionStage.ProposedBoundaries} reviewType={Enums.ReviewType.StateRedivision} />
                        </Grid>
                    }
                    {showCompletedStage2 &&
                        <Grid item xs={12}>
                            <CompletedSubmissions stage={Enums.RedivisionSubmissionStage.ProposedBoundaries} reviewType={Enums.ReviewType.StateRedivision} />
                        </Grid>
                    }
                </Grid>
        );
    }
}

SubmissionsSummaryStage2.propTypes = {
    classes: PropTypes.object,
    showDraftsStage2: PropTypes.bool,
    showCompletedStage2: PropTypes.bool
};

export default withStyles(styles)(SubmissionsSummaryStage2);