import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Hidden } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import PenIcon from '../../../../static/images/icon-pen.svg'
import MapIcon from '../../../../static/images/icon-map-2.svg'
import { inject, observer } from 'mobx-react';
import SignInFooter from './signin-footer';
import SubmissionsSummary from './submissions-summary'
import StyledPrimaryContainedButton from '../../styled-primary-contained-button'

const styles = theme => ({
    contentWrapper: {
        marginBottom: '30px'
    },
    roundedRect: {
        background: '#FEFEFE',
        border: '2px solid #B6C3D2',
        boxSizing: 'border-box',
        borderRadius: '8px',
        marginTop: '53px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            width: '325px',
            height: '213px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '330px',
            height: '310px'
        },
        [theme.breakpoints.up('md')]: {
            width: '90%',
            minWidth: '330px',
            maxWidth: '540px',
            height: '310px'
        }
    },
    writtenSubContainer: {
        marginTop: '53px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('xs')]: {
            width: '325px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '330px'
        },
        [theme.breakpoints.up('md')]: {
            width: '90%',
            minWidth: '330px',
            maxWidth: '540px'
        }
    },
    roundedRectWrittenSub: {
        background: '#FEFEFE',
        border: '2px solid #B6C3D2',
        boxSizing: 'border-box',
        borderRadius: '8px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            width: '325px',
            height: '213px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '330px',
            height: '310px'
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            minWidth: '330px',
            maxWidth: '540px',
            height: '310px'
        }
    },
    rectPositionLeft: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
            marginRight: '15px'
        }
    },
    rectPositionRigth: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            marginLeft: '15px'
        }
    },
    textContainer: {
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('xs')]: {
            marginTop: '29px'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '13px'
        }
    },
    writtenSubTitleText: {
        color: theme.palette.primary.contrastText
    },
    desktopReqText: {
        fontWeight: 'bold'
    },
    button: {
        width: '270px !important',
        height: '50px',
        marginTop: '21px'
    },
    icon: {
        marginTop: '29px'
    },
    submissionsContainer: {
        width: '200%'
    },
    subTitleForDesktop:{
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    subTitleForMobile:{
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '22px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    }
})

@inject('redivisionStore')
@observer
class StartSubmission extends React.Component {

    render() {
        const { classes, redivisionStore: { displayMakeSubmissions, displaySignInFooterNote, startTextBasedSubmission, startMapBasedSubmission }
        } = this.props;
        const isWidthUpMd = isWidthUp('md', this.props.width);
        const isWidthDownXs = isWidthDown('xs', this.props.width);
        const paragraphStyle = isWidthUpMd ? classes.subTitleForDesktop : classes.subTitleForMobile;
        const submissionTitleStyle = isWidthUpMd ? "h3" : "h4";

        return (
            <Grid container className={classes.contentWrapper}>
                <Grid item md={1} />
                <Grid container item sm={6} md={5} className={classes.rectPositionLeft}>
                    <div className={classes.writtenSubContainer}>
                        {displayMakeSubmissions && <div id="write_submission_rec" className={classes.roundedRectWrittenSub}>
                            <Hidden xsDown>
                                <img src={PenIcon} alt="Pen Icon" className={classes.icon} />
                            </Hidden>
                            <Grid container className={classes.textContainer}>
                                <Typography id="written-sub-title" className={classes.writtenSubTitleText} variant={submissionTitleStyle}>
                                    Written submission
                            </Typography>
                                <Typography id="create-sub-text-l1" variant="h4" className={paragraphStyle}>Create a text-based submission and</Typography>
                                <Typography id="create-sub-text-l2" variant="h4" className={paragraphStyle}>upload any supporting documents.</Typography>
                            </Grid>
                            <StyledPrimaryContainedButton color="primary" variant="contained" className={classes.button} onClick={() => startTextBasedSubmission()}>
                                Start
                            </StyledPrimaryContainedButton>
                        </div>}
                        {!isWidthDownXs && <Grid container className={classes.submissionsContainer}><SubmissionsSummary /></Grid>}
                        {!isWidthDownXs && displaySignInFooterNote && <SignInFooter isMobile={isWidthDownXs} />}
                    </div>
                </Grid>
                <Grid container item sm={6} md={5} className={classes.rectPositionRigth}>
                    {displayMakeSubmissions && <div id="write_map_submission_rec" className={classes.roundedRect}>
                        <Hidden xsDown>
                            <img src={MapIcon} alt="Map Icon" className={classes.icon} />
                        </Hidden>
                        <Grid container className={classes.textContainer}>
                            <Typography id="written-sub-map-title" className={classes.writtenSubTitleText} variant={submissionTitleStyle}>
                                Map-based submission
                                    </Typography>
                            <Typography id="edit-bound-text-l1" variant="h4" className={paragraphStyle}>{isWidthUpMd ? 'Create an electoral boundary' : 'Build or edit an electoral'}</Typography>
                            <Typography id="edit-bound-text-l2" variant="h4" className={paragraphStyle}>{isWidthUpMd ? 'model and written submission.' : 'boundary model.'}</Typography>
                        </Grid>
                        {isWidthUpMd &&
                            <StyledPrimaryContainedButton color="primary" variant="contained" className={classes.button} onClick={() => startMapBasedSubmission()}>
                                Start
                            </StyledPrimaryContainedButton>
                        }
                        {!isWidthUpMd &&
                            <Grid container className={classes.textContainer}>
                                <Typography id="desktop-req-text-l1" variant={"body2"} color="primary" className={classes.desktopReqText}>Note: A desktop computer is required</Typography>
                                <Typography id="desktop-req-text-l2" variant={"body2"} color="primary" className={classes.desktopReqText}>to make a map-based submission.</Typography>
                            </Grid>
                        }
                    </div>}
                    {isWidthDownXs && <Grid container className={classes.submissionsContainer}><SubmissionsSummary /></Grid>}
                    {isWidthDownXs && displaySignInFooterNote && <SignInFooter isMobile={isWidthDownXs} />}
                </Grid>
                <Grid item md={1} />
            </Grid>
        );
    }
}

StartSubmission.propTypes = {
    classes: PropTypes.object,
    redivisionStore: PropTypes.object,
    submissionStore: PropTypes.object,
    width: PropTypes.string
};

export default compose(
    withStyles(styles),
    withWidth(),
)(StartSubmission);