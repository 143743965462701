/*global google*/
/*global turf*/
import { observable, action, computed, runInAction, toJS} from 'mobx';
import {Enums} from '../enums';
import configurationService from '../services/configuration.service';
import * as martinez from 'martinez-polygon-clipping';
import redivisionService from '../services/redivision.service';

const  MIN_MAP_ZOOM_FOR_NUGGETS = 9;

export default class ModelingMapStore {

    constructor(sessionStore) {
        this.map = null;
        this.rawNuggetFeatures = null;
        this.rawExistingElectorateFeatures = null;
        this.sessionStore = sessionStore;
        this.rootStore = sessionStore.rootStore;
        this.councilStore = sessionStore.councilStore;
        this.draftSegments = null;
        this.colourSwatches = 
        [   '#FFC312', '#C4E538', '#12CBC4', '#FDA7DF', '#33CC33',
            '#5C2A08', '#0652DD', '#009432', '#FCF600', '#5813D8',
            '#75DBFF', '#9980FA', '#757171', '#03FC94', '#F59597',
            '#EA2027', '#006266', '#1B1464', '#CC00FF', '#ABCCC6'
        ];        
        configurationService.getConfigParameter('GuidedTourUrl').then(url => {
            if (url) {
                this.tourURL = url
            }
        });
        redivisionService.setAuthStore(sessionStore.authStore);
    }

    @observable googleAPILoadCalled = false;
    @observable googleAPILoaded = false;
    @observable segments = [];
    @observable segmentIdsInView = [];
    @observable boundaryIdsInView = [];
    @observable selectedSegmentIndex = -1;
    @observable nuggets = [];
    @observable existingBoundaries = [];
    @observable existingBoundaryLabels = [];
    @observable unassignedNuggetMarkers = [];
    @observable startFromExisting = false;
    @observable maxReviewMembers = 88;
    @observable minReviewMembers = 5;
    @observable nextSegmentId = 1;//This starts at 1 else google treats it as not an id
    @observable totalReviewMembers = 0; 
    @observable selectionMethod = Enums.SelectionMethod.Point;
    @observable showExistingBoundaries = false;
    @observable showExistingBoundaryLabels = false;
    @observable showPopDensity = false;
    @observable showWardInfo = true;
    @observable showLandingDialog = true;
    @observable showMapGuidelinesDialog = false;
    @observable showReviewWrittenPrompt = false;
    @observable layerSelectorExpanded = false;
    @observable existingBoundariesInfoExpanded = false;
    @observable validationMessages = [];    
    @observable tourURL = '';
    @observable showSegments = false;
    @observable finalExpectedPolygons = 0;
    @observable lastAction = null;
    @computed get showNuggets() {return true;}    
    @computed get selectedNuggets () {return this.nuggets.filter(nugget => {return nugget.properties.selected == true})}
    @computed get unassignedNuggets () {return this.nuggets.filter(nugget => {return !nugget.assignedSegmentId})}
    @computed get selectedSegment () {return this.selectedSegmentIndex > -1 && this.segments.length > this.selectedSegmentIndex? this.segments[this.selectedSegmentIndex] : null}
    @computed get selectedSegmentId () {return this.selectedSegment? this.selectedSegment.properties.id : 0}
    @computed get selectedElectors () {return this.selectedNuggets.reduce((totalElectors, nugget) => {return totalElectors + nugget.properties.electors} ,0)}
    @computed get totalReviewElectors () {return this.nuggets.reduce((totalElectors, nugget) => {return totalElectors + nugget.properties.electors}, 0)}
    @computed get unassignedReviewElectors () {return this.totalReviewElectors - this.segments.reduce((totalElectors, segment) => {return totalElectors + segment.properties.electors}, 0)}
    @computed get totalAssignedMembers () {return this.segments.reduce((totalMembers, segment) => {return totalMembers + segment.properties.members}, 0)}
    @computed get unassignedSegmentMembers () {return this.totalReviewMembers - this.totalAssignedMembers}
    @computed get avgElectorsPerMember () {return this.totalReviewMembers? this.totalReviewElectors/this.totalReviewMembers: 0 }
    @computed get segmentsInView() {return this.segments.filter(segment => {return this.segmentIdsInView.indexOf(segment.properties.id) !== -1})}
    @computed get existingBoundariesInView() {return this.existingBoundaries.filter(boundary => {return this.boundaryIdsInView.indexOf(boundary.properties.existingElectorateId) !== -1})}
    @computed get reviewStage() {return this.sessionStore.redivisionStore.submissionStage;}

    @action setShowLandingDialog(show) {
        this.showLandingDialog = show;
    }

    @action setShowMapGuidelinesDialog(show) {
        this.showMapGuidelinesDialog = show;
    }

    @action clearMapData() {
        this.segments.splice(0);
        this.selectedSegmentIndex = -1;
        this.nuggets.splice(0);
        this.existingBoundaries.splice(0);
        this.existingBoundaryLabels.splice(0);
        this.startFromExisting = false;
        this.nextSegmentId = 1;
        this.totalReviewMembers = 0; 
        this.selectionMethod = Enums.SelectionMethod.Point;
        
        this.showExistingBoundaryLabels = false;
        this.showPopDensity = false;
        this.showWardInfo = true;
        this.showLandingDialog = true;
        this.showMapGuidelinesDialog = false;
        this.showReviewWrittenPrompt = false;
        this.layerSelectorExpanded = false;
        this.existingBoundariesInfoExpanded = true;
        this.rawNuggetFeatures = null;
        this.rawExistingElectorateFeatures = null;
        this.draftSegments = null;
        this.finalExpectedPolygons = 0;
        this.lastAction = null;
        this.setIsMapDirty(false);
    }

    @action setIsMapDirty(isDirty) {
        this.rootStore.setDirty(isDirty);
    }

    @action viewportChanged(viewPortZoom, viewPortBounds) {
        if (this.calcInMapViewTimer) {
            clearTimeout(this.calcInMapViewTimer)
        }
        this.calcInMapViewTimer = setTimeout(() => {
            // let startTime = performance.now();
            runInAction(() => {
                let boundsRect = [viewPortBounds.getSouthWest().lng(), viewPortBounds.getNorthEast().lat(), viewPortBounds.getNorthEast().lng(), viewPortBounds.getSouthWest().lat()];
                this.segmentIdsInView.clear();
                this.boundaryIdsInView.clear();
                const bounds = turf.bboxPolygon(boundsRect);
                if (viewPortZoom > MIN_MAP_ZOOM_FOR_NUGGETS) {
                    toJS(this.segments).forEach(segment => {
                        if (segment.geometry && segment.geometry.coordinates.length !== 0 && martinez.intersection(bounds.geometry.coordinates, segment.bbox.geometry.coordinates) && martinez.intersection(bounds.geometry.coordinates, segment.geometry.coordinates) !== null) {
                            this.segmentIdsInView.push(segment.properties.id);
                        }
                    });

                    toJS(this.existingBoundaries).forEach(boundary => {
                         if (boundary.geometry.coordinates.length !== 0 && martinez.intersection(bounds.geometry.coordinates, boundary.bbox.geometry.coordinates) && martinez.intersection(bounds.geometry.coordinates, boundary.geometry.coordinates) !== null) {
                            this.boundaryIdsInView.push(boundary.properties.existingElectorateId);
                        }
                    });
                }
            });
            //    console.log('viewport calc took ' + (performance.now() - startTime));
        }, 500);
    }

    @action flagGoogleAPILoadSucceeded(success){
        this.googleAPILoaded = success;
        this.googleAPILoadCalled = true;
    }

    @action toggleWardInfo() {
        this.showWardInfo = !this.showWardInfo;
    }

    @action toggleShowReviewWrittenPrompt(value) {
        this.showReviewWrittenPrompt = value;
    }
    @action toggleShowExistingBoundaryLabels() {
        this.showExistingBoundaryLabels = !this.showExistingBoundaryLabels;
    }

    @action toggleExistingBoundaries() {
        this.showExistingBoundaries = !this.showExistingBoundaries;

        if(!this.showExistingBoundaries){
            this.showExistingBoundaryLabels = false;
        }
    }

    @action togglePopDensity() {
        this.showPopDensity = !this.showPopDensity;
    }

    storeCurrentActionState(action) {
        this.lastAction = {action: action, nuggets: [], targetSegment: {...this.selectedSegment.properties}}
        if (action === 'DeleteSegment') {
            let segmentNuggets = this.nuggets.filter(nugget => {return nugget.assignedSegmentId === this.selectedSegmentId})
            segmentNuggets.forEach(nugget => {
                this.lastAction.nuggets.push({id: nugget.properties.blockid, assignedSegmentId: nugget.assignedSegmentId});
            });
        } else {
            this.selectedNuggets.forEach(nugget => {
                this.lastAction.nuggets.push({id: nugget.properties.blockid, assignedSegmentId: nugget.assignedSegmentId});
            });
        }
    }   

    @action undoLastAction() {
        if (this.lastAction) {
            let targetSegments  = [];
            let nuggetIds;
            switch(this.lastAction.action) {
                case 'AssignNuggets' :
                case 'UnassignNuggets' :
                    this.lastAction.nuggets.forEach(nugget => {
                        const targetSegment = targetSegments.find(targetSegment => {return targetSegment.segmentId === nugget.assignedSegmentId })
                        if (targetSegment) {
                            targetSegment.nuggets.push(nugget.id);
                        } else {
                            targetSegments.push({segmentId: nugget.assignedSegmentId, nuggets: [nugget.id]});
                        }
                    })
                    targetSegments.forEach(targetSegment => {
                        this.selectSegment(targetSegment.segmentId);
                        this.clearSelectedNuggets();                    
                        this.nuggets.forEach(nugget => {
                            if (targetSegment.nuggets.includes(nugget.properties.blockid)) {
                                nugget.properties.selected = true;
                            }
                        });
                        if (targetSegment.segmentId) {
                            this.assignNuggets(true);                        
                        } else {
                            this.unassignNuggets(null, true);
                        }
                    });
                    break;
                case 'DeleteSegment' :
                    this.addSegment(this.lastAction.targetSegment.name, this.lastAction.targetSegment.colour, this.lastAction.targetSegment.members);
                    nuggetIds = this.lastAction.nuggets.map(nugget => nugget.id);
                    this.nuggets.forEach(nugget => {
                        if (nuggetIds.includes(nugget.properties.blockid)) {
                            nugget.properties.selected = true;
                        }
                    });
                    this.assignNuggets(true);
                    this.lastAction.targetSegment = {...this.selectedSegment.properties};
            break;
            }
        }
        this.lastAction.undone = true;
    }

    redoLastAction() {
        if (this.lastAction && this.lastAction.undone) {
            let targetNuggets;
            let nuggetIds;
            switch(this.lastAction.action) {
                case 'UnassignNuggets':
                    nuggetIds = this.lastAction.nuggets.map(nugget => nugget.id);
                    targetNuggets = this.nuggets.filter(nugget => {return nuggetIds.includes(nugget.properties.blockid)});
                    this.unassignNuggets(targetNuggets, true);
                break;
                case 'AssignNuggets': 
                    nuggetIds = this.lastAction.nuggets.map(nugget => nugget.id);
                    this.selectSegment(this.lastAction.targetSegment.id);
                    this.clearSelectedNuggets();
                    this.nuggets.forEach(nugget => {
                        if (nuggetIds.includes(nugget.properties.blockid))
                        {
                            nugget.properties.selected = true;
                        }
                    });
                    this.assignNuggets( true);

                    break;
                case 'DeleteSegment':
                    this.deleteSegment(this.lastAction.targetSegment.id, true);
                    break;
            }
            this.lastAction.undone = false;
        }
    }

    @action selectSegment(segmentId){
        //clear any existing selection
        if (this.selectedSegment){
            this.selectedSegmentIndex = -1;
        }
        //If new selection is possible do it
        if (segmentId && this.segments.length) {
            this.segments.some((segment, index) => {
                if (segment.properties.id == segmentId){
                    this.selectedSegmentIndex = index;
                    return true;
                } else {
                    return false;
                }
            });
        }
    }

    @action calcDeviation(electors, Members, average){
        if  (!average) average = this.avgElectorsPerMember
        return (((electors/Members)/average) * 100) -100;            
    }

    recalcSegmentDeviations(){
        this.segments.forEach((segment) => {
            segment.properties.deviation = this.calcDeviation(segment.properties.electors, segment.properties.members)
        });
    }

    @action addSegment(name, colour, members){
        if (!colour) {
            colour = this.colourSwatches[this.nextSegmentId % this.colourSwatches.length];
        }
        if (this.unassignedSegmentMembers > 0){
            this.segments.push(new Segment(this.nextSegmentId, name, colour, members));
            this.selectSegment(this.nextSegmentId);
            this.nextSegmentId ++;
        }   
    }

    @action deleteSegment(id, isSubAction = false){
        this.setIsMapDirty(true);
        let segmentIndex = this.segments.findIndex(segment => {return segment.properties.id == id});
        let newSelectedSegmentIndex = this.selectedSegmentIndex;
        if (segmentIndex > -1)        
        {
            if (!isSubAction) {this.storeCurrentActionState('DeleteSegment');}
            if (newSelectedSegmentIndex >= segmentIndex){
                newSelectedSegmentIndex --;
                if (newSelectedSegmentIndex < 0 && this.segments.length > 1){
                    newSelectedSegmentIndex = 0;
                }            
            }

            let nuggetsToUnassign = [];
            this.nuggets.forEach((nugget) => {
                if (nugget.assignedSegmentId == id){
                    nuggetsToUnassign.push(nugget);
                }                
            });
            this.unassignNuggets(nuggetsToUnassign, true);
            this.segments.splice(segmentIndex,1);
            if (newSelectedSegmentIndex > -1 && this.segments.length > newSelectedSegmentIndex) {
                this.selectSegment(this.segments[newSelectedSegmentIndex].properties.id)
            } else {
                this.selectedSegmentIndex = -1;
            }
        }
    }

    @action assignNuggets(isSubAction = false) {
        this.setIsMapDirty(true);
        if (this.selectedNuggets.length && this.selectedSegment) {
            if (!isSubAction) {this.storeCurrentActionState('AssignNuggets')}
            let newGeom = JSON.parse(JSON.stringify(this.selectedSegment));
            this.unassignNuggets(this.selectedNuggets, true);
            runInAction(() => {
                this.selectedNuggets.forEach(nugget => {
                    this.removeUnassignedMarker(nugget);
                    nugget.assignedSegmentId = this.selectedSegment.properties.id;
                    this.selectedSegment.properties.electors += nugget.properties.electors;
                    this.selectedSegment.properties.projected += nugget.properties.projected? nugget.properties.projected : 0;
                    this.selectedSegment.properties.deviation = this.calcDeviation(this.selectedSegment.properties.electors, this.selectedSegment.properties.members);
                    if (newGeom.geometry.coordinates.length > 0) {
                        newGeom = turf.union(newGeom, nugget);
                    }
                    else {
                        newGeom = nugget;
                    }
                });
                this.selectedSegment.geometry = newGeom.geometry;
                this.selectedSegment.bbox = turf.bboxPolygon(turf.bbox(newGeom));
                this.clearSelectedNuggets();
            });
        }        
    }
    
    @action unassignNuggets(nuggets, isSubAction = false) {
        this.setIsMapDirty(true);
        let nuggetsToUnassign = nuggets || this.selectedNuggets;    
        if (!isSubAction) {this.storeCurrentActionState('UnassignNuggets')}
        runInAction(() => {
            this.segments.forEach(segment => {
                let nuggetsFromSegment = nuggetsToUnassign.filter(nugget => {return nugget.assignedSegmentId === segment.properties.id});
                if(nuggetsFromSegment.length !== 0) {
                    let totalElectorsToRemove = 0;
                    let totalProjectionToRemove = 0;
                    let geomToRemove = turf.polygon([]);
                    nuggetsFromSegment.forEach(nugget => {
                        totalElectorsToRemove += nugget.properties.electors;
                        totalProjectionToRemove += nugget.properties.projected? nugget.properties.projected : 0;
                        nugget.assignedSegmentId = 0;
                        if (geomToRemove.geometry.coordinates.length !== 0) {
                            geomToRemove = turf.union(geomToRemove, nugget);
                        } else {
                            geomToRemove = nugget;
                        }
                    });
                    if (geomToRemove.geometry.coordinates.length > 0 ) {
                        let resultingFeat = turf.difference(segment, geomToRemove);
                        segment.geometry = resultingFeat? resultingFeat.geometry : turf.polygon([]).geometry;
                        resultingFeat = null;
                    }                
                    this.selectedSegment.bbox = turf.bboxPolygon(turf.bbox(this.selectedSegment));
                    segment.properties.electors -= totalElectorsToRemove;
                    segment.properties.projected -= totalProjectionToRemove;
                    segment.properties.deviation = this.calcDeviation(segment.properties.electors, segment.properties.members);
                }
            });
        });
        if (!nuggets) this.clearSelectedNuggets();
    }

    @action initiliseMap(mapRef){
        this.map = mapRef;
        this.zoomAndCenterMap();
    }

    zoomAndCenterMap(featureCollection){
        if (this.map && (this.rawNuggetFeatures || featureCollection)) {
            let boundingBox = turf.bbox(featureCollection && featureCollection.features.length > 0? featureCollection: this.rawNuggetFeatures);
            let mapBounds = new google.maps.LatLngBounds({lat: boundingBox[1], lng: boundingBox[0]}, {lat: boundingBox[3], lng: boundingBox[2]});
            let bbCentre = mapBounds.getCenter();
            this.map.setCenter(bbCentre);
            this.map.fitBounds(mapBounds);
        }
    }

    @action loadData(reviewId) {
        Promise.all([
        this.loadNuggets(reviewId),
        this.loadExistingBoundaries(reviewId)
        ]).then(() => {
            this.buildexistingBoundaries();
        });

    }

    @action loadDraftData(restoredSegments, reviewId) {
        Promise.all([
        this.loadNuggets(reviewId),
        this.loadExistingBoundaries(reviewId)
        ]).then(() => {
            this.buildexistingBoundaries();
            this.populateDraft(restoredSegments);
        });
    }

    @action loadNuggets(reviewId){
        if (reviewId) {
            this.unassignedNuggetMarkers.clear();
            this.rootStore.setBusy(true);
            return redivisionService.getMapBlocks(reviewId).then(blocks => {
                if (blocks.length !== 0) {
                    runInAction(() => {
                        this.rawNuggetFeatures = {type: 'FeatureCollection', features: blocks};
                        blocks.forEach(nugget => {
                            nugget.geometry = JSON.parse(nugget.geometry);
                            nugget.properties.selected=false;
                            nugget.assignedSegmentId = 0;
                            this.nuggets.push(nugget);
                        });
                        if (this.segments.length !== 0) {
                            this.backCalculateNumMembers();
                            this.reallocateNuggets();
                        }
                    });
                } else {
                    this.rawNuggetFeatures = null; 
                    this.nuggets.splice(0);

                }
                if (this.startFromExisting && this.nuggets.length !== 0 && this.existingBoundaries.length != 0) {
                    this.buildexistingBoundaries();
                }
            }).finally(() => {
                this.rootStore.setBusy(false);
            });            
        } else {
            return Promise.reject();
        }
    }

    @action populateDraft(restoredSegments) {
        // TODO: populate draft map        
        if (restoredSegments.length !== 0) {            
            this.segments.replace(restoredSegments.map(segment =>  
                {  
                    const seg = { ...segment, type: 'Feature', geometry: JSON.parse(segment.geometry), properties:{...segment.properties, id: segment.properties.segmentId } };
                    seg.bbox = turf.bboxPolygon(turf.bbox(seg));   
                    return seg;
                }
            )
            );

            if (this.segments.length !== 0) {
                this.selectSegment(this.segments[0].properties.id);
            }
            this.nextSegmentId = this.segments.length +1;
            this.draftSegments = restoredSegments;
            if (this.totalReviewElectors !== 0) {
                this.backCalculateNumMembers();
                this.reallocateNuggets();
            }
        }
    }

    @action backCalculateNumMembers() {
        //Because we only save assigned members with a model we need to reverse calculate the total members from the deviation        
        this.segments.some(segment => {
            //find the first segment with electors assigned and back calculate from that one
            if (segment.properties.electors !== 0) {
                let memberElectorRatio =  (segment.properties.electors / segment.properties.members / ((segment.properties.deviation + 100)/100));
                this.totalReviewMembers = Math.round(this.totalReviewElectors / memberElectorRatio);
                return true;
            } else {
                return false;
            }
        });
    }

    @action reallocateNuggets() { 
        this.nuggets.some(nugget => { nugget.assignedSegmentId = 0; });
        if (this.draftSegments) {       
            this.draftSegments.forEach(segment => {
                segment.blocks.forEach(block => {
                    this.nuggets.some(nugget => {
                        if (nugget.properties.blockid == block) {
                            nugget.assignedSegmentId = segment.properties.segmentId;
                            return true;
                        }  else {
                            return false;
                        }
                    })
                })
            });
        }
    }

    buildexistingBoundaries() {
        this.rootStore.setBusy(true);
        this.totalReviewMembers = this.existingBoundaries.reduce((totalMembers, electorate) => totalMembers + electorate.properties.members, 0);
        runInAction(() => {
            this.existingBoundaries.forEach(electorate => {
            this.addSegment(electorate.properties.name, this.colourSwatches[electorate.properties.defaultColour], electorate.properties.members);
                this.selectedSegment.properties.electors = electorate.properties.electors;
                this.selectedSegment.properties.projected = electorate.properties.projected;
                this.selectedSegment.properties.deviation = electorate.properties.deviation;
                this.selectedSegment.geometry = electorate.geometry;
                this.selectedSegment.bbox = electorate.bbox;
                this.nuggets.forEach(nugget => {
                    if (nugget.properties.currentWard == electorate.properties.num) {
                        nugget.assignedSegmentId = this.selectedSegment.properties.id;
                    }
                });
            });
        });
        this.finalExpectedPolygons = this.countSegmentPolygons();
        this.showSegments = true;
        this.rootStore.setBusy(false);
    }

    loadExistingBoundaries = (reviewId) => {
        this.rootStore.setBusy(true);
        return redivisionService.getExistingDistricts(reviewId).then(existingElectorates => {
            this.existingBoundaries.clear();
            this.existingBoundaryLabels.clear();
            if (existingElectorates && existingElectorates.length !== 0) {
                runInAction(() => {
                    let totals =  existingElectorates.reduce((sum, electorate) => {
                        return {electors: sum.electors + electorate.properties.electors, members: sum.members + electorate.properties.members} 
                    }, {electors: 0, members: 0});
                    existingElectorates.forEach(electorate => {
                        if (electorate.geometry.length != 0) {
                            electorate.geometry = JSON.parse(electorate.geometry);
                            electorate.bbox = turf.bboxPolygon(turf.bbox(electorate));

                            let centroid = turf.centerOfMass(electorate);
                            this.existingBoundaryLabels.push({text: electorate.properties.name, lat:centroid.geometry.coordinates[1], lng: centroid.geometry.coordinates[0]});
                        }
                        electorate.properties.deviation = this.calcDeviation(electorate.properties.electors, electorate.properties.members, totals.electors/totals.members);                        
                        this.existingBoundaries.push(electorate);
                    });
                    this.rawExistingElectorateFeatures = {type: 'FeatureCollection', features: this.existingBoundaries};
                    this.zoomAndCenterMap(this.rawExistingElectorateFeatures);

                });
            }
            this.rootStore.setBusy(false);
        }).catch(error => {
            this.rawExistingElectorateFeatures = null;
            console.log(error);
            this.rootStore.setBusy(false);
        });
    }

    countSegmentPolygons() {
        let totalPolyCount = 0;
        this.segments.forEach(segment => {
            if(segment.geometry) {
                if (segment.geometry.type === 'Polygon') {
                    totalPolyCount += segment.geometry.coordinates.length;
                } else if (segment.geometry.type === 'MultiPolygon') {
                    segment.geometry.coordinates.forEach(subPolygon => {
                        totalPolyCount += subPolygon.length;
                    })
                }
            }
        });
        return totalPolyCount;
    }

    @action clearSelectedNuggets(){
        this.selectedNuggets.forEach(nugget => {
            nugget.properties.selected = false;
        });
    }


    @action nuggetClick(feature){     
        let clickedNuggetId = feature.getId();
        this.nuggets.some((nugget) => {
            if (nugget.properties.blockid == clickedNuggetId) {
                nugget.properties.selected = !nugget.properties.selected;
                return true;
            } else {
                return false;
            }
        });       
    }

    @action selectNuggetsIntersectingPoly (polygon) {   
        this.clearSelectedNuggets();
        let selectionFeature = {
            geometry: {
                type: 'Polygon',
                coordinates: []
            },
            properties: {},
            type: 'Feature'
        }
    
        if (polygon.getRadius) {
            //this is a circle
        } else if (polygon.getBounds) {
            //This is a rectangle
            let rectangleBounds = polygon.getBounds();
            selectionFeature = turf.bboxPolygon([rectangleBounds.getSouthWest().lng(), rectangleBounds.getSouthWest().lat(), rectangleBounds.getNorthEast().lng(), rectangleBounds.getNorthEast().lat()]);
        } else if (polygon.getPath) {
            //this if a polygon            
            let nodes = [];
            polygon.getPath().forEach(coordPair => {
                nodes.push([coordPair.lng(), coordPair.lat()]);
            });
            nodes.push(nodes[0]);//Close loop
            selectionFeature = turf.polygon([nodes]);
        }
        polygon.setMap(null);
        this.nuggets.forEach(nugget => {            
            const overlap = martinez.intersection(selectionFeature.geometry.coordinates, nugget.geometry.coordinates)
            if (overlap && overlap.length !== 0){
                nugget.properties.selected = true;
            }
        });
        this.selectionMethod = Enums.SelectionMethod.Point;
    }

    @action validateMap () {
        let messages = [];        
        const segmentsWithNoNuggets = this.segments.filter(segment => {return this.nuggets.map(nugget => {return nugget.assignedSegmentId}).indexOf(segment.properties.id) == -1}).length;
        const segmentsWithExcessiveDeviation = this.segments.filter(segment => {return segment.properties.deviation > 10 || segment.properties.deviation < -10}).length;
        const hasNonContiguousSegments = this.finalExpectedPolygons < this.countSegmentPolygons();
        if (this.unassignedNuggets.length) messages.push('Blocks not assigned to district(s): ' + this.unassignedNuggets.length);        
        if (segmentsWithNoNuggets) messages.push('Districts with no blocks assigned to them: ' + segmentsWithNoNuggets);
        if (segmentsWithExcessiveDeviation) messages.push('Districts outside the +/-10% requirement: ' + segmentsWithExcessiveDeviation);
        if (hasNonContiguousSegments) messages.push('There are one or more districts with non-connecting blocks and/or gaps.');
        if (this.segments.length < 88) messages.push('Your model has fewer than the required 88 districts.');
        this.validationMessages.replace(messages);
        return messages.length === 0;        
    }

    @action markUnassignedNuggets() { 
        const unassignedVisible = this.unassignedNuggetMarkers.length !== 0;
        this.unassignedNuggetMarkers.clear();
        if (!unassignedVisible) {
            runInAction(() => {
                this.unassignedNuggets.forEach(nugget => {
                    let centroid = turf.centerOfMass(nugget);
                    this.unassignedNuggetMarkers.push({id: nugget.properties.blockid, latLng: {lat: centroid.geometry.coordinates[1], lng: centroid.geometry.coordinates[0]}});
                });
                let featureCollection = {type: 'FeatureCollection', features: this.unassignedNuggets};
                this.zoomAndCenterMap(featureCollection);
            });
        }
    }

    @action removeUnassignedMarker(nugget) {
        let markerToRemove = -1;
        if (nugget.assignedSegmentId === 0) {
            this.unassignedNuggetMarkers.some((marker, index) => {
                if (marker.id === nugget.properties.blockid) {
                    markerToRemove = index;
                    return true;    
                } else {
                    return false;
                }
            });
            if (markerToRemove !== -1) {
                this.unassignedNuggetMarkers.splice(markerToRemove, 1);
            }
        }        
    }

    @action displaySelectedSegment() {   
        let nuggetsSelectedSegment = [];
        this.nuggets.forEach((nugget) => {
            if (nugget.assignedSegmentId == this.selectedSegment.properties.id){
                nuggetsSelectedSegment.push(nugget);
            }                
        });

        let featureCollection = {type: 'FeatureCollection', features: nuggetsSelectedSegment};

        if(featureCollection && featureCollection.features.length > 0) {
            this.zoomAndCenterMap(featureCollection);
        }
    }

    nuggetStyle(nugget) {
        let selected = nugget.getProperty('selected');
        return { clickable: true, strokeWeight: 2, zIndex: selected? 10: 1, fillColor: "#959595", fillOpacity: selected? 0.5: 0.15, strokeColor: selected? '#13b1fe': '#9c9c9c' };
    }

    unassignedNuggetMarkerStyle() {
        return {anchor: {x: 12, y: 24}, fillColor: '#F05340', fillOpacity: 1, strokeColor: '#F05340', strokeWeight: 2, path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z'}        
    }

    segmentStyle(segment) {
        var style = { fillColor: segment.getProperty('colour'), fillOpacity: 0.5, clickable: false, strokeColor: '#ffffff', strokeWeight: 3, strokeOpacity: 1, zIndex: 5 };
        return style;
    }

    existingBoundariesStyle() {
        var style = { fillOpacity: 0, clickable: false, strokeColor: '#000000', strokeWeight: 2, strokeOpacity: 1, zIndex: 6, visibility: 'on'};
        return style;
    }

}

class Segment{
    constructor(id, name, colour, members=1){
        this.properties.name = name, 
        this.properties.colour = colour;
        this.properties.id = id;
        this.properties.members = members;
    }
    @observable properties = {
        name: '',
        members: 1,
        electors: 0,
        projected: 0,
        deviation: -100,
        colour: ''
    }
    @observable geometry = {
        type: 'Polygon',
        coordinates: []
    }
    @observable type = 'Feature';

}

