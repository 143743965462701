import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions, Input, FormControlLabel,
    Typography, Radio, RadioGroup, Grid
} from '@material-ui/core';
//import IntInput from './int-input';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

const styles = theme => ({
    dialogContainerWithoutImage: {
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: 'unset',
            height: 'unset',
        }
    },
    imageDialogContainer: {
        height: '400px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: 'unset',
            height: 'unset',
        }
    },
    imageContainer:{
        textAlign: 'center',
    },
    actionContainer: {
        justifyContent: 'center',
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '42px',
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        alignItems: 'center',
    },
    content: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal,
        textAlign: 'center',
        alignItems: 'center',
    },
    leftAlign: {
        textAlign: 'left'
    },
    leftButtonContainer: {
        textAlign: 'right', 
        paddingRight: '5px',
    },
    rightButtonContainer: {
        textAlign: 'left', 
        paddingLeft: '5px',
    },
    leftButton: {
        minWidth: '120px'
    },
    rightButton: {
        minWidth: '120px'
    }
})

@inject('msgBoxStore')
@observer
class MsgBox extends React.Component {

    render() {
        const{ classes, msgBoxStore } = this.props;
        const { cancelButton, confirmButton, content, header, imageLogo, open, size, close, confirm, hideConfirm, hideCancel, leftAlignContent } = msgBoxStore;
        let { inputOptions, inputValid, validateData } = msgBoxStore;
        let inputControl;
        let contentClasses = [classes.content];
        if (leftAlignContent) contentClasses.push(classes.leftAlign);

        switch (inputOptions.type.toUpperCase()) {
            /* TODO: This needs to be verified as functioning as it's just been pulled from batch manager which used semantic ui instead of materialui
            case 'NUMBER':
                inputControl = <div>                                        
                    <IntInput fluid id="input" // onValidate={inputOptions.validateFn} 
                        value={inputOptions.value} 
                        disabled= {!inputOptions.editable}
                        onChange={(e) => { validateData(e.target.value)}} 
                        acceptNegative= {inputOptions.minNum < 0}/>
                 </div>
                break
                */
            case 'RADIO':
                inputControl = <RadioGroup>
                    {inputOptions.items.map((item, index) => {
                        return <FormControlLabel key={"input" + index} label={item}
                            control={<Radio id={"input" + index} style={{ fontSize: '1.2em' }}
                                checked={inputOptions.value == index}
                                value={index}
                                disabled={!inputOptions.editable}
                                onChange={(e) => {
                                    validateData(parseInt(e.target.value))
                                }
                                }
                            />} />
                    })}
                </RadioGroup>
                break;
            case 'GENERIC':
                inputControl = inputOptions.inputControl;
                break;
            case 'TEXT':
                inputControl = <Input id="input"
                    value={inputOptions.value}
                    disabled={!inputOptions.editable}
                    style={{ width: '100%' }}
                    onChange={(e) => { validateData(e.target.value) }}
                />
                break;
            case 'NONE':
            default:
                inputControl = null;
                break;
        }

        return (
            <Dialog id="messageBox" open={open} fullWidth={!!size} maxWidth={size}>
                <Grid container className={classes.dialogContainerWithoutImage}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        {!!header && <DialogTitle>
                        {imageLogo && <Grid className={classes.imageContainer}><img src={imageLogo} alt="Success logo" /></Grid>}
                                <Typography className={classes.title}>{header}</Typography>
                        </DialogTitle>}
                        <DialogContent>
                            <DialogContentText component='div'>
                            {!!content && <ReactMarkdown source={content} className={contentClasses.join(' ') }/>}
                            </DialogContentText>
                            {inputOptions.label && <Typography variant='subtitle2'>{inputOptions.label}</Typography>}
                            {inputControl !== null && inputControl}
                            <p style={{ fontSize: '0.8em', height: '0.8em', margin: '0px', textAlign: 'right', color: 'red' }} >
                                {!inputValid && inputOptions.failedValidationMsg}
                            </p>
                        </DialogContent>
                        <DialogActions className={classes.actionContainer}>
                            {(!hideCancel && !hideConfirm) &&
                                    <Grid container>
                                        <Grid item xs={12} sm={6} className={classes.leftButtonContainer}>
                                            <Button onClick={close} color="primary" variant="outlined" className={classes.leftButton}>
                                                {cancelButton}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={classes.rightButtonContainer}>
                                            <Button onClick={confirm} color="primary" variant="contained" className={classes.rightButton}>
                                                {confirmButton}
                                            </Button>
                                        </Grid>
                                    </Grid>}
                                {(!hideCancel && hideConfirm) &&
                                    <Button onClick={close} variant='outlined' color='primary' >
                                        {cancelButton}
                                    </Button>}
                                {(hideCancel && !hideConfirm) &&
                                    <Button onClick={confirm} color='primary' variant='contained'>
                                        {confirmButton}
                                    </Button>}
                        </DialogActions>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Dialog>
        )
    }
}
    MsgBox.propTypes= {
        
    classes: PropTypes.object.isRequired,
        msgBoxStore: PropTypes.object
    }

export default withStyles(styles)(MsgBox);