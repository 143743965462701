import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Translate } from '@material-ui/icons';

const primaryMain = '#D93C29';
const secondaryMain = '#0E3A6C';
const baseTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
});
const vecTheme = createMuiTheme({
    overrides: {
        MuiAppBar: {
            root: {
                backgroundColor: '#DCEEF6 !important',
                color: secondaryMain + ' !important',
                '& svg': {
                    color: secondaryMain + ' !important'
                }
            }
        },
        MuiLink: {
            root: {
                cursor: 'pointer !important',
                fontWeight: 600,
                fontSize: '18px',
                color: primaryMain + ' !important',
                textDecorationLine: 'underline',
                lineHeight: '27px',
                letterSpacing: '0.2px',
                '&:disabled': {
                    cursor: 'default !important'
                }
            },
            underlineHover: {
                textDecorationLine: 'underline'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '8px 8px 20px',
            }
        },
        MuiButton: {
            root: {
                borderRadius: '3px',
                textTransform: 'none',
                minHeight: '50px',
                minWidth: '180px',
                fontWeight: 'bold',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.2px',
                flex: 'none',
                padding: '6px 36px',
                alignSelf: 'center',        
                [baseTheme.breakpoints.down('sm')]: {
                    minWidth: '140px',                    
                    padding: '6px 26px',
                    marginBottom: '15px'
                },
                [baseTheme.breakpoints.down('xs')]: {
                    minWidth: 'unset',
                    padding: '6px 16px',
                    width: '100% !important',
                    marginBottom: '15px'
                },
            },
            containedPrimary: {
                color: '#FFFFFF',
                '&:hover': {
                    backgroundColor: primaryMain,
                },
            },
            outlinedPrimary: {
                color: primaryMain,
            },
            label: {
              textTransform: 'none',
            },
        },
        MuiTooltip: {
            tooltip: {
                color: '#39444F',
                backgroundColor: '#ffffff',
                padding: '15px',
            }
        },
        MuiFormHelperText:{
            root: {
                color: '#e13023 !important'
            }
        },
        MuiTypography: {
            h1: {
                [baseTheme.breakpoints.up('xs')]: {
                    fontSize: '32px',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    lineHeight: '48px'
                },
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '40px',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    lineHeight: '60px'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '46px',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    lineHeight: '69px'
                }
            },
            h2: {
                fontSize: '38px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: '57px'
            },
            h3: {
                fontSize: '28px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: '42px'
            },
            h4: {
                fontSize: '24px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: '36px'
            },
            h5: {
                fontSize: '20px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: '30px'
            },
            h6: {
                fontSize: '14px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: '21px',
                letterSpacing: '0.4px'
            },
            /* P1 Paragraph */
            subtitle1: {
                fontSize: '18px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                lineHeight: '27px',
                letterSpacing: '0.2px',
                color: '#39444F'
            },
            /* P2 Paragraph */
            subtitle2: {
                fontSize: '16px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: '0.2px',
                color: '#39444F'
            },
            /* P3 Paragraph */
            body1: {
                fontSize: '14px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                lineHeight: '22px',
                letterSpacing: '0.2px',
                color: '#39444F'
            },
            /* P4 Paragraph */
            body2: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                lineHeight: '21px',
                letterSpacing: '0.2px'
            },
            button: {
                fontSize: '14px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: '21px',
                letterSpacing: '3.5px',
                textTransform: 'uppercase'
            },
            caption: {
                fontSize: '12px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: '18px',
                letterSpacing: '3.5px',
                textTransform: 'uppercase'
            },
            overline: {
                fontSize: '10px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: '15px',
                textTransform: 'uppercase'
            },
        },
        MuiOutlinedInput: {
            root: {
                background: '#FFFFFF',
                border: '2px solid #B6C3D2',
                boxSizing: 'border-box',
                borderRadius: '3px'
            }
        },
        MuiInputLabel: {
            root: {
                color: '#B6C3D2'
            },
            outlined: {
                transform: 'translate(14px, 22px) scale(1)'
            }
        }
    },
    palette: {
        primary: {
            light: '#DCEEF6',
            main: primaryMain,
            dark: secondaryMain,
            contrastText: secondaryMain
        },
        secondary: {
            light: '#B6C3D2',
            main: secondaryMain,
            dark: '#0e253a',
            charcoal: '#39444F',
            background: '#DCEEF6',
            contrastText: '#F7F7F7'
        },
        navy: {
            light: '#54A0D2',
            main: secondaryMain,
            dark: '#00396b',
            contrastText: '#F7F7F7',
            azure: '#0899fe'

        },
        gray: {
            gray100: '#2F2F2F',
            gray200: '#8F949A',
            gray300: '#E4E4E4',
            gray400: '#F3F4F5',
            gray500: '#C4C4C4',
            gray600: '#F5F5F5'
        },
        red: {
            main: '#EB0000',
            contrastText: '#F7F7F7'
        },
        green: {
            main: '#31BB00',
            contrastText: '#F7F7F7'
        },
        cloudyBlue: '#b6c3d2',
        white: '#FFFFFF',
        error:{
            main: '#e13023'
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Poppins',
            'Roboto',
            'Arial',
            'sans-serif'
        ].join(',')
    }
});

const dyslexicTheme = Object.assign({}, vecTheme, {
    typography: {
        h1: {
            fontSize: '40px',
            fontWeight: '500'
        },
        h2: {
            fontSize: '32px',
            fontWeight: '400'
        },
        h3: {
            fontSize: '22px'
        },
        fontFamily: [
            'OpenDyslexic',
            'Arial',
            'sans-serif'
        ].join(','),
        useNextVariants: true
    }
});

const dyslexicVecTheme = createMuiTheme(dyslexicTheme);

@inject('rootStore')
@observer
class VecTheme extends React.Component {
    render() {
        const { rootStore } = this.props;

        return (
            <MuiThemeProvider theme={rootStore.IsDyslexicTheme ? dyslexicVecTheme : vecTheme}>
                {this.props.children}
            </MuiThemeProvider>
        );
    }
}

VecTheme.propTypes = {
    children: PropTypes.object.isRequired,
    rootStore: PropTypes.object
};

export default VecTheme;