import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import PreliminaryQuestions from './preliminary-questions';
import SubmissionText from './submission-text';
import SubmissionAttachments from './submission-attachments';
import ConfirmMappingSubmissionDialog from './confirm-mapping-submission-dialog';
import SubmissionCompleteDialog from './submission-complete-dialog';

const styles = theme => ({
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    gridMargin: {
        marginTop: '40px',
    },
    subTitle: {
        fontStyle: 'italic',
        letterSpacing: 'normal',
        fontWeight: 'normal',
        marginTop: '10px',
        fontSize: '18px',
        lineHeight: '27px',
        color: theme.palette.secondary.charcoal
    },
    hr: {
        height: '1px',
        background: '#C4C4C4',
        border: 'none'
    },
    lbHrFieldsCompulsoryContainer: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    submitButton:{
        border: '2px solid'
    },
    title: {
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: '27px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    }
});

@inject('rootStore', 'submissionStore', 'redivisionStore')
@observer
class WrittenSubmission extends React.Component {

    constructor(props) {
        super();
        const { rootStore } = props;
        rootStore.setFormTitle('Your written submission');
    }

    componentDidMount() {
        this.initializeComponentDetails();
    }

    initializeComponentDetails = () => {
        const { submissionStore, match } = this.props;
        submissionStore.resetSubmissionForm();
        //const councilId = match.params.councilId;
        let submissionId = match.params.submissionId;
        this.resumedFromDraft = !!submissionId;
        if (!submissionId && submissionStore.draftDetails.submissionId) {
            submissionId = submissionStore.draftDetails.submissionId;
            //history.push(`/reviews/councils/${councilId}/writtensubmission/${submissionId}`);
        }

        submissionStore.completedWrittenSubmission = false;
        if (submissionId) {
            submissionStore.getDraftDetails(submissionId, false);
        }
    }

    render() {
        const { classes, ...other } = this.props;

        return (
            <>
                <Typography variant="h2" className={classes.title}>
                    Please provide your written submission below as well as any supporting attachments.
                </Typography>
                <Typography variant="h3" className={classes.subTitle}>
                    All fields are compulsory.
                </Typography>
                <Grid item xs={12} className={classes.lbHrFieldsCompulsoryContainer}>
                    <hr className={classes.hr} />
                </Grid>
                <SubmissionText />
                <SubmissionAttachments />
                <Grid item xs={12} className={classes.lbHrFieldsCompulsoryContainer}>
                    <hr className={classes.hr} />
                </Grid>
                <PreliminaryQuestions />
                <Grid item xs={12} className={classes.lbHrFieldsCompulsoryContainer}>
                    <hr className={classes.hr} />
                </Grid>
                <Grid container className={classes.gridMargin}>
                    <Grid item xs={12} sm={6}>
                        <Button color="primary" variant="contained" onClick={() => this.handleSave()}>
                            Save draft
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.actionButtonContainer}>
                        <Button color="primary" variant="outlined" onClick={this.handleSubmit} className={classes.submitButton}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
                <ConfirmMappingSubmissionDialog {...other} />
                <SubmissionCompleteDialog {...other} />
            </>
        );
    }

    handleSave = () => {
        this.props.submissionStore.setAttachmentsHelperText('');
        this.props.submissionStore.validateWrittenAndSave(true);
    }

    handleSubmit = () => {
        const { submissionStore } = this.props;
        submissionStore.completedWrittenSubmission = true;
        submissionStore.setAttachmentsHelperText('');
        if (submissionStore.isValidWrittenSubmission()) {
            submissionStore.saveSubmission(false, false, false);
        }
        else {
            submissionStore.showWrittenSubmissionValidationError();
        }
    }
}

WrittenSubmission.propTypes = {
    classes: PropTypes.object.isRequired,
    rootStore: PropTypes.object,
    submissionStore: PropTypes.object,
    redivisionStore: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    width: PropTypes.any
}

export default compose(
    withRouter,
    withStyles(styles)
)(WrittenSubmission);
