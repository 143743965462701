import React from 'react'
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import ChevronRightIcon from '../../../static/images/chevron-right-icon.svg';
import DeviationIcon from '../../../static/images/deviation_icon.svg';
import ElectoratesIcon from '../../../static/images/electorates_icon.svg';
import VotersIcon from '../../../static/images/voters_icon.svg';
import StyledPrimaryContainedButton from '../styled-primary-contained-button';

const styles = () => ({
    dialogContainer: {
        maxWidth: '755px',
        alignItems: 'center',
        padding: '38px 50px 50px 50px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px'
    },
    actionContainer: {
        justifyContent: 'center',
        paddingTop: '39px',
        paddingBottom: 'unset'
    },
    textContainer: {
        textAlign: 'center'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '11px',
        marginTop: '36px'
    },
    chevronIcon: {
        position: 'absolute',
        top: '43%'
    },
    alignCenter: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    paddingUnset: {
        padding: 'unset'
    },
    skipButtonStyle: {
        padding: '9px',
        fontSize: '16px',
        lineHeight: '24px',
        marginLeft: 'unset !important'
    }
});

@inject("modelingMapStore")
@observer
class LandingDialog extends React.Component {

    render() {
        const { classes, modelingMapStore } = this.props;
        const { showLandingDialog } = modelingMapStore;

        return (
            <Dialog open={showLandingDialog} fullWidth={true} classes={{ paper: classes.dialogContainer }}>
                <Grid container>
                    <Grid item md={12}>
                        <Typography color="secondary" variant="h3" className={classes.textContainer}>Your goal</Typography>
                        <DialogContent className={classes.paddingUnset}>
                            <Grid container>
                                <Grid item md={9}>
                                    <Grid container>
                                        <Grid item md={4}>
                                            <Grid>
                                                <img src={ElectoratesIcon} alt="Electorates Icon" className={classes.image} />
                                            </Grid>
                                            <Grid className={classes.alignCenter}>
                                                <Typography color="secondary" variant="h5">
                                                    88 districts
                                                </Typography>
                                            </Grid>
                                            <Grid className={classes.textContainer}>
                                                <Typography variant="subtitle2" className={classes.textContainer}>
                                                    Your aim is to create 88 districts (voting areas) across Victoria.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Grid className={classes.alignCenter}>
                                                <img src={ChevronRightIcon} alt="Chevron Right Icon" className={classes.chevronIcon} />
                                            </Grid>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Grid>
                                                <img src={VotersIcon} alt="Voters Icon" className={classes.image} />
                                            </Grid>
                                            <Grid className={classes.alignCenter}>
                                                <Typography color="secondary" variant="h5">
                                                    48,600 voters
                                                </Typography>
                                            </Grid>
                                            <Grid className={classes.textContainer}>
                                                <Typography variant="subtitle2" className={classes.textContainer}>
                                                    These districts should contain roughly 48,600 voters.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Grid className={classes.alignCenter}>
                                                <img src={ChevronRightIcon} alt="Chevron Right Icon" className={classes.chevronIcon} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={3}>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <Grid>
                                                <img src={DeviationIcon} alt="Deviation Icon" className={classes.image} />
                                            </Grid>
                                            <Grid className={classes.alignCenter}>
                                                <Typography color="secondary" variant="h5">
                                                    10% deviation
                                                </Typography>
                                            </Grid>
                                            <Grid className={classes.textContainer}>
                                                <Typography variant="subtitle2" className={classes.textContainer}>
                                                    Deviations within +/- 10% of the 48,600 are well-balanced.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.actionContainer}>
                            <StyledPrimaryContainedButton onClick={this.showHowToCreateMapSubmission} color="primary" variant="contained">
                                Show me how
                            </StyledPrimaryContainedButton>
                            <Link href="#" component='button' variant="subtitle2" onClick={this.hideLandingDialog} className={classes.skipButtonStyle}>
                                or, skip
                            </Link>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Dialog >
        )
    }

    hideLandingDialog = () => {
        const { modelingMapStore } = this.props;
        modelingMapStore.setShowLandingDialog(false);
    }

    showHowToCreateMapSubmission = () => {
        const { modelingMapStore } = this.props;
        modelingMapStore.setShowMapGuidelinesDialog(true);
        modelingMapStore.setShowLandingDialog(false);
    }
}

LandingDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    modelingMapStore: PropTypes.object
};

export default withStyles(styles)(LandingDialog);
