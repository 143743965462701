import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Hidden, Accordion, AccordionSummary, AccordionDetails, Checkbox, Typography, FormGroup, FormControlLabel, Grid, Table, TableHead, TableCell, TableRow, TableBody, Switch } from "@material-ui/core";
import FloatingBox from "../floating-box";
import IconLayers from "-!svg-react-loader?name=IconLayers!../../../static/images/icon-layers-2.svg";
import IconChevronDown from "-!svg-react-loader?name=IconChevronDown!../../../static/images/down-arrow.svg";
import { observer } from "mobx-react";
import ExistingBoundariesInfo from "./existing-boundaries-info";
import {Enums} from "./../../enums";
const styles = theme => ({
    floatingPanel: {
        backgroundColor: "green",
        "@media print": {
            display: "none !important"
        }
    },
    title: {
        fontWeight: "600",
        display: "inline"
    },
    layersIcon: {
        padding: "0px 10px 0px 0px"
    },
    accordionPanelDetailsContainer: {
        flexDirection: "column",
        padding: '0px'
    },
    accordionCollapsed: {
        width: "233px"
    },
    accordionExpanded: {
        width: "400px"
    },
    existingDistrictsHeader: {
        width: "90%"
    },
    labelHeader: {
        width: "10%"
    },
    layerOptionsContainer: {
        padding: '8px 16px 0px'
    }
});

@observer
class LayerSelector extends React.Component {
    constructor(props) {
        super(props);
        this.modelingStore = props.modelingStore;
    }

    render() {
        const { classes, parentElement } = this.props;
        const { showExistingBoundaries, layerSelectorExpanded, showExistingBoundaryLabels, reviewStage } = this.modelingStore;
        return (
            <Hidden smDown>
                <FloatingBox anchorEl={parentElement} name="layerSelector" className={classes.floatingPanel} offset={{ x: 24, y: 10 }} position="top-left" zIndex={1}>
                    <Accordion
                        onChange={(event, state) => {
                            this.updateExpandedStateVar(state);
                        }}
                        expanded={!!layerSelectorExpanded}
                        className={layerSelectorExpanded ? classes.accordionExpanded : classes.accordionCollapsed}
                    >
                        <AccordionSummary expandIcon={<IconChevronDown />}>
                            <IconLayers className={classes.layersIcon} />{" "}
                            <Typography variant={"body2"} color={"secondary"} className={classes.title}>
                                Layers
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionPanelDetailsContainer}>
                            <Grid className={classes.layerOptionsContainer} >  
                                <Table padding="none">
                                    <colgroup>
                                        <col className={classes.existingDistrictsHeader} />
                                        <col className={classes.labelHeader} />
                                    </colgroup>
                                    <TableHead>
                                        <TableRow className={classes.infoTable}>
                                            <TableCell>Show</TableCell>
                                            <TableCell>Label</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox color="primary" checked={showExistingBoundaries} />} 
                                                    onClick={() => this.toggleExistingDistricts()} label={reviewStage === Enums.RedivisionSubmissionStage.InitialSubmission? "Existing districts" : "Proposed districts"} labelPlacement="end" />
                                                </FormGroup>
                                            </TableCell>
                                            <TableCell>
                                                <FormGroup>
                                                    <FormControlLabel control={<Switch color="primary" checked={showExistingBoundaryLabels && showExistingBoundaries} 
                                                    onChange={() => this.toggleExistingDistrictNames()}  disabled={!showExistingBoundaries} />} />
                                                </FormGroup>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <ExistingBoundariesInfo modelingStore={this.modelingStore} />
                        </AccordionDetails>
                    </Accordion>
                </FloatingBox>
            </Hidden>
        )
    }

    updateExpandedStateVar = state => {
        this.modelingStore.layerSelectorExpanded = state;
    }

    toggleExistingDistricts() {
        this.modelingStore.toggleExistingBoundaries();
    }

    togglePopDensity() {
        this.modelingStore.togglePopDensity();
    }

    toggleExistingDistrictNames = () => {
        this.modelingStore.toggleShowExistingBoundaryLabels();
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        parentElement: PropTypes.object,
        modelingStore: PropTypes.object
    }
}

export default withStyles(styles)(LayerSelector)
