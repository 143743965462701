export const Enums = {
    SelectionMethod: {
        Point: 0,
        Rectangle: 1,
        Polygon: 2
    },

    SubmissionStage: {
        None: 0,
        Preliminary: 1,
        Response: 2,
        Addendum: 3
    },

    RedivisionSubmissionStage: {
        None: 0,
        InitialSubmission: 1,
        ProposedBoundaries: 2,
        FinalisedBoundaries: 3
    },

    MilestoneType: {
        PreliminarySubmissionOpen: 1,
        PublicInformationSession: 2,
        PreliminarySubmissionsClose: 3,
        PreliminaryReportPublished: 4,
        ResponseSubmissionsClose: 5,
        PublicHearing: 6,
        FinalReportPublished: 7,
        AddendumOpen: 8,
        AddendumClose: 9,
        InitialSubmissionOpen: 10,
        InitialSubmissionClose: 11,
        ProposedBoundariesPublished: 12,
        SuggestionsAndObjectsionsClosed: 13
    },

    FileExtensionType: {
        Pdf: 1,
        Docx: 2
    },

    ReportType: {
        GuideForSubmissions: 1,
        PreliminaryReport: 2,
        FinalReport: 3,
        Addendum: 4
    },

    ReviewType: {
        RepresentationReview: 1,
        Subdivision: 2,
        StateRedivision: 3
    }
}