import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
    loadingSpinnerWrapper: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: '9999999999',
        backgroundColor: 'rgba(255,255,255,0.5)'
    },
    loadingSpinner: {
        left: '50%',
        top: '50%',
        marginLeft: -30,
        marginTop: -30,
        position: 'relative'
    }
};

@inject('rootStore')
@observer
class LoadingSpinner extends React.Component {
    render() {
        const { classes, show } = this.props;
        const showSpinner = show || this.props.rootStore.isBusy;

        return (
            <React.Fragment>
                {showSpinner && <div className={classes.loadingSpinnerWrapper}>
                    <CircularProgress className={classes.loadingSpinner} color="primary" thickness={5} size={60} />
                </div>}
            </React.Fragment>
        )
    }
}

LoadingSpinner.propTypes = {
    classes: PropTypes.object.isRequired,
    show: PropTypes.bool,
    rootStore: PropTypes.object
};

export default withStyles(styles)(LoadingSpinner);
