import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Divider, Typography, Button, Link } from '@material-ui/core';

const styles = theme => ({
    gridContainer:{
        paddingLeft: '40px'
    },
    paginationContainer: {
        paddingLeft: '40px'
    },
    stageBtn: {
        cursor: 'pointer',
        padding: '6px'
    },
    stepPagination: {
        height: '35px',
        width: '35px',
        borderRadius: '50%',
        textAlign: 'center'
    },
    activeStepPagination: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    inactiveStepPegination: {
        border: '2px solid',
        borderColor: theme.palette.secondary.main,
        boxSizing: 'border-box'
    },
    stepText: {
        fontWeight: 'bold',
        display: 'inline',
        verticalAlign: 'middle'
    },
    activeStepText: {
        color: theme.palette.white,
    },
    inactiveStepText: {
        color: theme.palette.secondary.main,
    },
    stepperConnector: {
        border: '1px dashed',
        borderColor: theme.palette.gray.gray200,
        width: '40px',
        height: 0,
        position: 'absolute',
        top: '50%'
    },
    connectorGrid: {
        width: '40px',
        position: 'relative'
    },
    hideButton: {
        display: 'none'
    },
    backButton: {
        display: 'inline',
        top: '30%',
        fontSize: '14px',
        lineHeight: '22px'
    },
    submitButton: {
        width: '94px',
        height: '40px',
        minWidth: 'unset',
        minHeight: 'unset',
        fontSize: '14px',
        lineHeight: '22px'
    },
    submitButtonContainer: {
        position: 'absolute',
        right: '50px'
    }
});

class MapGuidelinePagination extends React.Component {
    render() {
        const { classes, activeStep, handleChangeActiveStep, handleBack, handleNext } = this.props;
        const stagePaginationButtons = [];

        for (let i = 0; i < 8; i += 1) {
            stagePaginationButtons.push(
                <React.Fragment key={i}>
                    <Grid item>
                        <IconButton index={i} className={classes.stageBtn} onClick={() => handleChangeActiveStep(i)}>
                            {i === activeStep ?
                                <Grid className={[classes.stepPagination, classes.activeStepPagination].join(' ')}>
                                    <Typography align="center" variant="subtitle1" className={[classes.stepText, classes.activeStepText].join(' ')}>
                                        {i + 1}
                                    </Typography>
                                </Grid> :
                                <Grid className={[classes.stepPagination, classes.inactiveStepPegination].join(' ')}>
                                    <Typography align="center" variant="subtitle1" className={[classes.stepText, classes.inactiveStepText].join(' ')}>
                                        {i + 1}
                                    </Typography>
                                </Grid>}
                        </IconButton>
                    </Grid>
                    {i < 7 &&
                        <Grid item className={classes.connectorGrid} >
                            <Divider className={classes.stepperConnector} />
                        </Grid>}
                </React.Fragment>);
        }
        return (
            <Grid container className={classes.gridContainer} >
                <Grid item xs={1}>
                    <Link href="#"
                        component='button'
                        variant="body1"
                        onClick={handleBack}
                        className={(activeStep === 0) ? classes.hideButton : classes.backButton}
                    >
                        Previous
                    </Link>
                </Grid>
                <Grid item xs={10}>
                    <Grid container className={classes.paginationContainer}>
                        {stagePaginationButtons}
                    </Grid>
                </Grid>
                <Grid item xs={1} className={classes.submitButtonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={(activeStep >= 7) ? classes.hideButton : classes.submitButton}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

MapGuidelinePagination.propTypes = {
    classes: PropTypes.object,
    activeStep: PropTypes.number,
    handleChangeActiveStep: PropTypes.func,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func
};

export default withStyles(styles)(MapGuidelinePagination);