import BaseService from './base.service.js'
import { Tools } from '../tools';

class ProfileService extends BaseService {
    getProfile() {
        const url = `/api/profiles`;
        return this.fetch(url).then(Tools.getResponseJson);
    }

    doesProfileExist(id) {
        const url = `/api/profiles/exists/${id}`;
        return this.fetch(url).then(Tools.getResponseJson);
    }

    saveProfile(profile) {
        const url = `/api/profiles`;

        return this.fetch(url, {
            method: 'post',
            body: JSON.stringify(profile),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(Tools.getResponseJson);
    }
}

const profileService = new ProfileService();

export default profileService;
