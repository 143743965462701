/*global google*/
import React from 'react'
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { withProps } from "recompose";
import { GoogleMap, Marker, LoadScriptNext, DrawingManager, MarkerClusterer} from "@react-google-maps/api"
import {Enums} from '../../enums';
import DataLayer from './data-layer';
import LayerSelector from './layers-selector';
import FloatingBox from '../floating-box';
import {Button, Hidden} from '@material-ui/core';
import IconList from '-!svg-react-loader?name=IconList!../../../static/images/icon-list.svg';

const DEFAULT_MAP_CENTER = { lat: -36.397, lng: 145.644 };

@observer
class ModelingMap extends React.Component {

    constructor(props) {
        super(props);
        this.modelingStore = props.modelingStore;
        this.mapContainer = document.querySelector('div[name="mapContainer"]')        
        this.googleLibraries= ['geometry','drawing','places'];        
    }

    @observable map;

    render(){
        const {nuggets, segments, nuggetStyle, segmentStyle, showWardInfo, showExistingBoundaries, showNuggets, showSegments, showExistingBoundaryLabels, selectionMethod, 
                unassignedNuggetMarkers, unassignedNuggetMarkerStyle,
             existingBoundaries, existingBoundariesStyle, existingBoundaryLabels, googleAPILoaded} = this.modelingStore;
        const {classes} = this.props;
        const mapDiv = this.map ? this.map.getDiv(): null;

        return (
            <React.Fragment>
             <LoadScriptNext id='script-loader' googleMapsApiKey='AIzaSyCiPf-efDgYB9EHmqN2mOGa9SnLlIXyk3Y' channel='EBCSubmissionTool' libraries={this.googleLibraries} language='en' region='AU' onError={() => {this.modelingStore.flagGoogleAPILoadSucceeded(false)}} onLoad={() => {this.modelingStore.flagGoogleAPILoadSucceeded(true)}} >                
                    {/* <Hidden mdUp>
                        <FloatingBox anchorEl={mapDiv} offset={{x:24, y: 10}} zIndex={1}>
                            <Button className={[classes.toolButton, classes.toolButtonActive].join(' ')} onClick={() => {if (this.props.finishBtnFn) this.props.finishBtnFn();}}>
                                Finish Map
                            </Button>
                        </FloatingBox>
                    </Hidden> */}
                    <LayerSelector parentElement = {mapDiv} modelingStore={this.modelingStore}/>
                    {showWardInfo || <FloatingBox anchorEl={mapDiv}  position='top-right' offset={{x: 24, y: 10}} zIndex={1}>
                        <Button className={classes.invertedButton} onClick={this.toggleWardInfo} id="btnWardInfo">
                            Ward info <IconList style={{padding: '5px'}}/>
                        </Button>
                    </FloatingBox>}
                    {googleAPILoaded &&
                    <GoogleMap
                         zoom={8}
                         center={DEFAULT_MAP_CENTER}
                         mapContainerStyle={{width: '100%', height: '100%'}}
                         onLoad={this.updateMapStoreMapRef }
                         onBoundsChanged={this.viewportChanged}
                        options={{                        
                            mapTypeControl: true,
                            mapTypeControlOptions: {mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE], position: google.maps.ControlPosition.RIGHT_BOTTOM, style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
                            streetViewControl: false,
                            zoomControl: true,
                            fullscreenControl: false,
                            
                            gestureHandling: 'greedy',
                            styles: [
                                { stylers: [{ 'lightness': 20 }, { 'saturation': -80 }] },
                                {
                                    featureType: 'road',
                                    elementType: 'geometry',
                                    stylers: [ { color: '#ffffff'  } ]
                                },
                                {
                                    featureType: 'road.highway',
                                    elementType: 'labels.icon',
                                    stylers: [{ visibility: 'off' }]
                                },
                                {
                                    featureType: 'poi',
                                    elementType: 'labels.icon',
                                    stylers: [{ visibility: 'off' }]
                                }
                            ]
                            
                        }}
                    >
                        <DataLayer dataSource={nuggets} featureId={'blockid'} map={this.map} styleFn={nuggetStyle} onClick={(event) => {if (selectionMethod === Enums.SelectionMethod.Point) this.modelingStore.nuggetClick(event);}} zoomExtent={true} visible={showNuggets}/>
                        <DataLayer dataSource={segments} featureId={'id'} map={this.map} visible={showSegments}styleFn={segmentStyle} onClick={(event) => this.modelingStore.nuggetClick(event)}/>
                        {unassignedNuggetMarkers.length !== 0 && <MarkerClusterer zoomOnClick averageCenter imageSizes={[66]} styles = {[{url: "/images/unassignedCluster.svg", textColor: 'white', height: 66, width: 66}]}>
                            {clusterer => unassignedNuggetMarkers.map(marker => {
                                return <Marker position={marker.latLng} key={marker.id} icon={unassignedNuggetMarkerStyle()} clusterer={clusterer}/>
                            })}
                        </MarkerClusterer>}                    
                        <DataLayer dataSource={existingBoundaries} featureId={'num'} map={this.map} styleFn={existingBoundariesStyle} visible={showExistingBoundaries}/>
                        {existingBoundaryLabels.map((label, index) => {
                            return <Marker key={'Label' + index} position={{ lat: label.lat, lng: label.lng }} icon={{path: google.maps.SymbolPath.CIRCLE, scale: 0, size: {width:0, height: 0}}} label={{color: 'black', fontSize: '16px', fontWeight: '700', text: label.text}} visible={showExistingBoundaryLabels}/>;
                        })}
                        {selectionMethod === Enums.SelectionMethod.Rectangle && 
                        <DrawingManager
                            drawingMode={'rectangle'}
                            options={{
                                drawingControl: false,
                                rectangleOptions: {
                                    fillOpacity: 0,
                                    strokeWeight: 3,
                                    clickable: false,
                                    editable: false,
                                    zIndex: 1,
                                }
                            }}
                            onRectangleComplete={(polygon) => {this.selectNuggetsIntersectingPoly(polygon);}}
                        />
                        }
                        {selectionMethod === Enums.SelectionMethod.Polygon && 
                        <DrawingManager
                            drawingMode={'polygon'}
                            options={{
                                drawingControl: false,
                                polygonOptions: {
                                    fillOpacity: 0,
                                    strokeWeight: 3,
                                    clickable: false,
                                    editable: false,
                                    zIndex: 1,
                                }
                            }}
                            onPolygonComplete={(polygon) => {this.selectNuggetsIntersectingPoly(polygon);}}
                        />
                        }
                    </GoogleMap>  }                  
                </LoadScriptNext>   
            </React.Fragment>
        )
    }    

    selectNuggetsIntersectingPoly = (polygon) => {
        this.modelingStore.selectNuggetsIntersectingPoly(polygon);
    }

    @action updateMapStoreMapRef = (ref) => {
        if (ref) {
            this.map=ref;
            this.modelingStore.initiliseMap(ref);
        } else {
            this.modelingStore.initiliseMap(null);
        }

    }

    @action.bound viewportChanged() {
        if (this.map) {            
            this.modelingStore.viewportChanged(this.map.getZoom(), this.map.getBounds());
        }
    }

    toggleHelpMenu = (event) => {
        this.popperElement = event.target;
        this.setState(state => {
            return {helpMenuVisible: !state.helpMenuVisible}
        });
    }

    hideHelpMenu = (event) => {
        if (this.popperElement.contains(event.target)) {
            return;
        }
        this.setState({helpMenuVisible: false});
    }

    toggleWardInfo = () => {
        this.modelingStore.toggleWardInfo();
    }

    static propTypes = {
        reviewId: PropTypes.string,
        modelingStore: PropTypes.object,
        classes: PropTypes.object,
        finishBtnFn: PropTypes.func
    }
}

export default withProps({  })(ModelingMap);