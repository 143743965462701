import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const buttonStyleTheme = createMuiTheme();

const StyledPrimaryContainedButton = withStyles({
    root: {
        color: '#FFFFFF',
        borderRadius: '3px',
        textTransform: 'none',
        height: '50px',
        minWidth: '180px',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        flex: 'none',
        order: 0,
        alignSelf: 'center',

        [buttonStyleTheme.breakpoints.down('sm')]: {
            minWidth: '140px',
            width: '140px',
            marginBottom: '15px'
        },
        [buttonStyleTheme.breakpoints.down('xs')]: {
            minWidth: 'unset',
            width: '100% !important',
            marginBottom: '15px'
        },
        '&:hover': {
            background: '#D93C29',
        }
    },
    label: {
      textTransform: 'none',
    },
  })(Button);

  export default StyledPrimaryContainedButton;