import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

@inject('authStore')
@observer
class PrivateRoute extends React.Component {

    render() {
        const { Component, authStore, ...rest } = this.props;

        if (!Component) {
            return null;
        }

        const isLoggedIn = authStore.isUserAuthenticated;

        return (
            <Route
                {...rest}
                render={(props) => isLoggedIn ? (<Component {...props} />) : (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
            />
        );
    }
}

PrivateRoute.propTypes = {
    Component: PropTypes.object,
    authStore: PropTypes.object,
    location: PropTypes.object
};

export default PrivateRoute