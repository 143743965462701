import BaseService from './base.service.js'
import { Tools } from '../tools';

const redivisionApiUrl = '/api/redivision';

class RedivisionService extends BaseService {

    getActiveRedivision() {
        let url = `${redivisionApiUrl}`;
        return this.fetch(url).then(Tools.getResponseJson);
    }

    getSpecificRedivisionDetails(reviewId) {
        let url = `${redivisionApiUrl}/${reviewId}`;
        return this.fetch(url).then(Tools.getResponseJson);
    }

    getMapBlocks(reviewId) {

        let url = `/api/redivision/${reviewId}/blocks`;
        return this.fetch(url).then(Tools.getResponseJson);
    }

    getExistingDistricts(reviewId) {
        let url = `/api/redivision/${reviewId}/existingGeometry/`;
        return this.fetch(url).then(Tools.getResponseJson);
    }
}

const redivisionService = new RedivisionService();

export default redivisionService;
