import 'whatwg-fetch'
import { Tools } from '../tools';

class CouncilService {
    getCouncils() {
        let url = '/api/councils';
        return fetch(url).then(Tools.getResponseJson);
    }

    getCouncilsWhthActiveSubmissions() {
        let url = '/api/councils/withactivesubmissions';
        return fetch(url).then(Tools.getResponseJson);
    }

    getCouncilDetails(councilId) {
        let url = `/api/councils/${councilId}`;
        return fetch(url).then(Tools.getResponseJson);
    }

    getCouncilBlocks(councilId) {

        let url = `/api/councils/${councilId}/blocks`;
        return fetch(url).then(Tools.getResponseJson);
    }

    getCouncilWards(electorateId, includeGeometry) {
        let url = '/api/councils/' + electorateId + '/wards/' + (!!includeGeometry).toString();
        return fetch(url).then(Tools.getResponseJson);
    }

    isSubmissionAllowed(councilId, stage) {
        let url = `/api/councils/${councilId}/issubmissionallowed/${stage}`;
        return fetch(url).then(Tools.getResponseJson);
    }
}

const councilService = new CouncilService();

export default councilService;
