import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Tools } from '../tools';
import { Paper, Button, Typography } from '@material-ui/core';

const styles = theme => ({
    footer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: '65px',
        padding: '1rem',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%'
    },
    content: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '27px',
        letterSpacing: '0.2px',
        color: theme.palette.white,
        alignItems: 'center',
        display: 'flex'
    },
    buttonText: {
        textTransform: 'none',
        fontWeight: 'bold !important',
        marginLeft: '10px',
        color: theme.palette.white
    },
    button: {
        width: '120px',
        height: '35px',
        marginLeft: 'calc(35% - 35px/2)',
        border: '2px solid',
        borderColor: theme.palette.white,
        boxSizing: 'border-box',
        borderRadius: '3px',
        top: 'calc(40% - 35px/2)',
        '&:hover': {
            border: '1px solid',
            borderColor: theme.palette.white,
        }
    },
})

@inject('rootStore')
@observer
class BrowserWarning extends Component {

    @computed get showBrowserSupportFooter() {
        return Tools.isIE && this.props.rootStore.showBrowserFooter;
    }

    render() {
        const { classes, rootStore: { setBrowserFooter } } = this.props;

        return (
            this.showBrowserSupportFooter && <Paper className={classes.footer} elevation={5}>
                <Grid container>
                    <Hidden smDown>
                        <Grid item md={1}>
                        </Grid>
                    </Hidden>
                    <Grid container item xs={12} md={9}>
                        <Grid container>
                            <Typography variant="h5" className={classes.content}>
                                This site uses technology not supported by your browser. For an optimal experience please use Chrome, Edge or Firefox.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                        <Button
                            onClick={() => setBrowserFooter(false)}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className={classes.button}
                        >
                            <Typography variant="body2" className={classes.buttonText}>
                                Dismiss
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

BrowserWarning.propTypes = {
    classes: PropTypes.object.isRequired,
    rootStore: PropTypes.object
};

export default withStyles(styles)(BrowserWarning);
