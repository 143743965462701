import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '../../../../static/images/info_icon.svg';

function arrowGenerator(color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '1.5em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`
            }
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '1.5em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`
            }
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '1.5em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`
            }
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '1.5em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`
            }
        }
    };
}

const styles = theme => ({
    tooltip: {
        color: theme.palette.white,
        backgroundColor: theme.palette.secondary.main,
        padding: '20px'
    },
    arrowPopper: arrowGenerator(theme.palette.secondary.main),
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    }
});

class MoreInfoTooltip extends React.Component {
    state = {
        arrowRef: null,
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    render() {
        const { classes, title, body, children } = this.props;

        return (
            <Tooltip
                enterTouchDelay={5}
                leaveTouchDelay={15000}
                title={
                    <React.Fragment>
                        {
                            title &&
                            <Typography variant="subtitle2" gutterBottom={true} color="inherit">{title}</Typography>
                        }
                        {
                            body &&
                            <Typography variant="body2" color="inherit">{body}</Typography>
                        }
                        <span className={classes.arrow} ref={this.handleArrowRef} />
                    </React.Fragment>
                }
                classes={{
                    tooltip: classes.tooltip,
                    popper: classes.arrowPopper
                }}
                PopperProps={{
                    popperOptions: {
                        modifiers: {
                            arrow: {
                                enabled: Boolean(this.state.arrowRef),
                                element: this.state.arrowRef,
                            },
                        },
                    },
                }}
            >
                {
                    children ? children : <IconButton><img src={InfoIcon} alt="more info" /></IconButton>
                }
            </Tooltip>
        );
    }
}

MoreInfoTooltip.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    body: PropTypes.string,
    children: PropTypes.any
}

export default withStyles(styles)(MoreInfoTooltip);
