import { observable, action, computed } from "mobx";
import { isWidthDown } from '@material-ui/core/withWidth';

export default class RootStore {
    constructor(sessionStore) {
        this.sessionStore = sessionStore;
    }

    @observable isAuthenticated = true;
    @observable busyQueue = 0;
    @observable isDirty = false;
    @observable breadcrumbs = [];
    @observable confirmNavigationDialogOpen = false;
    @observable confirmNavigationLink = null;
    @observable isDyslexicTheme = false;
    @observable formTitle = '';
    @observable showBrowserFooter = true;

    @computed get isBusy() {
        return this.busyQueue != 0;
    }
    @action
    setBusy(busy) {
        if (busy) {
            this.busyQueue ++;
        } else {
            this.busyQueue --;            
        }
        if (this.busyQueue < 0) {
            this.busyQueue = 0;
        }
    }

    @action
    setDirty(isDirty) {
        this.isDirty = isDirty;
    }

    @action
    setBreadcrumbs(breadcrumbs) {
        this.breadcrumbs = breadcrumbs;
    }

    @action
    setConfirmNavigationDialogOpen(open, link) {
        this.confirmNavigationDialogOpen = open;
        this.confirmNavigationLink = open ? link : null;
    }

    @action toggleDyslexicTheme = () => {
        this.isDyslexicTheme = !this.isDyslexicTheme;
    }

    @action setFormTitle = (title) => {
        this.formTitle = title;
    }

    @action setBrowserFooter = (show) => {
        this.showBrowserFooter = show
    }

    @computed get IsDyslexicTheme() {
        return this.isDyslexicTheme;
    }

    // To be overriden by the page
    confirmNavigation = () => {
        return true;
    }

    setConfirmNavigation=(confirmNavigationFn)=>{
        this.confirmNavigation = confirmNavigationFn;
    }

    isMobile(width) {
        return isWidthDown('sm', width);
    }
}
