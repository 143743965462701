import React from 'react'
import PropTypes from 'prop-types';

export default class FloatingBox extends React.Component {

    constructor(props){
        super(props)        
        this.offset = props.offset? props.offset: {x: 0, y: 0};        
        this.state = ({relativeStyle: this.calcStyle()});
    }

    componentWillUnmount() {
        clearTimeout(this.redrawTimeout);
    }
    
    render() {
        let _this = this;
        //eslint-disable-next-line
        let {children, anchorEl, relative, offset, position, zIndex, visible, redrawOnChange, ...otherProps} = this.props
        //This timeout waits untill the render has finished and will update the button location if needed (for transitions etc)
        this.redrawTimeout = setTimeout(() => {
            if (this.stylesDiffer(_this.state.relativeStyle, _this.calcStyle())) {
                _this.setState({relativeStyle: _this.calcStyle()});            
            }
        },100);
        return (anchorEl && (!!visible | visible == undefined)? <div {...otherProps} style={this.state.relativeStyle}>{children}</div> : null);
    }

    stylesDiffer(style1, style2) {
        return JSON.stringify(style1) !== JSON.stringify(style2);            
    }

    calcStyle() {        
        let relativeStyle = { position: this.props.relative? 'relative' : 'absolute'};
        if (this.props.zIndex) {
            relativeStyle.zIndex = this.props.zIndex;
        }
        if (this.props.anchorEl) {
            let anchor = this.props.anchorEl.getBoundingClientRect();        
            switch (this.props.position){
                case 'top', 'top-right':
                    relativeStyle.right = (window.innerWidth - (anchor.left + anchor.width ) + this.offset.x).toString() + 'px';
                    relativeStyle.top = (anchor.top + this.offset.y).toString() + 'px';
                    break;
                case 'bottom', 'bottom-left':
                    relativeStyle.left =  (anchor.left + this.offset.x).toString() + 'px';
                    relativeStyle.bottom = (window.innerHeight - (anchor.top + anchor.height) + this.offset.y).toString() + 'px';
                    break;            
                case 'bottom-right':
                    relativeStyle.right =  (window.innerWidth - (anchor.left + anchor.width ) + this.offset.x).toString() + 'px';
                    relativeStyle.bottom = (window.innerHeight - (anchor.top + anchor.height) + this.offset.y).toString() + 'px';
                    break;            
                case 'under-left':
                    relativeStyle.left =  (anchor.left + this.offset.x).toString() + 'px';
                    relativeStyle.top = (anchor.top + anchor.height + this.offset.y).toString() + 'px';
                    break;            
                case 'under-right':
                    relativeStyle.right =  (window.innerWidth - (anchor.left + anchor.width ) + this.offset.x).toString() + 'px';
                    relativeStyle.top = (anchor.top + anchor.height + this.offset.y).toString() + 'px';
                    break;            
                case 'top', 'top-left':
                default:
                    relativeStyle.left =  (anchor.left + this.offset.x).toString() + 'px';
                    relativeStyle.top = (anchor.top + this.offset.y).toString() + 'px';
                    break;
            }
         } else {
             relativeStyle.display = 'none';
         }
        return relativeStyle;
    }

    static propTypes ={
        anchorEl: PropTypes.object,
        offset: PropTypes.object,
        children: PropTypes.object,
        className: PropTypes.string,
        position: PropTypes.string,
        relative: PropTypes.any,
        visible: PropTypes.bool,
        redrawOnChange: PropTypes.any, //This is simply to trigger a redraw if the position is reliant on another element 
        zIndex: PropTypes.number,
    }
}